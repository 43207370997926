export class TranslationKeys {
  public static readonly CONFIDENTIALITY_TITLE = 'confidentiality.title'
  public static readonly CONFIDENTIALITY_SUBTITLE = 'confidentiality.subtitle'
  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_TITLE_HASGENERICMODULES =
    'homepage.progressKpi.completedCourse.title.hasGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_TITLE_NOGENERICMODULES =
    'homepage.progressKpi.completedCourse.title.noGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_DESCRIPTION_HASGENERICMODULES =
    'homepage.progressKpi.completedCourse.description.hasGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_DESCRIPTION_NOGENERICMODULES =
    'homepage.progressKpi.completedCourse.description.noGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_SEECOURSES_HASGENERICMODULES =
    'homepage.progressKpi.completedCourse.seeCourses.hasGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_COMPLETEDCOURSE_SEECOURSES_NOGENERICMODULES =
    'homepage.progressKpi.completedCourse.seeCourses.noGenericModules'

  public static readonly HOMEPAGE_PROGRESSKPI_CURRENTCOURSE_TITLE = 'homepage.progressKpi.currentCourse.title'
  public static readonly HOMEPAGE_PROGRESSKPI_CURRENTCOURSE_CANCEL = 'homepage.progressKpi.currentCourse.cancel'
  public static readonly HOMEPAGE_PROGRESSKPI_CURRENTCOURSE_CHANGE = 'homepage.progressKpi.currentCourse.change'
  public static readonly HOMEPAGE_PROGRESSKPI_CURRENTCOURSE_ONGOINGCHALLENGES_TITLE =
    'homepage.progressKpi.currentCourse.ongoingChallenges.title'

  public static readonly HOMEPAGE_PROGRESSKPI_CURRENTCOURSE_ONGOINGCHALLENGES_DESCRIPTION =
    'homepage.progressKpi.currentCourse.ongoingChallenges.description'

  public static readonly HOMEPAGE_PROGRESSKPI_CANCELMODAL_TITLE = 'homepage.progressKpi.cancelModal.title'
  public static readonly HOMEPAGE_PROGRESSKPI_CANCELMODAL_DESCRIPTION = 'homepage.progressKpi.cancelModal.description'
  public static readonly HOMEPAGE_PROGRESSKPI_CANCELMODAL_BACK = 'homepage.progressKpi.cancelModal.back'
  public static readonly HOMEPAGE_PROGRESSKPI_CANCELMODAL_CONFIRM = 'homepage.progressKpi.cancelModal.confirm'
  public static readonly HOMEPAGE_SESION_ERRORS_ROLLACTIONSFORSESSION = 'homepage.sesion.errors.rollActionsForSession'
  public static readonly ISSUECHALLENGE_ISSUESOMEONE = 'issueChallenge.issueSomeone'
  public static readonly ISSUECHALLENGE_ISSUESOMEONEV2 = 'issueChallenge.issueSomeoneV2'
  public static readonly ISSUECHALLENGE_RECOMMENDSOMEONE = 'issueChallenge.recommendSomeone'
  public static readonly ISSUECHALLENGE_WHO = 'issueChallenge.who'
  public static readonly ISSUECHALLENGE_RECOMMENDWHO = 'issueChallenge.recommendWho'
  public static readonly ISSUECHALLENGE_EXPLANATION = 'issueChallenge.explanation'
  public static readonly ISSUECHALLENGE_RECOMMANDEXPLANATION = 'issueChallenge.recommandExplanation'
  public static readonly ISSUECHALLENGE_PLACEHOLDER = 'issueChallenge.placeholder'
  public static readonly ISSUECHALLENGE_CANCEL = 'issueChallenge.cancel'
  public static readonly ISSUECHALLENGE_SEND = 'issueChallenge.send'
  public static readonly ISSUECHALLENGE_CONFIRMATION = 'issueChallenge.confirmation'
  public static readonly ISSUECHALLENGE_ERRORS_OK = 'issueChallenge.errors.Ok'
  public static readonly ISSUECHALLENGE_ERRORS_INVALIDEMAIL = 'issueChallenge.errors.InvalidEmail'
  public static readonly ISSUECHALLENGE_ERRORS_CHALLENGENOTFOUND = 'issueChallenge.errors.ChallengeNotFound'
  public static readonly ISSUECHALLENGE_ERRORS_ALREADYRECOMMENDED = 'issueChallenge.errors.AlreadyRecommended'
  public static readonly ISSUECHALLENGE_ERRORS_EMAILNOTSENT = 'issueChallenge.errors.EmailNotSent'
  public static readonly C_PERSONALIZATIONCHOICES_NOPRIORITY = 'c.PersonalizationChoices.noPriority'
  public static readonly C_PERSONALIZATIONCHOICES_PRIORITY = 'c.PersonalizationChoices.priority'
  public static readonly C_PERSONALIZATIONCHOICES_OBJECTIVE = 'c.PersonalizationChoices.objective'
  public static readonly C_PERSONALIZATIONCHOICES_CANCEL = 'c.PersonalizationChoices.cancel'
  public static readonly C_PERSONALIZATIONCHOICES_SAVE = 'c.PersonalizationChoices.save'
  public static readonly C_PERSONALIZATIONCHOICES_TOAST = 'c.PersonalizationChoices.toast'
  public static readonly C_CREATELAUNCHFORM_MODALTITLE_CREATE = 'c.createLaunchForm.modalTitle.create'
  public static readonly C_CREATELAUNCHFORM_MODALTITLE_UPDATE = 'c.createLaunchForm.modalTitle.update'
  public static readonly C_CREATELAUNCHFORM_BUTTON_CREATE = 'c.createLaunchForm.button.create'
  public static readonly C_CREATELAUNCHFORM_BUTTON_UPDATE = 'c.createLaunchForm.button.update'
  public static readonly C_CREATELAUNCHFORM_MANDATORYERROR = 'c.createLaunchForm.mandatoryError'
  public static readonly C_CREATELAUNCHFORM_INTERNALNAME_LABEL = 'c.createLaunchForm.internalName.label'
  public static readonly C_CREATELAUNCHFORM_INTERNALNAME_TOOLTIP = 'c.createLaunchForm.internalName.tooltip'
  public static readonly C_CREATELAUNCHFORM_COURSE_LABEL = 'c.createLaunchForm.course.label'
  public static readonly C_CREATELAUNCHFORM_STARTDATE_LABEL = 'c.createLaunchForm.startDate.label'
  public static readonly C_CREATELAUNCHFORM_STARTDATE_TOOLTIP = 'c.createLaunchForm.startDate.tooltip'
  public static readonly C_CREATELAUNCHFORM_NAME_LABEL = 'c.createLaunchForm.name.label'
  public static readonly C_CREATELAUNCHFORM_NAME_TOOLTIP = 'c.createLaunchForm.name.tooltip'
  public static readonly C_SUBSEQUENTONBOARDING_EXTENSION_TITLE = 'c.SubsequentOnBoarding.extension.title'
  public static readonly C_SUBSEQUENTONBOARDING_EXTENSION_SUBTITLE = 'c.SubsequentOnBoarding.extension.subtitle'
  public static readonly C_SUBSEQUENTONBOARDING_MANDATORYCOURSE_TITLE = 'c.SubsequentOnBoarding.mandatoryCourse.title'
  public static readonly C_SUBSEQUENTONBOARDING_MANDATORYCOURSE_SUBTITLE =
    'c.SubsequentOnBoarding.mandatoryCourse.subtitle'

  public static readonly C_SUBSEQUENTONBOARDING_HIGHLIGHTS_CHALLENGES = 'c.SubsequentOnBoarding.highlights.challenges'
  public static readonly C_SUBSEQUENTONBOARDING_HIGHLIGHTS_DURATION = 'c.SubsequentOnBoarding.highlights.duration'
  public static readonly C_SUBSEQUENTONBOARDING_HIGHLIGHTS_WEEKSDURATION =
    'c.SubsequentOnBoarding.highlights.weeksDuration'

  public static readonly C_SUBSEQUENTONBOARDING_BUTTONLABEL = 'c.SubsequentOnBoarding.buttonLabel'
  public static readonly C_TEAMCHALLENGE_META_TITLE = 'c.teamChallenge.meta.title'
  public static readonly C_TEAMCHALLENGE_META_DESCRIPTION = 'c.teamChallenge.meta.description'
  public static readonly C_TEAMCHALLENGE_PROGRESSTOOLTIP = 'c.teamChallenge.progressTooltip'
  public static readonly C_TEAMCHALLENGE_PROGRESSBLIND = 'c.teamChallenge.progressBlind'
  public static readonly C_TEAMCHALLENGE_PROGRESS = 'c.teamChallenge.progress'
  public static readonly C_TEAMCHALLENGE_YOURTEAM = 'c.teamChallenge.yourTeam'
  public static readonly C_TEAMCHALLENGE_TEAMMEMBERS = 'c.teamChallenge.teamMembers'
  public static readonly C_TEAMCHALLENGE_TEAMOWNER = 'c.teamChallenge.teamOwner'
  public static readonly C_TEAMCHALLENGE_UNKNOWNBANNER = 'c.teamChallenge.unknownBanner'
  public static readonly C_CHALLENGECHOICES_WAITINGFOR = 'c.ChallengeChoices.waitingFor'
  public static readonly C_CHALLENGECHOICES_NEWAVAILABLE_TITLE_NEW = 'c.ChallengeChoices.newAvailable.title.new'
  public static readonly C_CHALLENGECHOICES_NEWAVAILABLE_TITLE_SEEN = 'c.ChallengeChoices.newAvailable.title.seen'
  public static readonly C_CHALLENGECHOICES_NEWAVAILABLE_TITLE_SEENNEW =
    'c.ChallengeChoices.newAvailable.title.seen-new'

  public static readonly C_CHALLENGECHOICES_NEWAVAILABLE_SEE = 'c.ChallengeChoices.newAvailable.see'
  public static readonly C_CHALLENGECHOICES_FIRSTCHOICE_TITLE = 'c.ChallengeChoices.firstChoice.title'
  public static readonly C_CHALLENGECHOICES_FIRSTCHOICE_SEE = 'c.ChallengeChoices.firstChoice.see'
  public static readonly C_CHALLENGECHOICES_NOONGOING_TITLE = 'c.ChallengeChoices.noOngoing.title'
  public static readonly C_CHALLENGECHOICES_NOONGOING_SUBTITLE = 'c.ChallengeChoices.noOngoing.subtitle'
  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NEWCHALLENGESCOUNTDOWNLABEL =
    'c.MultipleChallengesChoiceContainer.newChallengesCountdownLabel'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NEWCHALLENGESCHOICESPAGE_TITLE =
    'c.MultipleChallengesChoiceContainer.newChallengesChoicesPage.title'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NEWCHALLENGESCHOICESPAGE_SUBTITLE_ACTIONABLE =
    'c.MultipleChallengesChoiceContainer.newChallengesChoicesPage.subtitle.actionable'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NEWCHALLENGESCHOICESPAGE_SUBTITLE_ALLSET =
    'c.MultipleChallengesChoiceContainer.newChallengesChoicesPage.subtitle.allSet'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NEWCHALLENGESCHOICESPAGE_BACKTOHOME =
    'c.MultipleChallengesChoiceContainer.newChallengesChoicesPage.backToHome'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_ONGOINGCHALLENGES =
    'c.MultipleChallengesChoiceContainer.ongoingChallenges'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOONGOINGCHALLENGESYET =
    'c.MultipleChallengesChoiceContainer.noOnGoingChallengesYet'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_MORECHOICE =
    'c.MultipleChallengesChoiceContainer.moreChoice'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_JOKERREMAININGONE =
    'c.MultipleChallengesChoiceContainer.jokerRemainingOne'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_JOKERREMAINING =
    'c.MultipleChallengesChoiceContainer.jokerRemaining'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_SKIPPEDCHALLENGE =
    'c.MultipleChallengesChoiceContainer.skippedChallenge'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_UNDERSTOOD_1 =
    'c.MultipleChallengesChoiceContainer.understood_1'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_UNDERSTOOD_2 =
    'c.MultipleChallengesChoiceContainer.understood_2'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_UNDERSTOOD_3 =
    'c.MultipleChallengesChoiceContainer.understood_3'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CANCEL = 'c.MultipleChallengesChoiceContainer.cancel'
  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CONFIRMJOKER =
    'c.MultipleChallengesChoiceContainer.confirmJoker'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_USEJOKER = 'c.MultipleChallengesChoiceContainer.useJoker'
  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CHALLENGESEARCH =
    'c.MultipleChallengesChoiceContainer.challengeSearch'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOMOREJOKERS =
    'c.MultipleChallengesChoiceContainer.noMoreJokers'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_GETMOREJOKERS =
    'c.MultipleChallengesChoiceContainer.getMoreJokers'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOCHALLENGEFITME =
    'c.MultipleChallengesChoiceContainer.noChallengeFitMe'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOCHALLENGEEXPLANATIONBEGIN =
    'c.MultipleChallengesChoiceContainer.noChallengeExplanationBegin'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOCHALLENGEEXPLANATIONEND =
    'c.MultipleChallengesChoiceContainer.noChallengeExplanationEnd'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOCHALLENGECOURSECOMPLETED =
    'c.MultipleChallengesChoiceContainer.noChallengeCourseCompleted'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_NOCHOICEWITHONGOINGBEGIN =
    'c.MultipleChallengesChoiceContainer.noChoiceWithOnGoingBegin'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CANCELNOFIT =
    'c.MultipleChallengesChoiceContainer.cancelNoFit'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_RETRIEVEIT =
    'c.MultipleChallengesChoiceContainer.retrieveIt'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_JOKERSTOOLTIPHELP =
    'c.MultipleChallengesChoiceContainer.jokersTooltipHelp'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CHALLENGEACCEPTED_TITLE =
    'c.MultipleChallengesChoiceContainer.challengeAccepted.title'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_CHALLENGEACCEPTED_NEWCHOICES =
    'c.MultipleChallengesChoiceContainer.challengeAccepted.newChoices'

  public static readonly C_MULTIPLECHALLENGESCHOICECONTAINER_HABITCHALLENGES_TITLE =
    'c.MultipleChallengesChoiceContainer.habitChallenges.title'

  public static readonly C_OBPROGRESSBAR_STEPS_1 = 'c.ObProgressBar.steps.1'
  public static readonly C_OBPROGRESSBAR_STEPS_2 = 'c.ObProgressBar.steps.2'
  public static readonly C_OBPROGRESSBAR_STEPS_3 = 'c.ObProgressBar.steps.3'
  public static readonly C_OBPROGRESSBAR_GENERIC = 'c.ObProgressBar.generic'
  public static readonly C_CHALLENGECHOICECARD_TOPUSER = 'c.ChallengeChoiceCard.topUser'
  public static readonly C_CHALLENGECHOICECARD_ACCEPT = 'c.ChallengeChoiceCard.accept'
  public static readonly C_CHALLENGECHOICECARD_CHOOSE = 'c.ChallengeChoiceCard.choose'
  public static readonly C_CHALLENGECHOICECARD_SKIP = 'c.ChallengeChoiceCard.skip'
  public static readonly C_CHALLENGECHOICECARD_DETAILSLINK = 'c.ChallengeChoiceCard.detailsLink'
  public static readonly C_CHALLENGECHOICECARD_DETAILSLINKV2 = 'c.ChallengeChoiceCard.detailsLinkV2'
  public static readonly C_CHALLENGECHOICECARD_NEWCHALLENGETYPE = 'c.ChallengeChoiceCard.newChallengeType'
  public static readonly C_CHALLENGECHOICECARD_DEPENDENCYCHALLENGE = 'c.ChallengeChoiceCard.dependencyChallenge'
  public static readonly C_CHALLENGECHOICECARD_GIVENAGAINCHALLENGE = 'c.ChallengeChoiceCard.givenAgainChallenge'
  public static readonly C_CHALLENGECHOICECARD_WASNOTCHOSENBEFORE = 'c.ChallengeChoiceCard.wasNotChosenBefore'
  public static readonly C_CHALLENGECHOICECARD_ISSUED = 'c.ChallengeChoiceCard.issued'
  public static readonly C_CHALLENGECHOICECARD_RECOMMENDED = 'c.ChallengeChoiceCard.recommended'
  public static readonly C_CHALLENGECHOICECARD_GROUP = 'c.ChallengeChoiceCard.group'
  public static readonly C_CHALLENGECHOICECARD_PASS = 'c.ChallengeChoiceCard.pass'
  public static readonly C_CHALLENGECHOICECARD_PLAN = 'c.ChallengeChoiceCard.plan'
  public static readonly C_CHALLENGECHOICECARD_CONTINUE = 'c.ChallengeChoiceCard.continue'
  public static readonly C_CHALLENGECHOICECARD_JUSTACCEPTED = 'c.ChallengeChoiceCard.justAccepted'
  public static readonly C_PLANNEDEXECUTIONTIME_MODIFY = 'c.PlannedExecutionTime.modify'
  public static readonly C_PLANNEDEXECUTIONTIME_NOREMINDER = 'c.PlannedExecutionTime.noReminder'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_ADDREMINDER = 'c.PlannedExecutionTimeForm.addReminder'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_ADDREMINDERONLY = 'c.PlannedExecutionTimeForm.addReminderOnly'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SELECTDAY_AGENDAVISIBILITY =
    'c.PlannedExecutionTimeForm.selectDay.agendaVisibility'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SELECTDAY_DEFAULT = 'c.PlannedExecutionTimeForm.selectDay.default'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SELECTDAY_CONGRATULATIONPAGE =
    'c.PlannedExecutionTimeForm.selectDay.congratulationPage'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOREMINDER_TEXT = 'c.PlannedExecutionTimeForm.noReminder.text'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOREMINDER_TEXTV2 = 'c.PlannedExecutionTimeForm.noReminder.textV2'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOREMINDER_BUTTON = 'c.PlannedExecutionTimeForm.noReminder.button'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_VALIDATION_NOSELECTEDDATE =
    'c.PlannedExecutionTimeForm.validation.noSelectedDate'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_VALIDATION_NOREMINDERSELECTED =
    'c.PlannedExecutionTimeForm.validation.noReminderSelected'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOW = 'c.PlannedExecutionTimeForm.now'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_MORNING = 'c.PlannedExecutionTimeForm.morning'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_AFTERNOON = 'c.PlannedExecutionTimeForm.afternoon'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TODAY = 'c.PlannedExecutionTimeForm.today'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TOMORROW = 'c.PlannedExecutionTimeForm.tomorrow'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_CHOOSEDATE = 'c.PlannedExecutionTimeForm.chooseDate'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_CHOOSEDATESHORT2 = 'c.PlannedExecutionTimeForm.chooseDateShort2'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_CHOOSEDATESHORT = 'c.PlannedExecutionTimeForm.chooseDateShort'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_DAYLABEL = 'c.PlannedExecutionTimeForm.dayLabel'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_HOURLABEL = 'c.PlannedExecutionTimeForm.hourLabel'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_DAYNOTSELECTEDERROR =
    'c.PlannedExecutionTimeForm.dayNotSelectedError'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_HOURNOTSELECTEDERROR =
    'c.PlannedExecutionTimeForm.hourNotSelectedError'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_MORNINGAFTERNOONNOTSELECTEDERROR =
    'c.PlannedExecutionTimeForm.morningAfternoonNotSelectedError'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SELECTHOUR = 'c.PlannedExecutionTimeForm.selectHour'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SELECTMORNINGORAFTERNOON =
    'c.PlannedExecutionTimeForm.selectMorningOrAfternoon'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_GETCALENDARINVITE = 'c.PlannedExecutionTimeForm.getCalendarInvite'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOCALENDARINVITE = 'c.PlannedExecutionTimeForm.noCalendarInvite'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_NOPLANNEDEXECUTION = 'c.PlannedExecutionTimeForm.noPlannedExecution'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_GETCALENDARINVITETHE =
    'c.PlannedExecutionTimeForm.getCalendarInviteThe'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_GETCALENDARINVITETIMEAT =
    'c.PlannedExecutionTimeForm.getCalendarInviteTimeAt'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_EDIT = 'c.PlannedExecutionTimeForm.edit'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_SAVE = 'c.PlannedExecutionTimeForm.save'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_RESCHEDULE = 'c.PlannedExecutionTimeForm.reschedule'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_CANCEL = 'c.PlannedExecutionTimeForm.cancel'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TEAMCHALLENGEINPUT_ALONE =
    'c.PlannedExecutionTimeForm.teamChallengeInput.alone'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TEAMCHALLENGEINPUT_TEAM =
    'c.PlannedExecutionTimeForm.teamChallengeInput.team'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TEAMCHALLENGEINPUT_HOW =
    'c.PlannedExecutionTimeForm.teamChallengeInput.how'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TEAMCHALLENGEINPUT_CREATE =
    'c.PlannedExecutionTimeForm.teamChallengeInput.create'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_TEAMCHALLENGEINPUT_TOOLTIP =
    'c.PlannedExecutionTimeForm.teamChallengeInput.tooltip'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_CHANNELREMINDERLABEL =
    'c.PlannedExecutionTimeForm.reminders.channelReminderLabel'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_CHANNELREMINDERLABELV2 =
    'c.PlannedExecutionTimeForm.reminders.channelReminderLabelV2'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_CHANNELREMINDERLABELIFONECHANNELONLY =
    'c.PlannedExecutionTimeForm.reminders.channelReminderLabelIfOneChannelOnly'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_NOREMINDER =
    'c.PlannedExecutionTimeForm.reminders.noReminder'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_NOREMINDERV2 =
    'c.PlannedExecutionTimeForm.reminders.noReminderV2'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_AT = 'c.PlannedExecutionTimeForm.reminders.at'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_THE = 'c.PlannedExecutionTimeForm.reminders.the'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_CALENDAR_LABEL =
    'c.PlannedExecutionTimeForm.reminders.calendar.label'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_CALENDAR_NONE =
    'c.PlannedExecutionTimeForm.reminders.calendar.none'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_TITLE =
    'c.PlannedExecutionTimeForm.reminders.teams.title'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_STEP1 =
    'c.PlannedExecutionTimeForm.reminders.teams.step1'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_STEP2 =
    'c.PlannedExecutionTimeForm.reminders.teams.step2'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_STEP2V2 =
    'c.PlannedExecutionTimeForm.reminders.teams.step2v2'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_STEP2LINK =
    'c.PlannedExecutionTimeForm.reminders.teams.step2Link'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_REFRESHBUTTON =
    'c.PlannedExecutionTimeForm.reminders.teams.refreshButton'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_SUCCESSFULINSTALLATION =
    'c.PlannedExecutionTimeForm.reminders.teams.successfulInstallation'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_TEAMS_VALIDATIONERROR =
    'c.PlannedExecutionTimeForm.reminders.teams.validationError'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_STEP1DESKTOP =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.step1Desktop'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_STEP1MOBILE =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.step1Mobile'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_STEP2 =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.step2'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_STEP3 =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.step3'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_STEP3LINK =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.step3Link'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_SUCCESSFULINSTALLATION =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.successfulInstallation'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_MOBILEAPP_VALIDATIONERROR =
    'c.PlannedExecutionTimeForm.reminders.mobileApp.validationError'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_REMINDERS_SMS_INPUTEMPTY =
    'c.PlannedExecutionTimeForm.reminders.sms.inputEmpty'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_GETSMSNOTIFICATION =
    'c.PlannedExecutionTimeForm.phoneBloc.getSmsNotification'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_GETSMSNOTIFICATIONLARGE =
    'c.PlannedExecutionTimeForm.phoneBloc.getSmsNotificationLarge'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_GETSMSNOTIFICATIONON =
    'c.PlannedExecutionTimeForm.phoneBloc.getSmsNotificationOn'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_GETSMSNOTIFICATIONTIMEAT =
    'c.PlannedExecutionTimeForm.phoneBloc.getSmsNotificationTimeAt'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_TITLE = 'c.PlannedExecutionTimeForm.phoneBloc.title'
  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_DESCRIPTION =
    'c.PlannedExecutionTimeForm.phoneBloc.description'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_INPUTLABEL =
    'c.PlannedExecutionTimeForm.phoneBloc.inputLabel'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_INPUTEMPTY =
    'c.PlannedExecutionTimeForm.phoneBloc.inputEmpty'

  public static readonly C_PLANNEDEXECUTIONTIMEFORM_PHONEBLOC_NOSMSNOTIFICATION =
    'c.PlannedExecutionTimeForm.phoneBloc.noSmsNotification'

  public static readonly C_CREATETEAMMODAL_TITLE = 'c.CreateTeamModal.title'
  public static readonly C_CREATETEAMMODAL_SUBTITLE = 'c.CreateTeamModal.subtitle'
  public static readonly C_CREATETEAMMODAL_GROUPNAME = 'c.CreateTeamModal.groupName'
  public static readonly C_CREATETEAMMODAL_GROUPWATERMARK = 'c.CreateTeamModal.groupWatermark'
  public static readonly C_CREATETEAMMODAL_GROUPMEMBERS = 'c.CreateTeamModal.groupMembers'
  public static readonly C_CREATETEAMMODAL_MEMBERNAME = 'c.CreateTeamModal.memberName'
  public static readonly C_CREATETEAMMODAL_MEMBEREMAIL = 'c.CreateTeamModal.memberEmail'
  public static readonly C_CREATETEAMMODAL_ADDMEMBER = 'c.CreateTeamModal.addMember'
  public static readonly C_CREATETEAMMODAL_HOWTITLE = 'c.CreateTeamModal.howTitle'
  public static readonly C_CREATETEAMMODAL_HOWLI1 = 'c.CreateTeamModal.howLi1'
  public static readonly C_CREATETEAMMODAL_HOWLI2 = 'c.CreateTeamModal.howLi2'
  public static readonly C_CREATETEAMMODAL_CANCEL = 'c.CreateTeamModal.cancel'
  public static readonly C_CREATETEAMMODAL_CONFIRM = 'c.CreateTeamModal.confirm'
  public static readonly C_CREATETEAMMODAL_MANDATORYGROUPNAME = 'c.CreateTeamModal.mandatoryGroupName'
  public static readonly C_CREATETEAMMODAL_TEAMCREATIONERRORS_UNEXPECTEDERROR =
    'c.CreateTeamModal.teamCreationErrors.UnexpectedError'

  public static readonly C_CREATETEAMMODAL_TEAMCREATIONERRORS_TEAMNAMEALREADYEXISTS =
    'c.CreateTeamModal.teamCreationErrors.TeamNameAlreadyExists'

  public static readonly C_CREATETEAMMODAL_TEAMCREATIONERRORS_EMPTYMEMBERS =
    'c.CreateTeamModal.teamCreationErrors.EmptyMembers'

  public static readonly C_CREATETEAMMODAL_TEAMCREATIONERRORS_UNVALIDMEMBER =
    'c.CreateTeamModal.teamCreationErrors.UnvalidMember'

  public static readonly C_CREATETEAMMODAL_TEAMCREATIONERRORS_TEAMWITHNOOTHERMEMBERS =
    'c.CreateTeamModal.teamCreationErrors.TeamWithNoOtherMembers'

  public static readonly C_CREATETEAMMODAL_CREATED = 'c.CreateTeamModal.created'
  public static readonly C_SKIPSELECTOR_SHORTSKIPLABEL_ALREADYDOINGITOFTEN =
    'c.SkipSelector.shortSkipLabel.AlreadyDoingItOften'

  public static readonly C_SKIPSELECTOR_SHORTSKIPLABEL_NOTAPPLICABLEFORME =
    'c.SkipSelector.shortSkipLabel.NotApplicableForMe'

  public static readonly C_SKIPSELECTOR_SKIPTEXT_ALREADYDOINGITOFTEN = 'c.SkipSelector.skipText.AlreadyDoingItOften'
  public static readonly C_SKIPSELECTOR_SKIPTEXT_NOTAPPLICABLEFORME = 'c.SkipSelector.skipText.NotApplicableForMe'
  public static readonly C_SKIPSELECTOR_SKIPTEXT_DEFAULT = 'c.SkipSelector.skipText.default'
  public static readonly C_SKIPSELECTOR_SKIPREMAINING_4 = 'c.SkipSelector.skipRemaining.4'
  public static readonly C_SKIPSELECTOR_SKIPREMAINING_3 = 'c.SkipSelector.skipRemaining.3'
  public static readonly C_SKIPSELECTOR_SKIPREMAINING_2 = 'c.SkipSelector.skipRemaining.2'
  public static readonly C_SKIPSELECTOR_SKIPREMAINING_1 = 'c.SkipSelector.skipRemaining.1'
  public static readonly C_CHALLENGEFEEDBACKFORM_TITLE = 'c.ChallengeFeedbackForm.title'
  public static readonly C_CHALLENGEFEEDBACKFORM_ERRORTRYAGAINLATER = 'c.ChallengeFeedbackForm.errorTryAgainLater'
  public static readonly C_CHALLENGEFEEDBACKFORM_STARSQUESTION = 'c.ChallengeFeedbackForm.starsQuestion'
  public static readonly C_CHALLENGEFEEDBACKFORM_COMMENT1LABEL = 'c.ChallengeFeedbackForm.comment1Label'
  public static readonly C_CHALLENGEFEEDBACKFORM_NOTDONETITLE = 'c.ChallengeFeedbackForm.notDoneTitle'
  public static readonly C_CHALLENGEFEEDBACKFORM_SKIPTHISSTEP = 'c.ChallengeFeedbackForm.skipThisStep'
  public static readonly C_CHALLENGEFEEDBACKFORM_SUBMIT = 'c.ChallengeFeedbackForm.submit'
  public static readonly C_CHALLENGEFEEDBACKFORM_UNCOMPLETED = 'c.ChallengeFeedbackForm.uncompleted'
  public static readonly C_CHALLENGEFEEDBACKFORM_UNCOMPLETEDADVICE = 'c.ChallengeFeedbackForm.uncompletedAdvice'
  public static readonly C_CHALLENGEFEEDBACKFORM_UNCOMPLETEDLABEL = 'c.ChallengeFeedbackForm.uncompletedLabel'
  public static readonly C_CHALLENGEFEEDBACKFORM_UNCOMPLETEDPLACEHOLDER =
    'c.ChallengeFeedbackForm.uncompletedPlaceholder'

  public static readonly C_CHALLENGEFEEDBACKFORM_SENDREVIEW = 'c.ChallengeFeedbackForm.sendReview'
  public static readonly C_CHALLENGEFEEDBACKFORM_SENDREVIEWTITLE = 'c.ChallengeFeedbackForm.sendReviewTitle'
  public static readonly C_CHALLENGEFEEDBACKFORM_REDOCHALLENGEADVICE = 'c.ChallengeFeedbackForm.redoChallengeAdvice'
  public static readonly C_CHALLENGEFEEDBACKFORM_REDOCHALLENGEADVICESUFFIX =
    'c.ChallengeFeedbackForm.redoChallengeAdviceSuffix'

  public static readonly C_CHALLENGEFEEDBACKFORM_FEEDBACKPLACEHOLDER = 'c.ChallengeFeedbackForm.feedbackPlaceholder'
  public static readonly C_CHALLENGEFEEDBACKFORM_SAVE = 'c.ChallengeFeedbackForm.save'
  public static readonly C_CHALLENGEFEEDBACKFORM_SAVED = 'c.ChallengeFeedbackForm.saved'
  public static readonly C_CHALLENGEFEEDBACKFORM_EDIT = 'c.ChallengeFeedbackForm.edit'
  public static readonly C_CHALLENGEFEEDBACKFORM_DISCLAIMER = 'c.ChallengeFeedbackForm.disclaimer'
  public static readonly C_CHALLENGEDATABOX_ADVICE = 'c.ChallengeDataBox.advice'
  public static readonly C_CHALLENGEDATABOX_NOTES = 'c.ChallengeDataBox.notes'
  public static readonly C_CHALLENGEDATABOX_CONTEXT = 'c.ChallengeDataBox.context'
  public static readonly C_CHALLENGEDATABOX_CUSTOMCONTEXT = 'c.ChallengeDataBox.customContext'
  public static readonly C_CHALLENGEDATABOX_CUSTOMRECOMMENDEDCONTEXT = 'c.ChallengeDataBox.customRecommendedContext'
  public static readonly C_CHALLENGEDATABOX_CUSTOMRECOMMENDEDCONTEXTNOTHING =
    'c.ChallengeDataBox.customRecommendedContextNothing'

  public static readonly C_CHALLENGEDATABOX_GROUPS_TITLE = 'c.ChallengeDataBox.groups.title'
  public static readonly C_CHALLENGEDATABOX_GROUPS_LINK = 'c.ChallengeDataBox.groups.link'
  public static readonly C_CHALLENGEDATABOX_GROUPS_ANDONEMORE = 'c.ChallengeDataBox.groups.andOneMore'
  public static readonly C_CHALLENGEDATABOX_GROUPS_ANDSEVERALMORE = 'c.ChallengeDataBox.groups.andSeveralMore'
  public static readonly C_CHALLENGEDATABOX_GROUPS_ANDYOU = 'c.ChallengeDataBox.groups.andYou'
  public static readonly C_CHALLENGEDATABOX_SKILLS = 'c.ChallengeDataBox.skills'
  public static readonly C_CHALLENGEACTIONS_CURRENTLYINPROGRESS = 'c.ChallengeActions.currentlyInProgress'
  public static readonly C_CHALLENGEACTIONS_CUSTOMCHALLENGECURRENTLYINPROGRESS =
    'c.ChallengeActions.customChallengeCurrentlyInProgress'

  public static readonly C_CHALLENGEACTIONS_NOTDONE = 'c.ChallengeActions.notDone'
  public static readonly C_CHALLENGEACTIONS_ACCEPT = 'c.ChallengeActions.accept'
  public static readonly C_CHALLENGEACTIONS_ACCEPTCUSTOMCHALLENGE = 'c.ChallengeActions.acceptCustomChallenge'
  public static readonly C_CHALLENGEACTIONS_VALIDATE = 'c.ChallengeActions.validate'
  public static readonly C_CHALLENGEACTIONS_RESCHEDULE = 'c.ChallengeActions.reschedule'
  public static readonly C_CHALLENGEACTIONS_VALIDATED = 'c.ChallengeActions.validated'
  public static readonly C_CHALLENGEACTIONS_VALIDATEDDESCRIPTION = 'c.ChallengeActions.validatedDescription'
  public static readonly C_CHALLENGEACTIONS_ENDED = 'c.ChallengeActions.ended'
  public static readonly C_CHALLENGEACTIONS_SEEDETAILS = 'c.ChallengeActions.seeDetails'
  public static readonly C_CHALLENGEACTIONS_RESCHEDULEMODALTITLE = 'c.ChallengeActions.rescheduleModalTitle'
  public static readonly C_CHALLENGEACTIONS_DOITIRL = 'c.ChallengeActions.doItIRl'
  public static readonly C_CHALLENGEACTIONS_CONFIGURE = 'c.ChallengeActions.configure'
  public static readonly C_CHALLENGEACTIONS_REDO = 'c.ChallengeActions.redo'
  public static readonly C_CHALLENGEACTIONS_HABIT = 'c.ChallengeActions.habit'
  public static readonly C_CHALLENGEACTIONS_RETRY = 'c.ChallengeActions.retry'
  public static readonly C_CHALLENGEACTIONS_ITSAHABITBTN = 'c.ChallengeActions.itsAHabitBtn'
  public static readonly C_CHALLENGEACTIONS_ITSAHABITBTNHELP = 'c.ChallengeActions.itsAHabitBtnHelp'
  public static readonly C_CHALLENGEACTIONS_ITSAHABITBTNHELPV2 = 'c.ChallengeActions.itsAHabitBtnHelpV2'
  public static readonly C_CHALLENGEACTIONS_HABITACQUIRED = 'c.ChallengeActions.habitAcquired'
  public static readonly C_CHALLENGEACTIONS_HABITREDONE = 'c.ChallengeActions.habitRedone'
  public static readonly C_CHALLENGEACTIONS_REDOTOHABIT = 'c.ChallengeActions.redoToHabit'
  public static readonly C_CHALLENGEACTIONS_REINFORCEHABIT = 'c.ChallengeActions.reinforceHabit'
  public static readonly C_CHALLENGEACTIONS_RESUMEHABIT = 'c.ChallengeActions.resumeHabit'
  public static readonly C_CHALLENGEACTIONS_REDOONLY = 'c.ChallengeActions.redoOnly'
  public static readonly C_RESCHEDULECHALLENGEFORM_RESCHEDULECHALLENGE = 'c.RescheduleChallengeForm.rescheduleChallenge'
  public static readonly C_RESCHEDULECHALLENGEFORM_CANCELRESCHEDULECHALLENGE =
    'c.RescheduleChallengeForm.cancelRescheduleChallenge'

  public static readonly C_HABITFORM_TITLE = 'c.HabitForm.title'
  public static readonly C_HABITFORM_SUBTITLE = 'c.HabitForm.subtitle'
  public static readonly C_HABITFORM_SUBTITLEREPLAY = 'c.HabitForm.subtitleReplay'
  public static readonly C_HABITFORM_FREQUENCYASK = 'c.HabitForm.frequencyAsk'
  public static readonly C_HABITFORM_EACH = 'c.HabitForm.each'
  public static readonly C_HABITFORM_TWOWEEKS = 'c.HabitForm.twoWeeks'
  public static readonly C_HABITFORM_ONEMONTH = 'c.HabitForm.oneMonth'
  public static readonly C_HABITFORM_THREEMONTH = 'c.HabitForm.threeMonth'
  public static readonly C_HABITFORM_THE = 'c.HabitForm.the'
  public static readonly C_HABITFORM_FIRSTOFTHEMONTH = 'c.HabitForm.firstOfTheMonth'
  public static readonly C_HABITFORM_LASTOFTHEMONTH = 'c.HabitForm.lastOfTheMonth'
  public static readonly C_HABITFORM_NEXTREMINDER = 'c.HabitForm.nextReminder'
  public static readonly C_HABITFORM_TO = 'c.HabitForm.to'
  public static readonly C_HABITFORM_PASS = 'c.HabitForm.pass'
  public static readonly C_HABITFORM_SUBMIT = 'c.HabitForm.submit'
  public static readonly C_HABITFORM_SUBMITREPLAY = 'c.HabitForm.submitReplay'
  public static readonly C_HABITFORM_HISTORY = 'c.HabitForm.history'
  public static readonly C_HABITFORM_MODIFY = 'c.HabitForm.modify'
  public static readonly C_HABITFORM_STOP = 'c.HabitForm.stop'
  public static readonly C_HABITFORM_CANCEL = 'c.HabitForm.cancel'
  public static readonly C_HABITFORM_CONFIRM = 'c.HabitForm.confirm'
  public static readonly C_HABITFORM_TOAST_SUCCESS = 'c.HabitForm.toast.success'
  public static readonly C_HABITFORM_TOAST_FAIL = 'c.HabitForm.toast.fail'
  public static readonly C_HABITFORM_PERIODS_RUNNING = 'c.HabitForm.periods.running'
  public static readonly C_HABITFORM_PERIODS_OTHER = 'c.HabitForm.periods.other'
  public static readonly C_CATEGORYCHALLENGES_NOCHALLENGE = 'c.CategoryChallenges.noChallenge'
  public static readonly C_NAVMENU_PROGRESSION = 'c.NavMenu.progression'
  public static readonly C_NAVMENU_HELP = 'c.NavMenu.help'
  public static readonly C_NAVMENU_ACCOUNT = 'c.NavMenu.account'
  public static readonly C_NAVMENU_ADMIN = 'c.NavMenu.admin'
  public static readonly C_NAVMENU_MYCHALLENGESONGOING = 'c.NavMenu.myChallengesOnGoing'
  public static readonly C_NAVMENU_MYCHALLENGESDONE = 'c.NavMenu.myChallengesDone'
  public static readonly C_NAVMENU_HOME = 'c.NavMenu.home'
  public static readonly C_NAVMENU_LOGIN = 'c.NavMenu.login'
  public static readonly C_NAVMENU_TEAM = 'c.NavMenu.team'
  public static readonly C_NAVMENU_INSCRIPTION = 'c.NavMenu.inscription'
  public static readonly C_NAVMENU_STEPS = 'c.NavMenu.steps'
  public static readonly C_NAVMENU_HIRING = 'c.NavMenu.hiring'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONGRATULATIONS = 'c.ChallengeValidatedContent.congratulations'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_CHALLENGEUNLOCKED = 'c.ChallengeValidatedContent.challengeUnlocked'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_CHALLENGEUNLOCKEDDESCRIPTION =
    'c.ChallengeValidatedContent.challengeUnlockedDescription'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONGRATULATIONSDESCRIPTION =
    'c.ChallengeValidatedContent.congratulationsDescription'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONGRATULATIONSDESCRIPTIONALTERNATIVE =
    'c.ChallengeValidatedContent.congratulationsDescriptionAlternative'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONGRATULATIONSDESCRIPTIONALTERNATIVEACHIEVED =
    'c.ChallengeValidatedContent.congratulationsDescriptionAlternativeAchieved'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_SEENEXTCHALLENGE = 'c.ChallengeValidatedContent.seeNextChallenge'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_BACKTOCHALLENGES = 'c.ChallengeValidatedContent.backToChallenges'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_SUBTITLE = 'c.ChallengeValidatedContent.subtitle'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_SKIPBUTTON = 'c.ChallengeValidatedContent.skipButton'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_DOITAGAINBUTTON = 'c.ChallengeValidatedContent.doItAgainButton'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONTINUEBUTTON = 'c.ChallengeValidatedContent.continueButton'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_RESCHEDULECHALLENGETOAST =
    'c.ChallengeValidatedContent.rescheduleChallengeToast'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_CONGRATULATIONSTEAMDESCRIPTION =
    'c.ChallengeValidatedContent.congratulationsTeamDescription'

  public static readonly C_CHALLENGEVALIDATEDCONTENT_REDOASANHABIT = 'c.ChallengeValidatedContent.redoAsAnHabit'
  public static readonly C_CHALLENGEVALIDATEDCONTENT_TRYHABIT = 'c.ChallengeValidatedContent.tryHabit'
  public static readonly C_MORECHALLENGEENDINGSBUTTON_ALMOSTDONE = 'c.MoreChallengeEndingsButton.almostDone'
  public static readonly C_MORECHALLENGEENDINGSBUTTON_NOTCOMPLETED = 'c.MoreChallengeEndingsButton.notCompleted'
  public static readonly C_FIRSTACCEPTATION_TITLE = 'c.firstAcceptation.title'
  public static readonly C_FIRSTACCEPTATION_DESCRIPTION = 'c.firstAcceptation.description'
  public static readonly C_FIRSTACCEPTATION_BACKTOHOMEBUTTON = 'c.firstAcceptation.backToHomeButton'
  public static readonly C_FIRSTACCEPTATION_ITSAHABIT = 'c.firstAcceptation.itsAHabit'
  public static readonly C_NOWDOIT_TITLE = 'c.nowDoIt.title'
  public static readonly C_NOWDOIT_DESCRIPTION = 'c.nowDoIt.description'
  public static readonly C_NOWDOIT_BACKTOHOMEBUTTON = 'c.nowDoIt.backToHomeButton'
  public static readonly C_NOWDOIT_TOAST = 'c.nowDoIt.toast'
  public static readonly C_NOWDOIT_NEWJOKER = 'c.nowDoIt.newJoker'
  public static readonly C_NOWDOIT_PLANNED = 'c.nowDoIt.planned'
  public static readonly C_DOITIRL_TITLE = 'c.doItIrl.title'
  public static readonly C_DOITIRL_DESCRIPTION = 'c.doItIrl.description'
  public static readonly C_DOITIRL_BACKTOHOMEBUTTON = 'c.doItIrl.backToHomeButton'
  public static readonly C_ADDFREQUENCYFORM_KEEPFREQUENCY = 'c.addFrequencyForm.keepFrequency'
  public static readonly C_ADDFREQUENCYFORM_ADJUSTFREQUENCY = 'c.addFrequencyForm.adjustFrequency'
  public static readonly C_ADDFREQUENCYFORM_ENDFREQUENCY = 'c.addFrequencyForm.endFrequency'
  public static readonly C_NEWPASSWORDSTYLEDINPUT_PASSWORDLABEL = 'c.NewPasswordStyledInput.passwordLabel'
  public static readonly C_NEWPASSWORDSTYLEDINPUT_PASSWORDPLACEHOLDER = 'c.NewPasswordStyledInput.passwordPlaceholder'
  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_SIMPLEPOLICY_PASSWORDLENGTHMESSAGE =
    'c.NewPasswordStyledInput.errors.simplePolicy.passwordLengthMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_STRONGPOLICY_PASSWORDLENGTHMESSAGE =
    'c.NewPasswordStyledInput.errors.strongPolicy.passwordLengthMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_STRONGPOLICY_PASSWORDLOWERCASELETTERMESSAGE =
    'c.NewPasswordStyledInput.errors.strongPolicy.passwordLowercaseLetterMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_STRONGPOLICY_PASSWORDUPPERCASELETTERMESSAGE =
    'c.NewPasswordStyledInput.errors.strongPolicy.passwordUppercaseLetterMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_STRONGPOLICY_PASSWORDDIGITMESSAGE =
    'c.NewPasswordStyledInput.errors.strongPolicy.passwordDigitMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_STRONGPOLICY_PASSWORDNONALPHANUMERICMESSAGE =
    'c.NewPasswordStyledInput.errors.strongPolicy.passwordNonAlphanumericMessage'

  public static readonly C_NEWPASSWORDSTYLEDINPUT_ERRORS_VERYSTRONGPOLICY_PASSWORDLENGTHMESSAGE =
    'c.NewPasswordStyledInput.errors.veryStrongPolicy.passwordLengthMessage'

  public static readonly C_USERMODULEUPDATERWIDGET_STARTINTHEPASTWARNING =
    'c.UserModuleUpdaterWidget.startInThePastWarning'

  public static readonly C_USERMODULEUPDATERWIDGET_STARTDAYLABEL = 'c.UserModuleUpdaterWidget.startDayLabel'
  public static readonly C_USERMODULEUPDATERWIDGET_STARTHOURLABEL = 'c.UserModuleUpdaterWidget.startHourLabel'
  public static readonly C_USERMODULEUPDATERWIDGET_TEAMLABEL = 'c.UserModuleUpdaterWidget.teamLabel'
  public static readonly C_USERMODULEUPDATERWIDGET_LAUNCHLABEL = 'c.UserModuleUpdaterWidget.launchLabel'
  public static readonly C_USERMODULEUPDATERWIDGET_NOLAUNCHAVAILABLE = 'c.UserModuleUpdaterWidget.noLaunchAvailable'
  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SHORTSPRINTSUMMARY =
    'c.SprintBatchUserImportFunnel.users.shortSprintSummary'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_TITLE =
    'c.SprintBatchUserImportFunnel.users.upload.title'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_STEP1NAME =
    'c.SprintBatchUserImportFunnel.users.upload.step1Name'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_DOWNLOADTEMPLATELABEL =
    'c.SprintBatchUserImportFunnel.users.upload.downloadTemplateLabel'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_STEP2NAME =
    'c.SprintBatchUserImportFunnel.users.upload.step2Name'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_LEARNMORE =
    'c.SprintBatchUserImportFunnel.users.upload.learnMore'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_STEP2BODY =
    'c.SprintBatchUserImportFunnel.users.upload.step2Body'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_STEP3NAME =
    'c.SprintBatchUserImportFunnel.users.upload.step3Name'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_STEP3BODY =
    'c.SprintBatchUserImportFunnel.users.upload.step3Body'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_SELECTFILEBUTTON =
    'c.SprintBatchUserImportFunnel.users.upload.selectFileButton'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_BACKBUTTONLABEL =
    'c.SprintBatchUserImportFunnel.users.upload.backButtonLabel'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ADDUSERSBUTTONLABEL =
    'c.SprintBatchUserImportFunnel.users.upload.addUsersButtonLabel'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_VALIDATIONMESSAGE =
    'c.SprintBatchUserImportFunnel.users.upload.validationMessage'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FAILEDDESCRIPTION =
    'c.SprintBatchUserImportFunnel.users.upload.errors.failedDescription'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FILEUPLOADVALIDATIONERROR =
    'c.SprintBatchUserImportFunnel.users.upload.errors.fileUploadValidationError'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_UNKNOWNERROR =
    'c.SprintBatchUserImportFunnel.users.upload.errors.UnknownError'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_IMPACTEDCELLS =
    'c.SprintBatchUserImportFunnel.users.upload.errors.impactedCells'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_COLUMNSTRUCTUREHASBEENCHANGED =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.ColumnStructureHasBeenChanged'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_COULDNOTREADFILE =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.CouldNotReadFile'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_DATAVALIDATIONERROR =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.DataValidationError'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_EXTENSIONNOTSUPPORTED =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.ExtensionNotSupported'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_MISSINGREQUIREDSHEET =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.MissingRequiredSheet'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_GENERALERRORTYPES_MISSINGPARAMETERSSHEET =
    'c.SprintBatchUserImportFunnel.users.upload.errors.generalErrorTypes.MissingParametersSheet'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FILEVALIDATIONERRORTYPE_EMAILWRONGFORMAT =
    'c.SprintBatchUserImportFunnel.users.upload.errors.fileValidationErrorType.EmailWrongFormat'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FILEVALIDATIONERRORTYPE_HEADERNOTRECOGNIZED =
    'c.SprintBatchUserImportFunnel.users.upload.errors.fileValidationErrorType.HeaderNotRecognized'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FILEVALIDATIONERRORTYPE_MISSINGREQUIREDFIELD =
    'c.SprintBatchUserImportFunnel.users.upload.errors.fileValidationErrorType.MissingRequiredField'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_UPLOAD_ERRORS_FILEVALIDATIONERRORTYPE_NOTAMONGACCEPTEDVALUES =
    'c.SprintBatchUserImportFunnel.users.upload.errors.fileValidationErrorType.NotAmongAcceptedValues'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SUCCESS_MESSAGE =
    'c.SprintBatchUserImportFunnel.users.success.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SUCCESS_TIMEZONE =
    'c.SprintBatchUserImportFunnel.users.success.timezone'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SUCCESS_CONTACTUS =
    'c.SprintBatchUserImportFunnel.users.success.contactUs'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SUCCESS_STARTAGAINBUTTONLABEL =
    'c.SprintBatchUserImportFunnel.users.success.startAgainButtonLabel'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_SUCCESS_FINISHBUTTONLABEL =
    'c.SprintBatchUserImportFunnel.users.success.finishButtonLabel'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_PARTIALSUCCESS_MESSAGE =
    'c.SprintBatchUserImportFunnel.users.partialSuccess.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_PARTIALSUCCESS_LINKTOPROFILE =
    'c.SprintBatchUserImportFunnel.users.partialSuccess.linkToProfile'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_COMPANY_SHORTSPRINTSUMMARY =
    'c.SprintBatchUserImportFunnel.users.company.shortSprintSummary'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_COMPANY_SUCCESS_MESSAGE =
    'c.SprintBatchUserImportFunnel.users.company.success.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_COMPANY_UPLOAD_TITLE =
    'c.SprintBatchUserImportFunnel.users.company.upload.title'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_CLIENT_SHORTSPRINTSUMMARY =
    'c.SprintBatchUserImportFunnel.users.client.shortSprintSummary'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_CLIENT_SUCCESS_MESSAGE =
    'c.SprintBatchUserImportFunnel.users.client.success.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_USERS_CLIENT_UPLOAD_TITLE =
    'c.SprintBatchUserImportFunnel.users.client.upload.title'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TITLE = 'c.SprintBatchUserImportFunnel.help.title'
  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_SPRINTTITLE =
    'c.SprintBatchUserImportFunnel.help.sprintTitle'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_SPRINTDEFINITION =
    'c.SprintBatchUserImportFunnel.help.sprintDefinition'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_EXCELTEMPLATETITLE =
    'c.SprintBatchUserImportFunnel.help.excelTemplateTitle'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TEMPLATEEXPLANATION =
    'c.SprintBatchUserImportFunnel.help.templateExplanation'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_MANDATORYFIELDSTITLE =
    'c.SprintBatchUserImportFunnel.help.mandatoryFieldsTitle'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_OPTIONALFIELDSTITLE =
    'c.SprintBatchUserImportFunnel.help.optionalFieldsTitle'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_HEADERROW_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.headerRow.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_HEADERROW_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.headerRow.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_0_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.0.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_0_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.0.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_1_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.1.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_1_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.1.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_2_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.2.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_MANDATORYROWS_2_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.mandatoryRows.2.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_0_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.0.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_0_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.0.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_1_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.1.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_1_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.1.description'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_2_COLUMN =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.2.column'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNEL_HELP_TABLE_OPTIONALROWS_2_DESCRIPTION =
    'c.SprintBatchUserImportFunnel.help.table.optionalRows.2.description'

  public static readonly C_NEXTMODULES_TITLE_CREATE_HASGENERICMODULES = 'c.nextModules.title.create.hasGenericModules'
  public static readonly C_NEXTMODULES_TITLE_CREATE_NOGENERICMODULES = 'c.nextModules.title.create.noGenericModules'
  public static readonly C_NEXTMODULES_TITLE_UPDATE = 'c.nextModules.title.update'
  public static readonly C_NEXTMODULES_DESCRIPTION = 'c.nextModules.description'
  public static readonly C_NEXTMODULES_CHOOSE = 'c.nextModules.choose'
  public static readonly C_NEXTMODULES_MODULESALREADYDONE = 'c.nextModules.modulesAlreadyDone'
  public static readonly C_NEXTMODULES_GENERICMODULES = 'c.nextModules.genericModules'
  public static readonly C_NEXTMODULES_CREATIONERROR = 'c.nextModules.creationError'
  public static readonly C_NEXTMODULES_COMPANYSPECIFICTAG = 'c.nextModules.companySpecificTag'
  public static readonly C_NEXTMODULES_GENERICTAG = 'c.nextModules.genericTag'
  public static readonly C_NEXTMODULES_CLIENTSPECIFICTAG = 'c.nextModules.clientSpecificTag'
  public static readonly C_FIFTYCONFIRMATIONPOPUP_CANCEL = 'c.FiftyConfirmationPopup.cancel'
  public static readonly C_FIFTYCONFIRMATIONPOPUP_CONFIRM = 'c.FiftyConfirmationPopup.confirm'
  public static readonly C_FIFTYHORIZONTALBARDIAGRAM_DISPLAYALLITEMSLINK =
    'c.FiftyHorizontalBarDiagram.displayAllItemsLink'

  public static readonly C_ADMINPROGRAMCAMPAIGNS_SHAREDACCESS_DISABLED_TOOLTIP =
    'c.adminProgramCampaigns.sharedAccess.disabled.tooltip'

  public static readonly C_ADMINPROGRAMCAMPAIGNS_SHAREDACCESS_DISABLEDVIEW_TOOLTIP =
    'c.adminProgramCampaigns.sharedAccess.disabledView.tooltip'

  public static readonly C_ADMINPROGRAM_CATEGORYCHALLENGESLIST_SHAREDACCESS_DISABLED_TOOLTIP =
    'c.adminProgram.CategoryChallengesList.sharedAccess.disabled.tooltip'

  public static readonly C_ADMINKPI_RATECARD_NODATA = 'c.adminKPI.rateCard.noData'
  public static readonly C_SPRINTBATCHUSERIMPORTFUNNE_PARTIALSUCCESS_ALERTOVERLAP_MESSAGE =
    'c.SprintBatchUserImportFunne.partialSuccess.alertOverlap.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNE_PARTIALSUCCESS_ALERTTHRESHOLD_MESSAGE =
    'c.SprintBatchUserImportFunne.partialSuccess.alertThreshold.message'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNE_PARTIALSUCCESS_ALERTTHRESHOLD_ADDTHEMANYWAY =
    'c.SprintBatchUserImportFunne.partialSuccess.alertThreshold.addThemAnyway'

  public static readonly C_SPRINTBATCHUSERIMPORTFUNNE_PARTIALSUCCESS_ALERTSUCCESS_MESSAGE =
    'c.SprintBatchUserImportFunne.partialSuccess.alertSuccess.message'

  public static readonly C_BOOSTERLANDING_TITLE = 'c.boosterLanding.title'
  public static readonly C_BOOSTERLANDING_SUBTITLE = 'c.boosterLanding.subtitle'
  public static readonly C_BOOSTERLANDING_BUTTONLABEL = 'c.boosterLanding.buttonLabel'
  public static readonly C_MANAGERPROFILES_4 = 'c.manager-profiles.4'
  public static readonly C_MANAGERPROFILES_2 = 'c.manager-profiles.2'
  public static readonly C_MANAGERPROFILES_3 = 'c.manager-profiles.3'
  public static readonly C_FIFTYSELECTNEW_NOOPTIONS = 'c.fiftySelectNew.noOptions'
  public static readonly G_GETAPPBANNER_TITLE = 'g.getAppBanner.title'
  public static readonly G_GETAPPBANNER_SUBTITLE = 'g.getAppBanner.subtitle'
  public static readonly G_GETAPPBANNER_GETLINK = 'g.getAppBanner.getLink'
  public static readonly G_GETAPPBANNER_DOWNLOAD = 'g.getAppBanner.download'
  public static readonly G_GETAPPBANNER_RECEIVESMS = 'g.getAppBanner.receiveSms'
  public static readonly G_COOKIEACCEPTATION_DESCRIPTION = 'g.cookieAcceptation.description'
  public static readonly G_COOKIEACCEPTATION_BUTTON = 'g.cookieAcceptation.button'
  public static readonly G_NPS_TITLE = 'g.nps.title'
  public static readonly G_NPS_NOASKAGAIN = 'g.nps.noAskAgain'
  public static readonly G_NPS_MIN = 'g.nps.min'
  public static readonly G_NPS_MAX = 'g.nps.max'
  public static readonly G_NPS_WHYNPS = 'g.nps.whyNps'
  public static readonly G_NPS_SUBMITFEEDBACK = 'g.nps.submitFeedback'
  public static readonly G_NPS_SKIPFEEDBACK = 'g.nps.skipFeedback'
  public static readonly G_NPS_FEEDBACKPLACEHOLDER = 'g.nps.feedbackPlaceholder'
  public static readonly G_NPS_THANKS = 'g.nps.thanks'
  public static readonly G_NPS_FEEDBACKTHANKSEXTENDED = 'g.nps.feedbackThanksExtended'
  public static readonly G_NPS_CLOSE = 'g.nps.close'
  public static readonly G_NONETWORK = 'g.noNetwork'
  public static readonly G_UNKNOWNERROR = 'g.unknownError'
  public static readonly G_SAVEDCHANGES = 'g.savedChanges'
  public static readonly G_TRYAGAINLATER = 'g.tryAgainLater'
  public static readonly G_UNKNOWNERRORMESSAGE = 'g.unknownErrorMessage'
  public static readonly G_LANGUAGE = 'g.language'
  public static readonly G_SELECTDEFAULT = 'g.selectDefault'
  public static readonly G_YES = 'g.yes'
  public static readonly G_NO = 'g.no'
  public static readonly G_UPDATE = 'g.update'
  public static readonly G_ENUM_CHALLENGEDURATION_ONTHEGO = 'g.enum.ChallengeDuration.onTheGo'
  public static readonly G_ENUM_CHALLENGEDURATION_MINUTE = 'g.enum.ChallengeDuration.minute'
  public static readonly G_ENUM_CHALLENGEDURATION_HOUR = 'g.enum.ChallengeDuration.hour'
  public static readonly G_ENUM_USERGOAL_FIVEINAMODULE = 'g.enum.UserGoal.FiveInAModule'
  public static readonly G_ENUM_USERGOAL_FIFTEENINAMODULE = 'g.enum.UserGoal.FifteenInAModule'
  public static readonly G_ENUM_USERGOAL_TWENTYFIVEINAMODULE = 'g.enum.UserGoal.TwentyFiveInAModule'
  public static readonly G_ENUM_SUPPORTEDLANGUAGEDTO_FR = 'g.enum.SupportedLanguageDto.Fr'
  public static readonly G_ENUM_SUPPORTEDLANGUAGEDTO_EN = 'g.enum.SupportedLanguageDto.En'
  public static readonly G_ENUM_SUPPORTEDLANGUAGEDTO_DE = 'g.enum.SupportedLanguageDto.De'
  public static readonly G_ENUM_SUPPORTEDLANGUAGEDTO_ES = 'g.enum.SupportedLanguageDto.Es'
  public static readonly G_ENUM_MESSAGECATEGORYTYPE_NEWSESSION_TITLE = 'g.enum.MessageCategoryType.NewSession.title'
  public static readonly G_ENUM_MESSAGECATEGORYTYPE_NEWSESSION_DESCRIPTION =
    'g.enum.MessageCategoryType.NewSession.description'

  public static readonly G_ENUM_MESSAGECATEGORYTYPE_SESSIONREMINDER_TITLE =
    'g.enum.MessageCategoryType.SessionReminder.title'

  public static readonly G_ENUM_MESSAGECATEGORYTYPE_SESSIONREMINDER_DESCRIPTION =
    'g.enum.MessageCategoryType.SessionReminder.description'

  public static readonly G_ENUM_MESSAGECATEGORYTYPE_MORNINGREMINDER_TITLE =
    'g.enum.MessageCategoryType.MorningReminder.title'

  public static readonly G_ENUM_MESSAGECATEGORYTYPE_MORNINGREMINDER_DESCRIPTION =
    'g.enum.MessageCategoryType.MorningReminder.description'

  public static readonly G_ENUM_MESSAGECHANNELTYPE_EMAIL = 'g.enum.MessageChannelType.Email'
  public static readonly G_ENUM_MESSAGECHANNELTYPE_MOBILEAPPNOTIFICATION =
    'g.enum.MessageChannelType.MobileAppNotification'

  public static readonly G_ENUM_MESSAGECHANNELTYPE_MICROSOFTTEAMSMESSAGE =
    'g.enum.MessageChannelType.MicrosoftTeamsMessage'

  public static readonly G_ENUM_FIFTYROLEDTO_USER = 'g.enum.FiftyRoleDto.User'
  public static readonly G_ENUM_FIFTYROLEDTO_FIFTYEMPLOYEEADMIN = 'g.enum.FiftyRoleDto.FiftyEmployeeAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_SUPERADMIN = 'g.enum.FiftyRoleDto.SuperAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_CUSTOMERADMIN = 'g.enum.FiftyRoleDto.CustomerAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_SUPERFIFTYEMPLOYEEADMIN = 'g.enum.FiftyRoleDto.SuperFiftyEmployeeAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_CUSTOMERCONTENTADMIN = 'g.enum.FiftyRoleDto.CustomerContentAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_CUSTOMERRESULTADMIN = 'g.enum.FiftyRoleDto.CustomerResultAdmin'
  public static readonly G_ENUM_FIFTYROLEDTO_ALL = 'g.enum.FiftyRoleDto.all'
  public static readonly G_ENUM_REMINDERCHANNELTYPEDTO_SMS = 'g.enum.ReminderChannelTypeDto.Sms'
  public static readonly G_ENUM_REMINDERCHANNELTYPEDTO_MOBILEAPPNOTIFICATION =
    'g.enum.ReminderChannelTypeDto.MobileAppNotification'

  public static readonly G_ENUM_REMINDERCHANNELTYPEDTO_MICROSOFTTEAMSMESSAGE =
    'g.enum.ReminderChannelTypeDto.MicrosoftTeamsMessage'

  public static readonly G_ENUM_PLANNEDEXECUTIONPRESETDATE_TODAY = 'g.enum.PlannedExecutionPresetDate.today'
  public static readonly G_ENUM_PLANNEDEXECUTIONPRESETDATE_TOMORROW = 'g.enum.PlannedExecutionPresetDate.tomorrow'
  public static readonly G_ENUM_PLANNEDEXECUTIONPRESETDATE_IN2WEEKS = 'g.enum.PlannedExecutionPresetDate.in2Weeks'
  public static readonly G_ENUM_PLANNEDEXECUTIONPRESETDATE_IN1MONTH = 'g.enum.PlannedExecutionPresetDate.in1Month'
  public static readonly G_DATEPICKER_CANCEL = 'g.datepicker.cancel'
  public static readonly G_DATEPICKER_SELECT = 'g.datepicker.select'
  public static readonly G_MOBILEACCESSRESTRICTEDCARD_TEXT = 'g.mobileAccessRestrictedCard.text'
  public static readonly G_HELLO = 'g.hello'
  public static readonly P_ACTIONPLANPAGE_CHALLENGECARD_EXAMPLETAG = 'p.actionPlanPage.challengeCard.exampleTag'
  public static readonly P_ACTIONPLANPAGE_CHALLENGECARD_YOURCHALLENGETAG =
    'p.actionPlanPage.challengeCard.yourChallengeTag'

  public static readonly P_ACTIONPLANPAGE_CHALLENGECARD_CREATECALLTOACTION =
    'p.actionPlanPage.challengeCard.createCallToAction'

  public static readonly P_ACTIONPLANPAGE_CHALLENGECARD_DISABLED_TOOLTIP =
    'p.actionPlanPage.challengeCard.disabled.tooltip'

  public static readonly P_ACTIONPLANPAGE_REMOVECHALLENGECONFIRMATION_TITLE =
    'p.actionPlanPage.removeChallengeConfirmation.title'

  public static readonly P_ACTIONPLANPAGE_REMOVECHALLENGECONFIRMATION_CANCEL =
    'p.actionPlanPage.removeChallengeConfirmation.cancel'

  public static readonly P_ACTIONPLANPAGE_REMOVECHALLENGECONFIRMATION_YES =
    'p.actionPlanPage.removeChallengeConfirmation.yes'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_FORMTITLE = 'p.actionPlanPage.actionPlanForm.formTitle'
  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_FORMTITLEUPDATE =
    'p.actionPlanPage.actionPlanForm.formTitleUpdate'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_ADDTOCOURSE = 'p.actionPlanPage.actionPlanForm.addToCourse'
  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_ONLYYOU = 'p.actionPlanPage.actionPlanForm.onlyYou'
  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_COURSE = 'p.actionPlanPage.actionPlanForm.course'
  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGETITLE =
    'p.actionPlanPage.actionPlanForm.challengeTitle'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGETITLEPLACEHOLDER =
    'p.actionPlanPage.actionPlanForm.challengeTitlePlaceholder'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGEDURATION =
    'p.actionPlanPage.actionPlanForm.challengeDuration'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGEDURATIONPLACEHOLDER =
    'p.actionPlanPage.actionPlanForm.challengeDurationPlaceholder'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGENOTES =
    'p.actionPlanPage.actionPlanForm.challengeNotes'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_CHALLENGENOTESPLACEHOLDER =
    'p.actionPlanPage.actionPlanForm.challengeNotesPlaceholder'

  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_SAVE = 'p.actionPlanPage.actionPlanForm.save'
  public static readonly P_ACTIONPLANPAGE_ACTIONPLANFORM_UPDATE = 'p.actionPlanPage.actionPlanForm.update'
  public static readonly P_ACTIONPLANPAGE_CHALLENGETIPS_NEEDHELP = 'p.actionPlanPage.challengeTips.needHelp'
  public static readonly P_ACTIONPLANPAGE_CHALLENGETIPS_TIPS1 = 'p.actionPlanPage.challengeTips.tips1'
  public static readonly P_ACTIONPLANPAGE_CHALLENGETIPS_TIPS2 = 'p.actionPlanPage.challengeTips.tips2'
  public static readonly P_ACTIONPLANPAGE_CHALLENGETIPS_TIPS3 = 'p.actionPlanPage.challengeTips.tips3'
  public static readonly P_ACTIONPLANPAGE_CRUD_REMOVECONFIRMATION = 'p.actionPlanPage.crud.removeConfirmation'
  public static readonly P_ACTIONPLANPAGE_CRUD_CANCELREMOVE = 'p.actionPlanPage.crud.cancelRemove'
  public static readonly P_ACTIONPLANPAGE_CRUD_REMOVEBTN = 'p.actionPlanPage.crud.removeBtn'
  public static readonly P_INTENTIONMODAL_TITLE = 'p.intentionModal.title'
  public static readonly P_INTENTIONMODAL_TITLEV2 = 'p.intentionModal.titleV2'
  public static readonly P_INTENTIONMODAL_SUBTITLE = 'p.intentionModal.subtitle'
  public static readonly P_INTENTIONMODAL_SUBTITLEV2 = 'p.intentionModal.subtitleV2'
  public static readonly P_PERSONALIZATIONMODAL_TITLE = 'p.personalizationModal.title'
  public static readonly P_PERSONALIZATIONMODAL_SUBTITLE = 'p.personalizationModal.subtitle'
  public static readonly P_CHALLENGESPAGE_META_TITLE = 'p.challengesPage.meta.title'
  public static readonly P_CHALLENGESPAGE_META_HOMETITLE = 'p.challengesPage.meta.homeTitle'
  public static readonly P_CHALLENGESPAGE_META_MYCHALLENGESTITLE = 'p.challengesPage.meta.myChallengesTitle'
  public static readonly P_CHALLENGESPAGE_META_DESCRIPTION = 'p.challengesPage.meta.description'
  public static readonly P_CHALLENGESPAGE_HELLO = 'p.challengesPage.hello'
  public static readonly P_CHALLENGESPAGE_DOINGMODULE = 'p.challengesPage.doingModule'
  public static readonly P_CHALLENGESPAGE_DONEMODULE = 'p.challengesPage.doneModule'
  public static readonly P_CHALLENGESPAGE_COMPLETEDCHALLENGES = 'p.challengesPage.completedChallenges'
  public static readonly P_CHALLENGESPAGE_COMPLETEDSUBTITLE = 'p.challengesPage.completedSubtitle'
  public static readonly P_CHALLENGESPAGE_CURRENTMODULENAME = 'p.challengesPage.currentModuleName'
  public static readonly P_CHALLENGESPAGE_NOCHALLENGESDONEBTN = 'p.challengesPage.noChallengesDoneBtn'
  public static readonly P_CHALLENGESPAGE_NOCHALLENGESDONE = 'p.challengesPage.noChallengesDone'
  public static readonly P_CHALLENGESPAGE_NOCHALLENGESDONECOURSECOMPLETED =
    'p.challengesPage.noChallengesDoneCourseCompleted'

  public static readonly P_CHALLENGESPAGE_MYCHALLENGESCATEGORIES_DONE = 'p.challengesPage.myChallengesCategories.done'
  public static readonly P_CHALLENGESPAGE_MYCHALLENGESCATEGORIES_CREATED =
    'p.challengesPage.myChallengesCategories.created'

  public static readonly P_CHALLENGESPAGE_MYCHALLENGESCATEGORIES_ALREADYDONE =
    'p.challengesPage.myChallengesCategories.alreadyDone'

  public static readonly P_CHALLENGESPAGE_MYCHALLENGESCATEGORIES_WILLNEVERBEDONE =
    'p.challengesPage.myChallengesCategories.willNeverBeDone'

  public static readonly P_CHALLENGESPAGE_MYHABITSCATEGORIES_ONGOING = 'p.challengesPage.myHabitsCategories.ongoing'
  public static readonly P_CHALLENGESPAGE_MYHABITSCATEGORIES_ACQUIRED = 'p.challengesPage.myHabitsCategories.acquired'
  public static readonly P_CHALLENGESPAGE_MYHABITSCATEGORIES_STOPPED = 'p.challengesPage.myHabitsCategories.stopped'
  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NEXTTIME = 'p.challengesPage.shaolinAdvice.nextTime'
  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMMON_HASCOMPLETEDCOURSE =
    'p.challengesPage.shaolinAdvice.common.hasCompletedCourse'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMMON_HASMODULEENDEDNOTONBOARDED =
    'p.challengesPage.shaolinAdvice.common.hasModuleEndedNotOnBoarded'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMMON_HASMODULESTARTINGINTHEFUTURE =
    'p.challengesPage.shaolinAdvice.common.hasModuleStartingInTheFuture'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASONGOINGCHALLENGES =
    'p.challengesPage.shaolinAdvice.normalModule.hasOnGoingChallenges'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_FIRSTCHOICEFIRSTMODULE =
    'p.challengesPage.shaolinAdvice.normalModule.firstChoiceFirstModule'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASCHOICETOMAKE_1 =
    'p.challengesPage.shaolinAdvice.normalModule.hasChoiceToMake_1'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASCHOICETOMAKE_2 =
    'p.challengesPage.shaolinAdvice.normalModule.hasChoiceToMake_2'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASCHOICETOMAKE_3 =
    'p.challengesPage.shaolinAdvice.normalModule.hasChoiceToMake_3'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASCHOICETOMAKE_4 =
    'p.challengesPage.shaolinAdvice.normalModule.hasChoiceToMake_4'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_HASCHOICETOMAKE_5 =
    'p.challengesPage.shaolinAdvice.normalModule.hasChoiceToMake_5'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_NORMALMODULE_FIRSTCHOICENEXTMODULES =
    'p.challengesPage.shaolinAdvice.normalModule.firstChoiceNextModules'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMPLETEDALL_CONGRATULATIONTITLE =
    'p.challengesPage.shaolinAdvice.completedAll.congratulationTitle'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMPLETEDALL_TAKEBREAKADVICE =
    'p.challengesPage.shaolinAdvice.completedAll.takeBreakAdvice'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMPLETEDALL_TIMETOTAKEBREAKADVICE =
    'p.challengesPage.shaolinAdvice.completedAll.timeToTakeBreakAdvice'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMPLETEDALL_COMEBACKLATER =
    'p.challengesPage.shaolinAdvice.completedAll.comebackLater'

  public static readonly P_CHALLENGESPAGE_SHAOLINADVICE_COMPLETEDALL_INCREASEBTN =
    'p.challengesPage.shaolinAdvice.completedAll.increaseBtn'

  public static readonly P_ABOUTPAGE_META_TITLE = 'p.aboutPage.meta.title'
  public static readonly P_ABOUTPAGE_META_DESCRIPTION = 'p.aboutPage.meta.description'
  public static readonly P_LOGINPAGE_META_TITLE = 'p.loginPage.meta.title'
  public static readonly P_LOGINPAGE_META_DESCRIPTION = 'p.loginPage.meta.description'
  public static readonly P_LOGINPAGE_BACKTOHOME = 'p.loginPage.backToHome'
  public static readonly P_LOGINPAGE_TITLE = 'p.loginPage.title'
  public static readonly P_LOGINPAGE_EMAILLABEL = 'p.loginPage.emailLabel'
  public static readonly P_LOGINPAGE_EMAILPLACEHOLDER = 'p.loginPage.emailPlaceholder'
  public static readonly P_LOGINPAGE_PASSWORDLABEL = 'p.loginPage.passwordLabel'
  public static readonly P_LOGINPAGE_PASSWORDPLACEHOLDER = 'p.loginPage.passwordPlaceholder'
  public static readonly P_LOGINPAGE_BUTTONLABEL = 'p.loginPage.buttonLabel'
  public static readonly P_LOGINPAGE_POSTPRELOGIN = 'p.loginPage.postPreLogin'
  public static readonly P_LOGINPAGE_RESETPASSWORDLINK = 'p.loginPage.resetPasswordLink'
  public static readonly P_LOGINPAGE_ERRORS_UNKNOWNERROR = 'p.loginPage.errors.unknownError'
  public static readonly P_LOGINPAGE_ERRORS_UNKNOWNERRORWITHCODE = 'p.loginPage.errors.unknownErrorWithCode'
  public static readonly P_LOGINPAGE_ERRORS_SSOIDPINTERNALERROR = 'p.loginPage.errors.ssoIdpInternalError'
  public static readonly P_LOGINPAGE_ERRORS_INVALIDEMAIL = 'p.loginPage.errors.invalidEmail'
  public static readonly P_LOGINPAGE_ERRORS_MANDATORYEMAIL = 'p.loginPage.errors.mandatoryEmail'
  public static readonly P_LOGINPAGE_ERRORS_MANDATORYPASSWORD = 'p.loginPage.errors.mandatoryPassword'
  public static readonly P_LOGINPAGE_ERRORS_INVALIDPASSWORD = 'p.loginPage.errors.invalidPassword'
  public static readonly P_LOGINPAGE_ERRORS_NOUSERFOUND = 'p.loginPage.errors.noUserFound'
  public static readonly P_LOGINPAGE_ERRORS_LINKEXPIRED = 'p.loginPage.errors.linkExpired'
  public static readonly P_LOGINPAGE_ERRORS_TOOMANYATTEMPTS = 'p.loginPage.errors.tooManyAttempts'
  public static readonly P_LOGINPAGE_ERRORS_MFACANTBESENT = 'p.loginPage.errors.mfaCantBeSent'
  public static readonly P_LOGINPAGE_ERRORS_MFAOVERFLOW = 'p.loginPage.errors.mfaOverflow'
  public static readonly P_LOGINPAGE_MFANEEDED = 'p.loginPage.mfaNeeded'
  public static readonly P_LOGINPAGE_SEPARATOR = 'p.loginPage.separator'
  public static readonly P_LOGINPAGE_LOGINWITHEMAILHELP = 'p.loginPage.loginWithEmailHelp'
  public static readonly P_LOGINPAGE_LOGINWITHEMAIL = 'p.loginPage.loginWithEmail'
  public static readonly P_LOGINPAGE_EMAILCONFIRMATIONNEEDED = 'p.loginPage.emailConfirmationNeeded'
  public static readonly P_LOGINPAGE_EMAILCONFIRMATIONFAILED = 'p.loginPage.emailConfirmationFailed'
  public static readonly P_LOGINPAGE_INFOSLIDE_SUBTITLE = 'p.loginPage.infoSlide.subtitle'
  public static readonly P_LOGINPAGE_INFOSLIDE_DESCRIPTION = 'p.loginPage.infoSlide.description'
  public static readonly P_RESETPASSWORDREQUESTPAGE_META_TITLE = 'p.resetPasswordRequestPage.meta.title'
  public static readonly P_RESETPASSWORDREQUESTPAGE_META_DESCRIPTION = 'p.resetPasswordRequestPage.meta.description'
  public static readonly P_RESETPASSWORDREQUESTPAGE_TITLE = 'p.resetPasswordRequestPage.title'
  public static readonly P_RESETPASSWORDREQUESTPAGE_DESCRIPTION = 'p.resetPasswordRequestPage.description'
  public static readonly P_RESETPASSWORDREQUESTPAGE_EMAILLABEL = 'p.resetPasswordRequestPage.emailLabel'
  public static readonly P_RESETPASSWORDREQUESTPAGE_EMAILPLACEHOLDER = 'p.resetPasswordRequestPage.emailPlaceholder'
  public static readonly P_RESETPASSWORDREQUESTPAGE_BUTTONLABEL = 'p.resetPasswordRequestPage.buttonLabel'
  public static readonly P_RESETPASSWORDREQUESTPAGE_SENTTITLE = 'p.resetPasswordRequestPage.sentTitle'
  public static readonly P_RESETPASSWORDREQUESTPAGE_SENTDESCRIPTION = 'p.resetPasswordRequestPage.sentDescription'
  public static readonly P_RESETPASSWORDREQUESTPAGE_SENTLINK = 'p.resetPasswordRequestPage.sentLink'
  public static readonly P_RESETPASSWORDREQUESTPAGE_ERRORS_UNKNOWNERROR =
    'p.resetPasswordRequestPage.errors.unknownError'

  public static readonly P_RESETPASSWORDREQUESTPAGE_ERRORS_INVALIDEMAIL =
    'p.resetPasswordRequestPage.errors.invalidEmail'

  public static readonly P_RESETPASSWORDREQUESTPAGE_ERRORS_MANDATORYEMAIL =
    'p.resetPasswordRequestPage.errors.mandatoryEmail'

  public static readonly P_RESETPASSWORDREQUESTPAGE_ERRORS_NOUSERFOUND = 'p.resetPasswordRequestPage.errors.noUserFound'
  public static readonly P_RESETPASSWORDREQUESTPAGE_ERRORS_INADEQUATEAUTHTYPE =
    'p.resetPasswordRequestPage.errors.inadequateAuthType'

  public static readonly P_RESETPASSWORDPAGE_META_TITLE = 'p.resetPasswordPage.meta.title'
  public static readonly P_RESETPASSWORDPAGE_META_DESCRIPTION = 'p.resetPasswordPage.meta.description'
  public static readonly P_RESETPASSWORDPAGE_TITLE = 'p.resetPasswordPage.title'
  public static readonly P_RESETPASSWORDPAGE_BUTTONLABEL = 'p.resetPasswordPage.buttonLabel'
  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDTITLE = 'p.resetPasswordPage.passwordChangedTitle'
  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDDESCRIPTION =
    'p.resetPasswordPage.passwordChangedDescription'

  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDLINKNOTAUTH =
    'p.resetPasswordPage.passwordChangedLinkNotAuth'

  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDLINKAUTH = 'p.resetPasswordPage.passwordChangedLinkAuth'
  public static readonly P_RESETPASSWORDPAGE_ERRORS_UNKNOWNERROR = 'p.resetPasswordPage.errors.unknownError'
  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDWITHERRORS_TITLE =
    'p.resetPasswordPage.passwordChangedWithErrors.title'

  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDWITHERRORS_DESCRIPTION1 =
    'p.resetPasswordPage.passwordChangedWithErrors.description1'

  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDWITHERRORS_DESCRIPTION2 =
    'p.resetPasswordPage.passwordChangedWithErrors.description2'

  public static readonly P_RESETPASSWORDPAGE_PASSWORDCHANGEDWITHERRORS_LINK =
    'p.resetPasswordPage.passwordChangedWithErrors.link'

  public static readonly P_CHALLENGEPAGE_META_TITLE = 'p.challengePage.meta.title'
  public static readonly P_CHALLENGEPAGE_META_DESCRIPTION = 'p.challengePage.meta.description'
  public static readonly P_CHALLENGEPAGE_DOUBTBACK = 'p.challengePage.doubtBack'
  public static readonly P_CHALLENGEPAGE_BACK = 'p.challengePage.back'
  public static readonly P_CHALLENGEPAGE_EXECUTIONATTIME = 'p.challengePage.executionAtTime'
  public static readonly P_CHALLENGEPAGE_MYNOTES = 'p.challengePage.myNotes'
  public static readonly P_CHALLENGEPAGE_ACTIONS_CLOSEDCHALLENGETITLE = 'p.challengePage.actions.closedChallengeTitle'
  public static readonly P_CHALLENGEPAGE_ACTIONS_CONGRATULATION = 'p.challengePage.actions.congratulation'
  public static readonly P_CHALLENGEPAGE_ACTIONS_ACCEPTIT = 'p.challengePage.actions.acceptIt'
  public static readonly P_CHALLENGEPAGE_ACTIONS_DOIT = 'p.challengePage.actions.doIt'
  public static readonly P_CHALLENGEPAGE_ACTIONS_DOITFREQUENCY = 'p.challengePage.actions.doItFrequency'
  public static readonly P_CHALLENGEPAGE_ACTIONS_EXECUTIONDATETITLE = 'p.challengePage.actions.executionDateTitle'
  public static readonly P_CHALLENGEPAGE_ACTIONS_UPDATEEXECUTIONDATE = 'p.challengePage.actions.updateExecutionDate'
  public static readonly P_CHALLENGEPAGE_CATEGORY_FORCATEGORY = 'p.challengePage.category.forCategory'
  public static readonly P_CHALLENGEPAGE_CATEGORY_EXPLANATION = 'p.challengePage.category.explanation'
  public static readonly P_ACCOUNTPAGE_META_TITLE = 'p.accountPage.meta.title'
  public static readonly P_ACCOUNTPAGE_META_DESCRIPTION = 'p.accountPage.meta.description'
  public static readonly P_ACCOUNTPAGE_ERRORSAVING = 'p.accountPage.errorSaving'
  public static readonly P_ACCOUNTPAGE_MYACCOUNTTITLE = 'p.accountPage.myAccountTitle'
  public static readonly P_ACCOUNTPAGE_SAVED = 'p.accountPage.saved'
  public static readonly P_ACCOUNTPAGE_FIRSTNAME = 'p.accountPage.firstName'
  public static readonly P_ACCOUNTPAGE_LASTNAME = 'p.accountPage.lastName'
  public static readonly P_ACCOUNTPAGE_EMAIL = 'p.accountPage.email'
  public static readonly P_ACCOUNTPAGE_PHONE = 'p.accountPage.phone'
  public static readonly P_ACCOUNTPAGE_TIMEZONE = 'p.accountPage.timeZone'
  public static readonly P_ACCOUNTPAGE_PHONEDISCLAIMER = 'p.accountPage.phoneDisclaimer'
  public static readonly P_ACCOUNTPAGE_DISCONNECTTITLE = 'p.accountPage.disconnectTitle'
  public static readonly P_ACCOUNTPAGE_DISCONNECT = 'p.accountPage.disconnect'
  public static readonly P_ACCOUNTPAGE_ENVIRONMENTTITLE = 'p.accountPage.environmentTitle'
  public static readonly P_ACCOUNTPAGE_IAM = 'p.accountPage.iAm'
  public static readonly P_ACCOUNTPAGE_DOTHETEST = 'p.accountPage.doTheTest'
  public static readonly P_ACCOUNTPAGE_PERSONALIZATION_TITLE = 'p.accountPage.personalization.title'
  public static readonly P_ACCOUNTPAGE_PERSONALIZATION_SUBTITLE = 'p.accountPage.personalization.subtitle'
  public static readonly P_ACCOUNTPAGE_CATEGORIESORDER_TITLE = 'p.accountPage.categoriesOrder.title'
  public static readonly P_ACCOUNTPAGE_CATEGORIESORDER_MODULESTART = 'p.accountPage.categoriesOrder.moduleStart'
  public static readonly P_ACCOUNTPAGE_CATEGORIESORDER_DESCRIPTION = 'p.accountPage.categoriesOrder.description'
  public static readonly P_ACCOUNTPAGE_CATEGORIESORDER_TITLESMALL = 'p.accountPage.categoriesOrder.titleSmall'
  public static readonly P_ACCOUNTPAGE_NOTIFICATION_TITLE = 'p.accountPage.notification.title'
  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_INSTRUCTIONS =
    'p.accountPage.notification.getAppModal.instructions'

  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_PHONEPLACEHOLDER =
    'p.accountPage.notification.getAppModal.phonePlaceholder'

  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_BUTTON = 'p.accountPage.notification.getAppModal.button'
  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_STOREINSTRUCTIONS =
    'p.accountPage.notification.getAppModal.storeInstructions'

  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_STOREINSTRUCTIONSV2 =
    'p.accountPage.notification.getAppModal.storeInstructionsV2'

  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_MOBILEINSTRUCTIONS =
    'p.accountPage.notification.getAppModal.mobileInstructions'

  public static readonly P_ACCOUNTPAGE_NOTIFICATION_GETAPPMODAL_MOBILEBUTTON =
    'p.accountPage.notification.getAppModal.mobileButton'

  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_CONTAINERTITLE = 'p.accountPage.sessionFrequency.containerTitle'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_DESCRIPTION = 'p.accountPage.sessionFrequency.description'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_0_TITLE = 'p.accountPage.sessionFrequency.0.title'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_0_SUBTITLE = 'p.accountPage.sessionFrequency.0.subTitle'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_1_TITLE = 'p.accountPage.sessionFrequency.1.title'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_1_SUBTITLE = 'p.accountPage.sessionFrequency.1.subTitle'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_2_TITLE = 'p.accountPage.sessionFrequency.2.title'
  public static readonly P_ACCOUNTPAGE_SESSIONFREQUENCY_2_SUBTITLE = 'p.accountPage.sessionFrequency.2.subTitle'
  public static readonly P_ACCOUNTPAGE_PERSONAE_TITLE = 'p.accountPage.personae.title'
  public static readonly P_ACCOUNTPAGE_PERSONAE_DESCRIPTION = 'p.accountPage.personae.description'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_TAKEBREAK = 'p.accountPage.setUserOnBreak.takeBreak'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_ENDBREAK = 'p.accountPage.setUserOnBreak.endBreak'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_DATEPICKERADVICE = 'p.accountPage.setUserOnBreak.datepickerAdvice'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_ENDPREVIEW = 'p.accountPage.setUserOnBreak.endPreview'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_STARTENDADVICE = 'p.accountPage.setUserOnBreak.startEndAdvice'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_ENDBREAKONENDMODULEWARNING =
    'p.accountPage.setUserOnBreak.endBreakOnEndModuleWarning'

  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_CHOSESTART = 'p.accountPage.setUserOnBreak.choseStart'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_CHOSEEND = 'p.accountPage.setUserOnBreak.choseEnd'
  public static readonly P_ACCOUNTPAGE_SETUSERONBREAK_CHOOSE = 'p.accountPage.setUserOnBreak.choose'
  public static readonly P_ACCOUNTPAGE_RESETPASSWORDLABEL = 'p.accountPage.resetPasswordLabel'
  public static readonly P_ACCOUNTPAGE_RESETPASSWORDEMAILSENT = 'p.accountPage.resetPasswordEmailSent'
  public static readonly P_ACCOUNTPAGE_APPLICATION_TITLE = 'p.accountPage.application.title'
  public static readonly P_ACCOUNTPAGE_APPLICATION_SUBTITLE_DESKTOP = 'p.accountPage.application.subtitle.desktop'
  public static readonly P_ACCOUNTPAGE_APPLICATION_SUBTITLE_MOBILE = 'p.accountPage.application.subtitle.mobile'
  public static readonly P_ACCOUNTPAGE_APPLICATION_GETAPPLINK_DESCRIPTION =
    'p.accountPage.application.getAppLink.description'

  public static readonly P_ACCOUNTPAGE_APPLICATION_GETAPPLINK_BUTTON = 'p.accountPage.application.getAppLink.button'
  public static readonly P_ACCOUNTPAGE_LANGUAGE_TITLE = 'p.accountPage.language.title'
  public static readonly P_ACCOUNTPAGE_PASSWORD = 'p.accountPage.password'
  public static readonly P_CATEGORYPAGE_META_TITLE = 'p.categoryPage.meta.title'
  public static readonly P_CATEGORYPAGE_META_DESCRIPTION = 'p.categoryPage.meta.description'
  public static readonly P_CATEGORYPAGE_NEXTCATEGORY = 'p.categoryPage.nextCategory'
  public static readonly P_CATEGORYPAGE_PREVIOUSCATEGORY = 'p.categoryPage.previousCategory'
  public static readonly P_MODULENOTSTARTED_TITLE = 'p.moduleNotStarted.title'
  public static readonly P_MODULENOTSTARTED_STARTAT = 'p.moduleNotStarted.startAt'
  public static readonly P_MODULENOTSTARTED_WHATISFIFTY = 'p.moduleNotStarted.whatIsFifty'
  public static readonly P_MODULENOTSTARTED_FIFTYDESCRIPTION = 'p.moduleNotStarted.fiftyDescription'
  public static readonly P_MODULENOTSTARTED_NOW = 'p.moduleNotStarted.now'
  public static readonly P_MODULENOTSTARTED_WAIT = 'p.moduleNotStarted.wait'
  public static readonly P_MODULENOTSTARTED_ACCOUNTCREATED = 'p.moduleNotStarted.accountCreated'
  public static readonly P_OBINTRODUCTIONPAGE_META_TITLE = 'p.obIntroductionPage.meta.title'
  public static readonly P_OBINTRODUCTIONPAGE_META_DESCRIPTION = 'p.obIntroductionPage.meta.description'
  public static readonly P_OBINTRODUCTIONPAGE_OBTITLE = 'p.obIntroductionPage.obTitle'
  public static readonly P_OBINTRODUCTIONPAGE_OBTITLEMANYMODULE = 'p.obIntroductionPage.obTitleManyModule'
  public static readonly P_OBINTRODUCTIONPAGE_EXISTINGACCOUNT_TEXT = 'p.obIntroductionPage.existingAccount.text'
  public static readonly P_OBINTRODUCTIONPAGE_EXISTINGACCOUNT_LINK = 'p.obIntroductionPage.existingAccount.link'
  public static readonly P_OBINTRODUCTIONPAGE_SUBTITLERETURNINGUSER = 'p.obIntroductionPage.subtitleReturningUser'
  public static readonly P_OBINTRODUCTIONPAGE_FIRSTLOGINCHANGEPASSWORD = 'p.obIntroductionPage.firstLoginChangePassword'
  public static readonly P_OBINTRODUCTIONPAGE_ACTIONS = 'p.obIntroductionPage.actions'
  public static readonly P_OBINTRODUCTIONPAGE_ACTIONDESCRIPTION = 'p.obIntroductionPage.actionDescription'
  public static readonly P_OBINTRODUCTIONPAGE_FREQUENCY = 'p.obIntroductionPage.frequency'
  public static readonly P_OBINTRODUCTIONPAGE_FREQUENCYDESCRIPTION = 'p.obIntroductionPage.frequencyDescription'
  public static readonly P_OBINTRODUCTIONPAGE_ANONYMOUS = 'p.obIntroductionPage.anonymous'
  public static readonly P_OBINTRODUCTIONPAGE_ANONYMOUSDESCRIPTION = 'p.obIntroductionPage.anonymousDescription'
  public static readonly P_OBINTRODUCTIONPAGE_FIRSTNAME = 'p.obIntroductionPage.firstName'
  public static readonly P_OBINTRODUCTIONPAGE_PWD = 'p.obIntroductionPage.pwd'
  public static readonly P_OBINTRODUCTIONPAGE_EMAIL = 'p.obIntroductionPage.email'
  public static readonly P_OBINTRODUCTIONPAGE_NEXT = 'p.obIntroductionPage.next'
  public static readonly P_OBINTRODUCTIONPAGE_NEXTMANYMODULE = 'p.obIntroductionPage.nextManyModule'
  public static readonly P_OBINTRODUCTIONPAGE_CHECKBOXCONDITION = 'p.obIntroductionPage.checkboxCondition'
  public static readonly P_OBINTRODUCTIONPAGE_CHECKBOXCONDITIONAND = 'p.obIntroductionPage.checkboxConditionAnd'
  public static readonly P_OBINTRODUCTIONPAGE_CHECKBOXCONDITIONLINK = 'p.obIntroductionPage.checkboxConditionLink'
  public static readonly P_OBINTRODUCTIONPAGE_CLICKCONDITIONS = 'p.obIntroductionPage.clickConditions'
  public static readonly P_OBINTRODUCTIONPAGE_CONDITIONS = 'p.obIntroductionPage.conditions'
  public static readonly P_OBINTRODUCTIONPAGE_PRIVACY = 'p.obIntroductionPage.privacy'
  public static readonly P_OBINTRODUCTIONPAGE_CURRENTMODULE = 'p.obIntroductionPage.currentModule'
  public static readonly P_OBINTRODUCTIONPAGE_HEADER_TITLE = 'p.obIntroductionPage.header.title'
  public static readonly P_OBINTRODUCTIONPAGE_HEADER_DESCRIPTION = 'p.obIntroductionPage.header.description'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_1 = 'p.obIntroductionPage.errors.1'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_2 = 'p.obIntroductionPage.errors.2'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_3 = 'p.obIntroductionPage.errors.3'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_4 = 'p.obIntroductionPage.errors.4'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_5 = 'p.obIntroductionPage.errors.5'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_6 = 'p.obIntroductionPage.errors.6'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_7 = 'p.obIntroductionPage.errors.7'
  public static readonly P_OBINTRODUCTIONPAGE_ERRORS_8 = 'p.obIntroductionPage.errors.8'
  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_BEGIN = 'p.obIntroductionPage.slider.begin'
  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_WELCOME_TITLE = 'p.obIntroductionPage.slider.welcome.title'
  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_WELCOME_SUBTITLE = 'p.obIntroductionPage.slider.welcome.subtitle'
  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_CHOICEPREVIEW_TITLE =
    'p.obIntroductionPage.slider.choicePreview.title'

  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_CHOICEPREVIEW_SUBTITLE =
    'p.obIntroductionPage.slider.choicePreview.subtitle'

  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_PRACTICE_TITLE = 'p.obIntroductionPage.slider.practice.title'
  public static readonly P_OBINTRODUCTIONPAGE_SLIDER_PRACTICE_SUBTITLE = 'p.obIntroductionPage.slider.practice.subtitle'
  public static readonly P_OBINTRODUCTIONPAGE_CHOOSECAMPAIGNTITLE = 'p.obIntroductionPage.chooseCampaignTitle'
  public static readonly P_OBINTRODUCTIONPAGE_CHOOSECAMPAIGNPLACEHOLDER =
    'p.obIntroductionPage.chooseCampaignPlaceholder'

  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_TITLE = 'p.ObLaunchDescription.launchFound.title'
  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_TEXT_SENTENCE1 =
    'p.ObLaunchDescription.launchFound.text.sentence1'

  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_TEXT_SENTENCE2 =
    'p.ObLaunchDescription.launchFound.text.sentence2'

  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_TEXT_SENTENCE3 =
    'p.ObLaunchDescription.launchFound.text.sentence3'

  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_TEXT_SENTENCE2CLIENT =
    'p.ObLaunchDescription.launchFound.text.sentence2client'

  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHFOUND_BUTTONLABEL = 'p.ObLaunchDescription.launchFound.buttonLabel'
  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHNOTFOUND_TITLE = 'p.ObLaunchDescription.launchNotFound.title'
  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHNOTFOUND_TEXT = 'p.ObLaunchDescription.launchNotFound.text'
  public static readonly P_OBLAUNCHDESCRIPTION_LAUNCHNOTFOUND_BUTTONLABEL =
    'p.ObLaunchDescription.launchNotFound.buttonLabel'

  public static readonly P_OBMODULEDESCRIPTION_META_TITLE = 'p.ObModuleDescription.meta.title'
  public static readonly P_OBMODULEDESCRIPTION_TITLE = 'p.ObModuleDescription.title'
  public static readonly P_OBMODULEDESCRIPTION_TITLEMANYMODULE = 'p.ObModuleDescription.titleManyModule'
  public static readonly P_OBMODULEDESCRIPTION_PRACTICE = 'p.ObModuleDescription.practice'
  public static readonly P_OBMODULEDESCRIPTION_PROGRESS = 'p.ObModuleDescription.progress'
  public static readonly P_OBMODULEDESCRIPTION_NEXT = 'p.ObModuleDescription.next'
  public static readonly P_OBMODULEDESCRIPTION_TEXT = 'p.ObModuleDescription.text'
  public static readonly P_OBMODULEDESCRIPTION_LETSGO = 'p.ObModuleDescription.letsgo'
  public static readonly P_OBMODULEDESCRIPTION_ACTIONS_NEXT = 'p.ObModuleDescription.actions.next'
  public static readonly P_OBMODULEDESCRIPTION_MULTIPLEONBOARDING_DEFAULT =
    'p.ObModuleDescription.multipleOnboarding.default'

  public static readonly P_OBMODULEDESCRIPTION_MULTIPLEONBOARDING_SUBSEQUENT =
    'p.ObModuleDescription.multipleOnboarding.subsequent'

  public static readonly P_OBCOURSESUMMARY_META_TITLE = 'p.ObCourseSummary.meta.title'
  public static readonly P_OBCOURSESUMMARY_TITLE = 'p.ObCourseSummary.title'
  public static readonly P_OBCOURSESUMMARY_DURATION = 'p.ObCourseSummary.duration'
  public static readonly P_OBCOURSESUMMARY_RHYTHM = 'p.ObCourseSummary.rhythm'
  public static readonly P_OBCOURSESUMMARY_REMINDER = 'p.ObCourseSummary.reminder'
  public static readonly P_OBCOURSESUMMARY_NEXT = 'p.ObCourseSummary.next'
  public static readonly P_OBMANAGERPROFILESELECTION_META_TITLE = 'p.ObManagerProfileSelection.meta.title'
  public static readonly P_OBMANAGERPROFILESELECTION_TITLE = 'p.ObManagerProfileSelection.title'
  public static readonly P_OBMANAGERPROFILESELECTION_SUBTITLE = 'p.ObManagerProfileSelection.subtitle'
  public static readonly P_OBMODULESELECTION_META_TITLE = 'p.ObModuleSelection.meta.title'
  public static readonly P_OBMODULESELECTION_TITLE = 'p.ObModuleSelection.title'
  public static readonly P_SEGMENTSSELECTOR_META_TITLE = 'p.SegmentsSelector.meta.title'
  public static readonly P_SEGMENTSSELECTOR_TITLE = 'p.SegmentsSelector.title'
  public static readonly P_SEGMENTSSELECTOR_SUBTITLE = 'p.SegmentsSelector.subtitle'
  public static readonly P_SEGMENTSSELECTOR_NEXT = 'p.SegmentsSelector.next'
  public static readonly P_SEGMENTSSELECTOR_MANAGERPROFILE = 'p.SegmentsSelector.managerProfile'
  public static readonly P_SEGMENTSSELECTOR_ERRORS_NOPROFILE = 'p.SegmentsSelector.errors.noProfile'
  public static readonly P_SEGMENTSSELECTOR_MANAGERPROFILEV2 = 'p.SegmentsSelector.managerProfilev2'
  public static readonly P_OBSEGMENTS_META_TITLE = 'p.ObSegments.meta.title'
  public static readonly P_OBSEGMENTS_TITLE = 'p.ObSegments.title'
  public static readonly P_OBSEGMENTS_NEXT = 'p.ObSegments.next'
  public static readonly P_OBCATEGORIES_META_TITLE = 'p.ObCategories.meta.title'
  public static readonly P_OBCATEGORIES_TITLE = 'p.ObCategories.title'
  public static readonly P_OBCATEGORIES_SUBTITLE = 'p.ObCategories.subtitle'
  public static readonly P_OBCATEGORIES_NOCHOICEWANTED = 'p.ObCategories.noChoiceWanted'
  public static readonly P_OBCATEGORIES_NEXT = 'p.ObCategories.next'
  public static readonly P_OBCATEGORIES_EMPTY = 'p.ObCategories.empty'
  public static readonly P_OBLOADINGTRANSITION_COMPUTINGCHALLENGES = 'p.ObLoadingTransition.computingChallenges'
  public static readonly P_OBLOADINGTRANSITION_LOADING = 'p.ObLoadingTransition.loading'
  public static readonly P_OBPERSONAE_META_TITLE = 'p.obPersonae.meta.title'
  public static readonly P_OBPERSONAE_TITLE = 'p.obPersonae.title'
  public static readonly P_OBPERSONAE_SUBTITLE = 'p.obPersonae.subtitle'
  public static readonly P_OBPERSONAE_OPTION1_TITLE = 'p.obPersonae.option1.title'
  public static readonly P_OBPERSONAE_OPTION1_SUBTITLE = 'p.obPersonae.option1.subtitle'
  public static readonly P_OBPERSONAE_OPTION2_TITLE = 'p.obPersonae.option2.title'
  public static readonly P_OBPERSONAE_OPTION2_SUBTITLE = 'p.obPersonae.option2.subtitle'
  public static readonly P_OBPERSONAE_NEXT = 'p.obPersonae.next'
  public static readonly P_OBCHALLENGECHOICE_META_TITLE = 'p.obChallengeChoice.meta.title'
  public static readonly P_OBCHALLENGECHOICE_TITLE = 'p.obChallengeChoice.title'
  public static readonly P_OBCHALLENGECHOICE_SUBTITLE = 'p.obChallengeChoice.subtitle'
  public static readonly P_OBCHALLENGECHOICE_COURSE = 'p.obChallengeChoice.course'
  public static readonly P_OBACCEPTCHALLENGE_META_TITLE = 'p.obAcceptChallenge.meta.title'
  public static readonly P_OBACCEPTCHALLENGE_TITLE = 'p.obAcceptChallenge.title'
  public static readonly P_OBACCEPTCHALLENGE_SUBTITLE = 'p.obAcceptChallenge.subtitle'
  public static readonly P_OBACCEPTCHALLENGE_NEXT = 'p.obAcceptChallenge.next'
  public static readonly P_OBACCEPTCHALLENGE_PASS = 'p.obAcceptChallenge.pass'
  public static readonly P_OBCONGRATULATIONS_TITLE = 'p.obCongratulations.title'
  public static readonly P_OBCONGRATULATIONS_DESCRIPTION = 'p.obCongratulations.description'
  public static readonly P_OBCONGRATULATIONS_DESCRIPTION2 = 'p.obCongratulations.description2'
  public static readonly P_OBCONGRATULATIONS_NEXT = 'p.obCongratulations.next'
  public static readonly P_OBCONGRATULATIONS_DESCRIPTION3 = 'p.obCongratulations.description3'
  public static readonly P_PROGRESSIONPAGE_META_TITLE = 'p.progressionPage.meta.title'
  public static readonly P_PROGRESSIONPAGE_META_DESCRIPTION = 'p.progressionPage.meta.description'
  public static readonly P_PROGRESSIONPAGE_TOOLTIPS_GLOBALACCEPTEDCOUNT =
    'p.progressionPage.tooltips.globalAcceptedCount'

  public static readonly P_PROGRESSIONPAGE_TOOLTIPS_GLOBALVALIDATEDCOUNT =
    'p.progressionPage.tooltips.globalValidatedCount'

  public static readonly P_PROGRESSIONPAGE_TOOLTIPS_GLOBALHABITSCOUNT = 'p.progressionPage.tooltips.globalHabitsCount'
  public static readonly P_PROGRESSIONPAGE_ACCEPTEDCOUNTPROGRESS = 'p.progressionPage.acceptedCountProgress'
  public static readonly P_PROGRESSIONPAGE_VALIDATEDCOUNTPROGRESS = 'p.progressionPage.validatedCountProgress'
  public static readonly P_PROGRESSIONPAGE_HABITSCOUNTPROGRESS = 'p.progressionPage.habitsCountProgress'
  public static readonly P_PROGRESSIONPAGE_HABITSCOUNTPROGRESSMOBILE = 'p.progressionPage.habitsCountProgressMobile'
  public static readonly P_PROGRESSIONPAGE_HABITSPROGRESSHELP = 'p.progressionPage.habitsProgressHelp'
  public static readonly P_PROGRESSIONPAGE_TIMELEFTPROGRESS = 'p.progressionPage.timeLeftProgress'
  public static readonly P_PROGRESSIONPAGE_TIMELEFT_MONTH = 'p.progressionPage.timeLeft.month'
  public static readonly P_PROGRESSIONPAGE_TIMELEFT_WEEK = 'p.progressionPage.timeLeft.week'
  public static readonly P_PROGRESSIONPAGE_TIMELEFT_DAY = 'p.progressionPage.timeLeft.day'
  public static readonly P_PROGRESSIONPAGE_GLOBAL = 'p.progressionPage.global'
  public static readonly P_PROGRESSIONPAGE_H1 = 'p.progressionPage.h1'
  public static readonly P_PROGRESSIONPAGE_BEGINGLOBALCARD = 'p.progressionPage.beginGlobalCard'
  public static readonly P_PROGRESSIONPAGE_BEGINSECONDMODULEGLOBALCARD = 'p.progressionPage.beginSecondModuleGlobalCard'
  public static readonly P_PROGRESSIONPAGE_BEGINTHIRDMODULEGLOBALCARD = 'p.progressionPage.beginThirdModuleGlobalCard'
  public static readonly P_PROGRESSIONPAGE_DESCRIPTIONGLOBALCARD = 'p.progressionPage.descriptionGlobalCard'
  public static readonly P_PROGRESSIONPAGE_DESCRIPTIONMANYMODULE = 'p.progressionPage.descriptionManyModule'
  public static readonly P_PROGRESSIONPAGE_BADGES_TITLE = 'p.progressionPage.badges.title'
  public static readonly P_PROGRESSIONPAGE_BADGES_CHALLENGE = 'p.progressionPage.badges.challenge'
  public static readonly P_PROGRESSIONPAGE_BADGES_COUNT = 'p.progressionPage.badges.count'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_BRONZE_NAME = 'p.progressionPage.badges.level.bronze.name'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_BRONZE_DESCRIPTION =
    'p.progressionPage.badges.level.bronze.description'

  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_BRONZE_TEXT = 'p.progressionPage.badges.level.bronze.text'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_SILVER_NAME = 'p.progressionPage.badges.level.silver.name'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_SILVER_DESCRIPTION =
    'p.progressionPage.badges.level.silver.description'

  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_SILVER_TEXT = 'p.progressionPage.badges.level.silver.text'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_GOLD_NAME = 'p.progressionPage.badges.level.gold.name'
  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_GOLD_DESCRIPTION =
    'p.progressionPage.badges.level.gold.description'

  public static readonly P_PROGRESSIONPAGE_BADGES_LEVEL_GOLD_TEXT = 'p.progressionPage.badges.level.gold.text'
  public static readonly P_PROGRESSIONPAGE_ADVICES_TITLE = 'p.progressionPage.advices.title'
  public static readonly P_PROGRESSIONPAGE_ADVICES_INCREASE_DESCRIPTION =
    'p.progressionPage.advices.increase.description'

  public static readonly P_PROGRESSIONPAGE_ADVICES_INCREASE_LINK = 'p.progressionPage.advices.increase.link'
  public static readonly P_PROGRESSIONPAGE_ADVICES_REPEAT_DESCRIPTION = 'p.progressionPage.advices.repeat.description'
  public static readonly P_PROGRESSIONPAGE_ADVICES_REPEAT_LINK = 'p.progressionPage.advices.repeat.link'
  public static readonly P_PROGRESSIONPAGE_ADVICES_ADDMORETOWEEK_LINK = 'p.progressionPage.advices.addMoreToWeek.link'
  public static readonly P_PROGRESSIONPAGE_ADVICES_ADDMORETOWEEK_DESCRIPTION =
    'p.progressionPage.advices.addMoreToWeek.description'

  public static readonly P_PROGRESSIONPAGE_CERTIFICATION_TITLE = 'p.progressionPage.certification.title'
  public static readonly P_PROGRESSIONPAGE_CERTIFICATION_DESCRIPTION = 'p.progressionPage.certification.description'
  public static readonly P_PROGRESSIONPAGE_CERTIFICATION_BTN = 'p.progressionPage.certification.btn'
  public static readonly P_PROGRESSIONPAGE_CERTIFICATION_ERROR = 'p.progressionPage.certification.error'
  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_TITLE_BRONZE =
    'p.progressionPage.newBadgeObtention.title.bronze'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_TITLE_SILVER =
    'p.progressionPage.newBadgeObtention.title.silver'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_TITLE_GOLD =
    'p.progressionPage.newBadgeObtention.title.gold'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_DESCRIPTION_BRONZE =
    'p.progressionPage.newBadgeObtention.description.bronze'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_DESCRIPTION_SILVER =
    'p.progressionPage.newBadgeObtention.description.silver'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_DESCRIPTION_GOLD =
    'p.progressionPage.newBadgeObtention.description.gold'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_NEXT = 'p.progressionPage.newBadgeObtention.next'
  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_NEXTMOBILE =
    'p.progressionPage.newBadgeObtention.nextMobile'

  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_SKIP = 'p.progressionPage.newBadgeObtention.skip'
  public static readonly P_PROGRESSIONPAGE_NEWBADGEOBTENTION_GETCERTIFICAT =
    'p.progressionPage.newBadgeObtention.getCertificat'

  public static readonly P_PROGRESSIONPAGE_CONGRATULATIONHABIT_SKIP = 'p.progressionPage.congratulationHabit.skip'
  public static readonly P_EXPIREDLICENSE_TITLE = 'p.expiredLicense.title'
  public static readonly P_EXPIREDLICENSE_BODY1 = 'p.expiredLicense.body1'
  public static readonly P_EXPIREDLICENSE_BODY2 = 'p.expiredLicense.body2'
  public static readonly P_EXPIREDLICENSE_CONTACT = 'p.expiredLicense.contact'
  public static readonly P_NOCOURSEGIVEN_TITLE = 'p.noCourseGiven.title'
  public static readonly P_NOCOURSEGIVEN_BODY = 'p.noCourseGiven.body'
  public static readonly P_NOCOURSEGIVEN_SUBTITLE = 'p.noCourseGiven.subtitle'
  public static readonly P_NOCOURSEGIVEN_SUBBODY = 'p.noCourseGiven.subbody'
  public static readonly P_USERNOPROGRAMPAGE_TITLE = 'p.UserNoProgramPage.title'
  public static readonly P_USERNOPROGRAMPAGE_DESCRIPTION = 'p.UserNoProgramPage.description'
  public static readonly P_USERNOPROGRAMPAGE_HELP = 'p.UserNoProgramPage.help'
  public static readonly P_SSOSAFARISUCCESSPAGE_DESCRIPTION = 'p.ssoSafariSuccessPage.description'
  public static readonly P_SSOANDROIDSUCCESSPAGE_DESCRIPTION = 'p.ssoAndroidSuccessPage.description'
  public static readonly P_SSOANDROIDSUCCESSPAGE_ACTION = 'p.ssoAndroidSuccessPage.action'
  public static readonly P_USERWITHPASTCAMPAIGN_DESCRIPTION = 'p.userWithPastCampaign.description'
  public static readonly P_USERWITHPASTCAMPAIGN_TITLE = 'p.userWithPastCampaign.title'
  public static readonly P_USERWITHPASTCAMPAIGN_HELP = 'p.userWithPastCampaign.help'
  public static readonly D_NAVMENUDASHBOARDBTN = 'd.navMenuDashboardBtn'
  public static readonly D_SENDMAIL = 'd.sendMail'
  public static readonly D_CONTACTUS = 'd.contactUs'
  public static readonly D_CONTACTUSMAIL = 'd.contactUsMail'
  public static readonly D_NEEDHELP = 'd.needHelp'
  public static readonly D_BACKTOFIFTY = 'd.backToFifty'
  public static readonly D_ISPROSPECTFAKEDATA = 'd.isProspectFakeData'
  public static readonly D_EXPIRATIONDATE_TITLE = 'd.expirationDate.title'
  public static readonly D_EXPIRATIONDATE_BODY = 'd.expirationDate.body'
  public static readonly D_DASHBOARDPAGINATEFOOTER_COUNT = 'd.DashboardPaginateFooter.count'
  public static readonly D_LAUNCHESPAGE_META_TITLE = 'd.launchesPage.meta.title'
  public static readonly D_LAUNCHESPAGE_META_DESCRPTION = 'd.launchesPage.meta.descrption'
  public static readonly D_LAUNCHESPAGE_MENUNAME = 'd.launchesPage.menuName'
  public static readonly D_LAUNCHESPAGE_TITLE = 'd.launchesPage.title'
  public static readonly D_LAUNCHESPAGE_NEWLAUNCHBTN = 'd.launchesPage.newLaunchBtn'
  public static readonly D_LAUNCHESPAGE_NOLAUNCH_TITLE = 'd.launchesPage.noLaunch.title'
  public static readonly D_LAUNCHESPAGE_TABLE_DETAILS = 'd.launchesPage.table.details'
  public static readonly D_LAUNCHESPAGE_TABLE_COLUMN_NAME = 'd.launchesPage.table.column.name'
  public static readonly D_LAUNCHESPAGE_TABLE_COLUMN_COURSE = 'd.launchesPage.table.column.course'
  public static readonly D_LAUNCHESPAGE_TABLE_COLUMN_DATE = 'd.launchesPage.table.column.date'
  public static readonly D_LAUNCHESPAGE_TABLE_COLUMN_USERCOUNT = 'd.launchesPage.table.column.userCount'
  public static readonly D_SINGLELAUNCHPAGE_META_TITLE = 'd.singleLaunchPage.meta.title'
  public static readonly D_SINGLELAUNCHPAGE_META_DESCRPTION = 'd.singleLaunchPage.meta.descrption'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_COURSE = 'd.singleLaunchPage.launchData.label.course'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_DATE = 'd.singleLaunchPage.launchData.label.date'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_USERCOUNT = 'd.singleLaunchPage.launchData.label.userCount'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_MULTICOURSE =
    'd.singleLaunchPage.launchData.label.multiCourse'

  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_DURATION = 'd.singleLaunchPage.launchData.label.duration'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_EXPORTPARTICIPANTS =
    'd.singleLaunchPage.launchData.label.exportParticipants'

  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_TIME = 'd.singleLaunchPage.launchData.label.time'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_OBLINK = 'd.singleLaunchPage.launchData.label.obLink'
  public static readonly D_SINGLELAUNCHPAGE_LAUNCHDATA_LABEL_OBLINKTOOLTIP =
    'd.singleLaunchPage.launchData.label.obLinkTooltip'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TITLE = 'd.singleLaunchPage.users.title'
  public static readonly D_SINGLELAUNCHPAGE_USERS_BATCHLABEL = 'd.singleLaunchPage.users.batchLabel'
  public static readonly D_SINGLELAUNCHPAGE_USERS_SINGLELABEL = 'd.singleLaunchPage.users.singleLabel'
  public static readonly D_SINGLELAUNCHPAGE_USERS_EMPTYUSERS = 'd.singleLaunchPage.users.emptyUsers'
  public static readonly D_SINGLELAUNCHPAGE_USERS_NOITEM_TITLE = 'd.singleLaunchPage.users.noItem.title'
  public static readonly D_SINGLELAUNCHPAGE_USERS_NOITEM_SUBTITLE = 'd.singleLaunchPage.users.noItem.subtitle'
  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_LASTNAME =
    'd.singleLaunchPage.users.table.column.lastName'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_FIRSTNAME =
    'd.singleLaunchPage.users.table.column.firstName'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_EMAIL = 'd.singleLaunchPage.users.table.column.email'
  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_START = 'd.singleLaunchPage.users.table.column.start'
  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_SEGMENT = 'd.singleLaunchPage.users.table.column.segment'
  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_LANGUAGE =
    'd.singleLaunchPage.users.table.column.language'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_TIMEZONE =
    'd.singleLaunchPage.users.table.column.timeZone'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_PROFILE = 'd.singleLaunchPage.users.table.column.profile'
  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_ONBOARDED =
    'd.singleLaunchPage.users.table.column.onboarded'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_SEGMENTS =
    'd.singleLaunchPage.users.table.column.segments'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_ISSUBSCRIBED =
    'd.singleLaunchPage.users.table.column.isSubscribed'

  public static readonly D_SINGLELAUNCHPAGE_USERS_TABLE_COLUMN_PROFILEV2 =
    'd.singleLaunchPage.users.table.column.profilev2'

  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_AUTO = 'd.singleLaunchPage.users.actions.auto'
  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_USERS = 'd.singleLaunchPage.users.actions.users'
  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_BATCH = 'd.singleLaunchPage.users.actions.batch'
  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_PARTICIPANT = 'd.singleLaunchPage.users.actions.participant'
  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_LABEL = 'd.singleLaunchPage.users.actions.label'
  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_DELETEALL_BTN =
    'd.singleLaunchPage.users.actions.deleteAll.btn'

  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_DELETEALL_TOOLTIPDISABLED =
    'd.singleLaunchPage.users.actions.deleteAll.tooltipDisabled'

  public static readonly D_SINGLELAUNCHPAGE_USERS_ACTIONS_DELETEALL_CONFIRM =
    'd.singleLaunchPage.users.actions.deleteAll.confirm'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_TITLE =
    'd.singleLaunchPage.externalEvents.configurationModal.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTS_0 =
    'd.singleLaunchPage.externalEvents.configurationModal.events.0'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTS_1 =
    'd.singleLaunchPage.externalEvents.configurationModal.events.1'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTS_2 =
    'd.singleLaunchPage.externalEvents.configurationModal.events.2'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_SELECTEVENT_INTRODUCTION =
    'd.singleLaunchPage.externalEvents.configurationModal.selectEvent.introduction'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_SELECTEVENT_LABEL =
    'd.singleLaunchPage.externalEvents.configurationModal.selectEvent.label'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_SELECTEVENT_ALREADYUSED =
    'd.singleLaunchPage.externalEvents.configurationModal.selectEvent.alreadyUsed'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_TITLE =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_LABEL =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.label'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_TOOLTIP =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.tooltip'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_PLACEHOLDER =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.placeholder'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_EMPTYLIST =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.emptyList'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_NORESULTS =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.noResults'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ADD =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.add'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_ALREADYUSED =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.alreadyUsed'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_INPUTREQUIRED =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.inputRequired'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_ADDFAILED =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.addFailed'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_REMOVEFAILED =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.removeFailed'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_SEARCHFAILED =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.searchFailed'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_CRITERIAS_ERRORS_CONFLICT =
    'd.singleLaunchPage.externalEvents.configurationModal.criterias.errors.conflict'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTADDED_TITLE =
    'd.singleLaunchPage.externalEvents.configurationModal.eventAdded.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTADDED_BODY_FIRSTTIMEMANAGER =
    'd.singleLaunchPage.externalEvents.configurationModal.eventAdded.body.FirstTimeManager'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTADDED_BODY_LEARNINGCOMPLETION =
    'd.singleLaunchPage.externalEvents.configurationModal.eventAdded.body.LearningCompletion'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_EVENTADDED_OK =
    'd.singleLaunchPage.externalEvents.configurationModal.eventAdded.ok'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_ACTIONS_ACTIVATE =
    'd.singleLaunchPage.externalEvents.configurationModal.actions.activate'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_ACTIONS_DEACTIVATE =
    'd.singleLaunchPage.externalEvents.configurationModal.actions.deactivate'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_ACTIONS_CLOSE =
    'd.singleLaunchPage.externalEvents.configurationModal.actions.close'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_ERRORS_ACTIVATION =
    'd.singleLaunchPage.externalEvents.configurationModal.errors.activation'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_CONFIGURATIONMODAL_ERRORS_DEACTIVATION =
    'd.singleLaunchPage.externalEvents.configurationModal.errors.deactivation'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_TITLE =
    'd.singleLaunchPage.externalEvents.unavailableModal.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_DESCRIPTION =
    'd.singleLaunchPage.externalEvents.unavailableModal.description'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_FIRSTTIMEMANAGER_TITLE =
    'd.singleLaunchPage.externalEvents.unavailableModal.firstTimeManager.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_FIRSTTIMEMANAGER_TEXT =
    'd.singleLaunchPage.externalEvents.unavailableModal.firstTimeManager.text'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_LEARNINGCOMPLETION_TITLE =
    'd.singleLaunchPage.externalEvents.unavailableModal.learningCompletion.title'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_LEARNINGCOMPLETION_TEXT =
    'd.singleLaunchPage.externalEvents.unavailableModal.learningCompletion.text'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_CONTACTUS =
    'd.singleLaunchPage.externalEvents.unavailableModal.contactUs'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_CONNECTORS_DESCRIPTION =
    'd.singleLaunchPage.externalEvents.unavailableModal.connectors.description'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_UNAVAILABLEMODAL_CONNECTORS_MORE =
    'd.singleLaunchPage.externalEvents.unavailableModal.connectors.more'

  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_ADDBUTTON = 'd.singleLaunchPage.externalEvents.addButton'
  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_STATES_NEW = 'd.singleLaunchPage.externalEvents.states.new'
  public static readonly D_SINGLELAUNCHPAGE_EXTERNALEVENTS_STATES_ACTIVE =
    'd.singleLaunchPage.externalEvents.states.active'

  public static readonly D_SINGLELAUNCHPAGE_BACKBUTTON = 'd.singleLaunchPage.back-button'
  public static readonly D_SINGLELAUNCHPAGE_EDITCAMPAIGN = 'd.singleLaunchPage.edit-campaign'
  public static readonly D_SINGLELAUNCHPAGE_SAVE = 'd.singleLaunchPage.save'
  public static readonly D_SINGLELAUNCHPAGE_CAMPAIGNNAME = 'd.singleLaunchPage.campaign-name'
  public static readonly D_SINGLELAUNCHPAGE_TOAST_PARTICIPANTBULK_SUCCESS =
    'd.singleLaunchPage.toast.participantBulk.success'

  public static readonly D_SINGLELAUNCHPAGE_TOAST_REMOVEPARTICIPANT_CONTENT =
    'd.singleLaunchPage.toast.removeParticipant.content'

  public static readonly D_SINGLELAUNCHPAGE_TOAST_REMOVEPARTICIPANT_UNDO_SUCCESS =
    'd.singleLaunchPage.toast.removeParticipant.undo.success'

  public static readonly D_SINGLELAUNCHPAGE_TOAST_REMOVEPARTICIPANT_UNDO_ERROR =
    'd.singleLaunchPage.toast.removeParticipant.undo.error'

  public static readonly D_SINGLELAUNCHPAGE_TOAST_DELETEALLPARTICIPANTS_SUCCESS =
    'd.singleLaunchPage.toast.deleteAllParticipants.success'

  public static readonly D_SINGLELAUNCHPAGE_TOAST_DELETEALLPARTICIPANTS_ERROR =
    'd.singleLaunchPage.toast.deleteAllParticipants.error'

  public static readonly D_SINGLELAUNCHPAGE_CAMPAIGNPARTICIPANTS_TOASTER_SERVERERROR =
    'd.singleLaunchPage.campaignParticipants.toaster.serverError'

  public static readonly D_USERSPAGE_META_TITLE = 'd.usersPage.meta.title'
  public static readonly D_USERSPAGE_META_DESCRPTION = 'd.usersPage.meta.descrption'
  public static readonly D_USERSPAGE_GROUPMENUNAME = 'd.usersPage.groupMenuName'
  public static readonly D_USERSPAGE_MENUNAME = 'd.usersPage.menuName'
  public static readonly D_USERSPAGE_USERS = 'd.usersPage.users'
  public static readonly D_USERSPAGE_NEWUSERBTN = 'd.usersPage.newUserBtn'
  public static readonly D_USERSPAGE_NOUSER_TITLE = 'd.usersPage.noUser.title'
  public static readonly D_USERSPAGE_NOUSER_SUBTITLE = 'd.usersPage.noUser.subtitle'
  public static readonly D_USERSPAGE_EMPTYUSERS = 'd.usersPage.emptyUsers'
  public static readonly D_USERSPAGE_NOUSERADDINDEMOTITLE = 'd.usersPage.noUserAddInDemoTitle'
  public static readonly D_USERSPAGE_TABLE_COLUMN_LASTNAME = 'd.usersPage.table.column.lastName'
  public static readonly D_USERSPAGE_TABLE_COLUMN_FIRSTNAME = 'd.usersPage.table.column.firstName'
  public static readonly D_USERSPAGE_TABLE_COLUMN_EMAIL = 'd.usersPage.table.column.email'
  public static readonly D_USERSPAGE_TABLE_COLUMN_ROLE = 'd.usersPage.table.column.role'
  public static readonly D_USERSPAGE_TABLE_DETAILS = 'd.usersPage.table.details'
  public static readonly D_USERSPAGE_TABLE_SEARCHBYTEXT = 'd.usersPage.table.searchByText'
  public static readonly D_USERSPAGE_TABLE_SAVE = 'd.usersPage.table.save'
  public static readonly D_CREATEUSERFORM_ADDADMIN_TITLE = 'd.createUserForm.addAdmin.title'
  public static readonly D_CREATEUSERFORM_ADDTOLAUNCH_TITLE = 'd.createUserForm.addToLaunch.title'
  public static readonly D_CREATEUSERFORM_ADDTOLAUNCH_SUBTITLE = 'd.createUserForm.addToLaunch.subtitle'
  public static readonly D_CREATEUSERFORM_SUBMIT = 'd.createUserForm.submit'
  public static readonly D_CREATEUSERFORM_VALIDATIONERROR = 'd.createUserForm.validationError'
  public static readonly D_CREATEUSERFORM_DUPLICATEEMAIL = 'd.createUserForm.duplicateEmail'
  public static readonly D_CREATEUSERFORM_SERVERERROR = 'd.createUserForm.serverError'
  public static readonly D_CREATEUSERFORM_USERCREATED = 'd.createUserForm.userCreated'
  public static readonly D_CREATEUSERFORM_ALREADYEXISTINGUSERERROR = 'd.createUserForm.alreadyExistingUserError'
  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_LASTNAME_LABEL =
    'd.createUserForm.personalData.fields.lastName.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_LASTNAME_ERROR =
    'd.createUserForm.personalData.fields.lastName.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_FIRSTNAME_LABEL =
    'd.createUserForm.personalData.fields.firstName.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_FIRSTNAME_ERROR =
    'd.createUserForm.personalData.fields.firstName.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_EMAIL_LABEL =
    'd.createUserForm.personalData.fields.email.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_EMAIL_ERROR =
    'd.createUserForm.personalData.fields.email.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_TIMEZONE_LABEL =
    'd.createUserForm.personalData.fields.timeZone.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_TIMEZONE_EMPTYLABEL =
    'd.createUserForm.personalData.fields.timeZone.emptyLabel'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_TIMEZONE_ERROR =
    'd.createUserForm.personalData.fields.timeZone.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_LANGUAGE_LABEL =
    'd.createUserForm.personalData.fields.language.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_LANGUAGE_EMPTYLABEL =
    'd.createUserForm.personalData.fields.language.emptyLabel'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_LANGUAGE_ERROR =
    'd.createUserForm.personalData.fields.language.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_ROLE_LABEL =
    'd.createUserForm.personalData.fields.role.label'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_ROLE_ERROR =
    'd.createUserForm.personalData.fields.role.error'

  public static readonly D_CREATEUSERFORM_PERSONALDATA_FIELDS_PROGRAMS_LABEL =
    'd.createUserForm.personalData.fields.programs.label'

  public static readonly D_CREATEUSERFORM_MODULE_TITLE = 'd.createUserForm.module.title'
  public static readonly D_CREATEUSERFORM_MODULE_FIELDS_NAME_LABEL = 'd.createUserForm.module.fields.name.label'
  public static readonly D_CREATEUSERFORM_MODULE_FIELDS_NAME_EMPTYLABEL =
    'd.createUserForm.module.fields.name.emptyLabel'

  public static readonly D_CREATEUSERFORM_MODULE_FIELDS_NAME_ERROR = 'd.createUserForm.module.fields.name.error'
  public static readonly D_CREATEUSERFORM_MODULE_FIELDS_TEAM_LABEL = 'd.createUserForm.module.fields.team.label'
  public static readonly D_CREATEUSERFORM_MODULE_FIELDS_TEAM_HELP = 'd.createUserForm.module.fields.team.help'
  public static readonly D_CREATEUSERFORM_EDITADMIN_SUBTITLE = 'd.createUserForm.editAdmin.subtitle'
  public static readonly D_CREATEUSERFORM_SAVE = 'd.createUserForm.save'
  public static readonly D_CREATEUSERFORM_ELIGIBILITYWARNING_OVERLAP = 'd.createUserForm.eligibilityWarning.overlap'
  public static readonly D_CREATEUSERFORM_ELIGIBILITYWARNING_THRESHOLD = 'd.createUserForm.eligibilityWarning.threshold'
  public static readonly D_CREATEUSERFORM_ELIGIBILITYWARNING_ADDUSERANYWAY =
    'd.createUserForm.eligibilityWarning.addUserAnyway'

  public static readonly D_CREATEUSERFORM_ALLPROGRAMSLABEL = 'd.createUserForm.allProgramsLabel'
  public static readonly D_SINGLEUSERPAGE_META_TITLE = 'd.singleUserPage.meta.title'
  public static readonly D_SINGLEUSERPAGE_META_DESCRPTION = 'd.singleUserPage.meta.descrption'
  public static readonly D_SINGLEUSERPAGE_EDIT = 'd.singleUserPage.edit'
  public static readonly D_SINGLEUSERPAGE_PERSONALDATA_LABEL_EMAIL = 'd.singleUserPage.personalData.label.email'
  public static readonly D_SINGLEUSERPAGE_PERSONALDATA_LABEL_TIMEZONE = 'd.singleUserPage.personalData.label.timezone'
  public static readonly D_SINGLEUSERPAGE_PERSONALDATA_LABEL_LANGUAGE = 'd.singleUserPage.personalData.label.language'
  public static readonly D_SINGLEUSERPAGE_PERSONALDATA_LABEL_ADMINROLE = 'd.singleUserPage.personalData.label.adminRole'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_TITLE = 'd.singleUserPage.launches.title'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_ADD = 'd.singleUserPage.launches.add'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_EDIT = 'd.singleUserPage.launches.edit'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_ONEFUTURELAUNCHONLYEXPLANATION =
    'd.singleUserPage.launches.oneFutureLaunchOnlyExplanation'

  public static readonly D_SINGLEUSERPAGE_LAUNCHES_LABEL_NAME = 'd.singleUserPage.launches.label.name'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_LABEL_COURSE = 'd.singleUserPage.launches.label.course'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_LABEL_DATES = 'd.singleUserPage.launches.label.dates'
  public static readonly D_SINGLEUSERPAGE_LAUNCHES_LABEL_SEGMENT = 'd.singleUserPage.launches.label.segment'
  public static readonly D_EDITUSERMODULEPAGE_TITLE_NEW = 'd.editUserModulePage.title.new'
  public static readonly D_EDITUSERMODULEPAGE_TITLE_EDIT = 'd.editUserModulePage.title.edit'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_NAME_LABEL = 'd.editUserModulePage.fields.name.label'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_NAME_EMPTYLABEL = 'd.editUserModulePage.fields.name.emptyLabel'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_NAME_ERROR = 'd.editUserModulePage.fields.name.error'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_STARTDATE_LABEL = 'd.editUserModulePage.fields.startDate.label'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_STARTDATE_ERROR = 'd.editUserModulePage.fields.startDate.error'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_TEAM_LABEL = 'd.editUserModulePage.fields.team.label'
  public static readonly D_EDITUSERMODULEPAGE_FIELDS_TEAM_HELP = 'd.editUserModulePage.fields.team.help'
  public static readonly D_EDITUSERMODULEPAGE_VALIDATIONERROR = 'd.editUserModulePage.validationError'
  public static readonly D_EDITUSERMODULEPAGE_SERVERERROR = 'd.editUserModulePage.serverError'
  public static readonly D_EDITUSERMODULEPAGE_CREATE = 'd.editUserModulePage.create'
  public static readonly D_EDITUSERMODULEPAGE_SAVE = 'd.editUserModulePage.save'
  public static readonly D_EDITUSERMODULEPAGE_CANCEL = 'd.editUserModulePage.cancel'
  public static readonly D_EDITUSERMODULEPAGE_ASSIGNNEWUSERMODULESUCCESSFUL =
    'd.editUserModulePage.assignNewUserModuleSuccessful'

  public static readonly D_EDITUSERMODULEPAGE_UPDATEUSERMODULESUCCESSFUL =
    'd.editUserModulePage.updateUserModuleSuccessful'

  public static readonly D_EDITUSERPERSONALDATAPAGE_TITLE = 'd.editUserPersonalDataPage.title'
  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_LASTNAME_LABEL =
    'd.editUserPersonalDataPage.fields.lastName.label'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_LASTNAME_ERROR =
    'd.editUserPersonalDataPage.fields.lastName.error'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_FIRSTNAME_LABEL =
    'd.editUserPersonalDataPage.fields.firstName.label'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_FIRSTNAME_ERROR =
    'd.editUserPersonalDataPage.fields.firstName.error'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_EMAIL_LABEL = 'd.editUserPersonalDataPage.fields.email.label'
  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_EMAIL_ERROR = 'd.editUserPersonalDataPage.fields.email.error'
  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_TIMEZONE_LABEL =
    'd.editUserPersonalDataPage.fields.timeZone.label'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_TIMEZONE_EMPTYLABEL =
    'd.editUserPersonalDataPage.fields.timeZone.emptyLabel'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_TIMEZONE_ERROR =
    'd.editUserPersonalDataPage.fields.timeZone.error'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_LANGUAGE_LABEL =
    'd.editUserPersonalDataPage.fields.language.label'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_LANGUAGE_EMPTYLABEL =
    'd.editUserPersonalDataPage.fields.language.emptyLabel'

  public static readonly D_EDITUSERPERSONALDATAPAGE_FIELDS_LANGUAGE_ERROR =
    'd.editUserPersonalDataPage.fields.language.error'

  public static readonly D_EDITUSERPERSONALDATAPAGE_VALIDATIONERROR = 'd.editUserPersonalDataPage.validationError'
  public static readonly D_EDITUSERPERSONALDATAPAGE_SERVERERROR = 'd.editUserPersonalDataPage.serverError'
  public static readonly D_EDITUSERPERSONALDATAPAGE_SAVE = 'd.editUserPersonalDataPage.save'
  public static readonly D_EDITUSERPERSONALDATAPAGE_CANCEL = 'd.editUserPersonalDataPage.cancel'
  public static readonly D_EDITUSERPERSONALDATAPAGE_UPDATEUSERPERSONALDATASUCCESSFUL =
    'd.editUserPersonalDataPage.updateUserPersonalDataSuccessful'

  public static readonly D_RESULTSPAGE_RESULTS = 'd.resultsPage.results'
  public static readonly D_RESULTSPAGE_BENCHMARK = 'd.resultsPage.benchmark'
  public static readonly D_RESULTSPAGE_GENERALSTATS_COURSECHOICE = 'd.resultsPage.generalStats.courseChoice'
  public static readonly D_RESULTSPAGE_GENERALSTATS_COURSECHOICETOOLTIP =
    'd.resultsPage.generalStats.courseChoiceTooltip'

  public static readonly D_RESULTSPAGE_GENERALSTATS_DEVICE = 'd.resultsPage.generalStats.device'
  public static readonly D_RESULTSPAGE_GENERALSTATS_DEVICETOOLTIP = 'd.resultsPage.generalStats.deviceTooltip'
  public static readonly D_RESULTSPAGE_GENERALSTATS_FREQUENCY = 'd.resultsPage.generalStats.frequency'
  public static readonly D_RESULTSPAGE_GENERALSTATS_FREQUENCYTOOLTIP = 'd.resultsPage.generalStats.frequencyTooltip'
  public static readonly D_RESULTSPAGE_GENERALSTATS_ACCEPTEDDAYS = 'd.resultsPage.generalStats.acceptedDays'
  public static readonly D_RESULTSPAGE_GENERALSTATS_ACCEPTEDDAYSTOOLTIP =
    'd.resultsPage.generalStats.acceptedDaysTooltip'

  public static readonly D_RESULTSPAGE_GENERALSTATS_VALIDATEDDAYS = 'd.resultsPage.generalStats.validatedDays'
  public static readonly D_RESULTSPAGE_GENERALSTATS_VALIDATEDDAYSTOOLTIP =
    'd.resultsPage.generalStats.validatedDaysTooltip'

  public static readonly D_RESULTSPAGE_FILTER_TITLE = 'd.resultsPage.filter.title'
  public static readonly D_RESULTSPAGE_FILTER_EMPTYLABEL = 'd.resultsPage.filter.emptyLabel'
  public static readonly D_RESULTSPAGE_FILTER_RESETFILTERSMOBILE = 'd.resultsPage.filter.resetFiltersMobile'
  public static readonly D_RESULTSPAGE_FILTER_RESETFILTERS = 'd.resultsPage.filter.resetFilters'
  public static readonly D_RESULTSPAGE_FILTER_COMPANYGROUP = 'd.resultsPage.filter.companyGroup'
  public static readonly D_RESULTSPAGE_FILTER_LAUNCHGROUP = 'd.resultsPage.filter.launchGroup'
  public static readonly D_RESULTSPAGE_FILTER_MODULEGROUP = 'd.resultsPage.filter.moduleGroup'
  public static readonly D_RESULTSPAGE_FILTER_NOCONFIGUREDTEAM = 'd.resultsPage.filter.noConfiguredTeam'
  public static readonly D_RESULTSPAGE_FILTER_NOCONFIGUREDMODULE = 'd.resultsPage.filter.noConfiguredModule'
  public static readonly D_RESULTSPAGE_FILTER_NOCONFIGUREDOTHER = 'd.resultsPage.filter.noConfiguredOther'
  public static readonly D_RESULTSPAGE_FILTER_ALLUSERS = 'd.resultsPage.filter.allUsers'
  public static readonly D_RESULTSPAGE_FILTER_MANAGERPROFILE = 'd.resultsPage.filter.managerProfile'
  public static readonly D_RESULTSPAGE_FILTER_PERIOD = 'd.resultsPage.filter.period'
  public static readonly D_RESULTSPAGE_FILTER_PROGRAM = 'd.resultsPage.filter.program'
  public static readonly D_RESULTSPAGE_FILTER_CLIENTGROUP = 'd.resultsPage.filter.clientGroup'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_EMPTYFILTER = 'd.resultsPage.selectPlaceholders.emptyFilter'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_ALL = 'd.resultsPage.selectPlaceholders.all'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_SELECTALL = 'd.resultsPage.selectPlaceholders.selectAll'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_DESELECTALL = 'd.resultsPage.selectPlaceholders.deselectAll'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_OPTIONSSELECTED =
    'd.resultsPage.selectPlaceholders.optionsSelected'

  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_ALLPROGRAMS = 'd.resultsPage.selectPlaceholders.allPrograms'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_ALLLAUNCHES = 'd.resultsPage.selectPlaceholders.allLaunches'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_ALLPROFILES = 'd.resultsPage.selectPlaceholders.allProfiles'
  public static readonly D_RESULTSPAGE_SELECTPLACEHOLDERS_ALLSEGMENTS = 'd.resultsPage.selectPlaceholders.allSegments'
  public static readonly D_RESULTSPAGE_MOBILEOPENERBTN = 'd.resultsPage.mobileOpenerBtn'
  public static readonly D_RESULTSPAGE_SEARCHBTN = 'd.resultsPage.searchBtn'
  public static readonly D_RESULTSPAGE_NORESULTS = 'd.resultsPage.noResults'
  public static readonly D_RESULTSPAGE_NORESULTSFILTERS = 'd.resultsPage.noResultsFilters'
  public static readonly D_RESULTSPAGE_CHANGEFILTERSADVICE = 'd.resultsPage.changeFiltersAdvice'
  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTENOUGHUSERSFORANONYMITY_TITLE =
    'd.resultsPage.noDataWarning.notEnoughUsersForAnonymity.title'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTENOUGHUSERSFORANONYMITY_SUBTITLE =
    'd.resultsPage.noDataWarning.notEnoughUsersForAnonymity.subtitle'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTENOUGHUSERSFORANONYMITY_BUTTON =
    'd.resultsPage.noDataWarning.notEnoughUsersForAnonymity.button'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOUSER_TITLE = 'd.resultsPage.noDataWarning.noUser.title'
  public static readonly D_RESULTSPAGE_NODATAWARNING_NOUSER_SUBTITLE = 'd.resultsPage.noDataWarning.noUser.subtitle'
  public static readonly D_RESULTSPAGE_NODATAWARNING_NOUSER_BUTTON = 'd.resultsPage.noDataWarning.noUser.button'
  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTPERTINENTFILTER_TITLE =
    'd.resultsPage.noDataWarning.notPertinentFilter.title'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTPERTINENTFILTER_SUBTITLE =
    'd.resultsPage.noDataWarning.notPertinentFilter.subtitle'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTENOUGHTIME_TITLE =
    'd.resultsPage.noDataWarning.notEnoughTime.title'

  public static readonly D_RESULTSPAGE_NODATAWARNING_NOTENOUGHTIME_SUBTITLE =
    'd.resultsPage.noDataWarning.notEnoughTime.subtitle'

  public static readonly D_RESULTSPAGE_USAGE_TITLE = 'd.resultsPage.usage.title'
  public static readonly D_RESULTSPAGE_USAGE_USAGEBYUSERS = 'd.resultsPage.usage.usageByUsers'
  public static readonly D_RESULTSPAGE_USAGE_TOOLTIPS_USAGEBYUSERS = 'd.resultsPage.usage.tooltips.usageByUsers'
  public static readonly D_RESULTSPAGE_USAGE_TOOLTIPS_USAGEBYUSERSMANAGERPROFILE =
    'd.resultsPage.usage.tooltips.usageByUsersManagerProfile'

  public static readonly D_RESULTSPAGE_USAGERATE_TITLE = 'd.resultsPage.usageRate.title'
  public static readonly D_RESULTSPAGE_USAGERATE_TOOLTIP = 'd.resultsPage.usageRate.tooltip'
  public static readonly D_RESULTSPAGE_USAGERATE_USERS = 'd.resultsPage.usageRate.users'
  public static readonly D_RESULTSPAGE_USAGERATEBYPROFILE_TITLE = 'd.resultsPage.usageRateByProfile.title'
  public static readonly D_RESULTSPAGE_USAGERATEBYPROFILE_TOOLTIP = 'd.resultsPage.usageRateByProfile.tooltip'
  public static readonly D_RESULTSPAGE_USAGERATEBYPROFILE_UNKNOWNPROFILE =
    'd.resultsPage.usageRateByProfile.unknownProfile'

  public static readonly D_RESULTSPAGE_USERACTIVITY_TITLE = 'd.resultsPage.userActivity.title'
  public static readonly D_RESULTSPAGE_USERACTIVITY_TOOLTIP = 'd.resultsPage.userActivity.tooltip'
  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_INACTIVTITLE =
    'd.resultsPage.userActivity.columnChart.inactivTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_INACTIVSUBTITLE =
    'd.resultsPage.userActivity.columnChart.inactivSubTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_EXPLORERTITLE =
    'd.resultsPage.userActivity.columnChart.explorerTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_EXPLORERSUBTITLE =
    'd.resultsPage.userActivity.columnChart.explorerSubTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_REGULARTITLE =
    'd.resultsPage.userActivity.columnChart.regularTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_REGULARSUBTITLE =
    'd.resultsPage.userActivity.columnChart.regularSubTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_COMMONTITLE =
    'd.resultsPage.userActivity.columnChart.commonTitle'

  public static readonly D_RESULTSPAGE_USERACTIVITY_COLUMNCHART_FIRSTCAMPAIGN =
    'd.resultsPage.userActivity.columnChart.first-campaign'

  public static readonly D_RESULTSPAGE_ACTIONDETAIL_TITLE = 'd.resultsPage.actionDetail.title'
  public static readonly D_RESULTSPAGE_ACTIONDETAIL_ACCEPTED = 'd.resultsPage.actionDetail.accepted'
  public static readonly D_RESULTSPAGE_ACTIONDETAIL_VALIDATED = 'd.resultsPage.actionDetail.validated'
  public static readonly D_RESULTSPAGE_ACTIONDETAIL_TRIED = 'd.resultsPage.actionDetail.tried'
  public static readonly D_RESULTSPAGE_ACTIONDETAIL_AVERAGERATE = 'd.resultsPage.actionDetail.averageRate'
  public static readonly D_RESULTSPAGE_ACTIONDETAIL_BYACTIVEUSER = 'd.resultsPage.actionDetail.byActiveUser'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_TITLE = 'd.resultsPage.courseAnalysis.title'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_TOOLTIPTOPACTIONS =
    'd.resultsPage.courseAnalysis.tooltipTopActions'

  public static readonly D_RESULTSPAGE_COURSEANALYSIS_TOOLTIPFLOPACTIONS =
    'd.resultsPage.courseAnalysis.tooltipFlopActions'

  public static readonly D_RESULTSPAGE_COURSEANALYSIS_TOOLTIPINTENTIONVSACTION =
    'd.resultsPage.courseAnalysis.tooltipIntentionVsAction'

  public static readonly D_RESULTSPAGE_COURSEANALYSIS_MOSTACTIONSLABEL = 'd.resultsPage.courseAnalysis.mostActionsLabel'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_LEASTACTIONSLABEL =
    'd.resultsPage.courseAnalysis.leastActionsLabel'

  public static readonly D_RESULTSPAGE_COURSEANALYSIS_SUCCESSFUL = 'd.resultsPage.courseAnalysis.successful'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_EASIEST = 'd.resultsPage.courseAnalysis.easiest'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_HARDEST = 'd.resultsPage.courseAnalysis.hardest'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_INTENTIONVSACTION =
    'd.resultsPage.courseAnalysis.intentionVsAction'

  public static readonly D_RESULTSPAGE_COURSEANALYSIS_NOTENOUGHTDATA = 'd.resultsPage.courseAnalysis.notEnoughtData'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_PRIORIZATION = 'd.resultsPage.courseAnalysis.priorization'
  public static readonly D_RESULTSPAGE_COURSEANALYSIS_ACTION = 'd.resultsPage.courseAnalysis.action'
  public static readonly D_RESULTSPAGE_ACCEPTIONACTIONEVOLUTION_TITLE = 'd.resultsPage.acceptionActionEvolution.title'
  public static readonly D_RESULTSPAGE_ACCEPTIONACTIONEVOLUTION_TOOLTIP =
    'd.resultsPage.acceptionActionEvolution.tooltip'

  public static readonly D_RESULTSPAGE_ACCEPTIONACTIONWEEKDAYS_TITLE = 'd.resultsPage.acceptionActionWeekDays.title'
  public static readonly D_RESULTSPAGE_ACCEPTIONACTIONWEEKDAYS_TOOLTIP = 'd.resultsPage.acceptionActionWeekDays.tooltip'
  public static readonly D_RESULTSPAGE_DEVICERATE_TITLE = 'd.resultsPage.deviceRate.title'
  public static readonly D_RESULTSPAGE_DEVICERATE_TOOLTIP = 'd.resultsPage.deviceRate.tooltip'
  public static readonly D_RESULTSPAGE_CHANNELRATE_TITLE = 'd.resultsPage.channelRate.title'
  public static readonly D_RESULTSPAGE_CHANNELRATE_TOOLTIP = 'd.resultsPage.channelRate.tooltip'
  public static readonly D_RESULTSPAGE_ACTION_META_TITLE = 'd.resultsPage.action.meta.title'
  public static readonly D_RESULTSPAGE_ACTION_META_DESCRPTION = 'd.resultsPage.action.meta.descrption'
  public static readonly D_RESULTSPAGE_ACTION_TITLE = 'd.resultsPage.action.title'
  public static readonly D_RESULTSPAGE_ACTION_ACCEPTED = 'd.resultsPage.action.accepted'
  public static readonly D_RESULTSPAGE_ACTION_VALIDATED = 'd.resultsPage.action.validated'
  public static readonly D_RESULTSPAGE_ACTION_ALLACCEPTEDBYTIME = 'd.resultsPage.action.allAcceptedByTime'
  public static readonly D_RESULTSPAGE_ACTION_VALIDATEDOVERVIEW = 'd.resultsPage.action.validatedOverview'
  public static readonly D_RESULTSPAGE_ACTION_VALIDATEDAVERAGERATE = 'd.resultsPage.action.validatedAverageRate'
  public static readonly D_RESULTSPAGE_ACTION_HABITCHALLENGES = 'd.resultsPage.action.habitChallenges'
  public static readonly D_RESULTSPAGE_ACTION_TOOLTIPS_ACCEPTED = 'd.resultsPage.action.tooltips.accepted'
  public static readonly D_RESULTSPAGE_ACTION_TOOLTIPS_VALIDATED = 'd.resultsPage.action.tooltips.validated'
  public static readonly D_RESULTSPAGE_ACTION_TOOLTIPS_ALLACCEPTEDBYTIME =
    'd.resultsPage.action.tooltips.allAcceptedByTime'

  public static readonly D_RESULTSPAGE_ANALYSE_META_TITLE = 'd.resultsPage.analyse.meta.title'
  public static readonly D_RESULTSPAGE_ANALYSE_META_DESCRPTION = 'd.resultsPage.analyse.meta.descrption'
  public static readonly D_RESULTSPAGE_ANALYSE_TITLE = 'd.resultsPage.analyse.title'
  public static readonly D_RESULTSPAGE_ANALYSE_ONLYIFMODULESELECTED = 'd.resultsPage.analyse.onlyIfModuleSelected'
  public static readonly D_RESULTSPAGE_ANALYSE_CHOOSEMODULE = 'd.resultsPage.analyse.chooseModule'
  public static readonly D_RESULTSPAGE_ANALYSE_CATEGORIES = 'd.resultsPage.analyse.categories'
  public static readonly D_RESULTSPAGE_ANALYSE_CATEGORYEMPTY = 'd.resultsPage.analyse.categoryEmpty'
  public static readonly D_RESULTSPAGE_ANALYSE_CHALLENGEEMPTY = 'd.resultsPage.analyse.challengeEmpty'
  public static readonly D_RESULTSPAGE_ANALYSE_BESTCHALLENGES = 'd.resultsPage.analyse.bestChallenges'
  public static readonly D_RESULTSPAGE_ANALYSE_WORSTCHALLENGES = 'd.resultsPage.analyse.worstChallenges'
  public static readonly D_RESULTSPAGE_ANALYSE_WORSTTOOLTIP = 'd.resultsPage.analyse.worstTooltip'
  public static readonly D_RESULTSPAGE_ANALYSE_TOOLTIPS_CATEGORIES = 'd.resultsPage.analyse.tooltips.categories'
  public static readonly D_RESULTSPAGE_EVOLUTION = 'd.resultsPage.evolution'
  public static readonly D_RESULTSPAGE_NOPROGRAMS = 'd.resultsPage.no-programs'
  public static readonly D_RESULTSPAGE_TABGLOBAL = 'd.resultsPage.tab-global'
  public static readonly D_RESULTSPAGE_TABCOMPAREGROUPS = 'd.resultsPage.tab-compareGroups'
  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_COURSENAME = 'd.challengeReviewPage.settings.courseName'
  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_INTERNALVISIBILITY =
    'd.challengeReviewPage.settings.internalVisibility'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_INTERNALNAME = 'd.challengeReviewPage.settings.internalName'
  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_EXTERNALVISIBILITY =
    'd.challengeReviewPage.settings.externalVisibility'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_FRENCHCOURSENAME =
    'd.challengeReviewPage.settings.frenchCourseName'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_ENGLISHCOURSENAME =
    'd.challengeReviewPage.settings.englishCourseName'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_SPANISHCOURSENAME =
    'd.challengeReviewPage.settings.spanishCourseName'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_GERMANCOURSENAME =
    'd.challengeReviewPage.settings.germanCourseName'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_SAVEBTN = 'd.challengeReviewPage.settings.saveBtn'
  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_INTERNALTOOLTIP =
    'd.challengeReviewPage.settings.internalTooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_EXTERNALTOOLTIP =
    'd.challengeReviewPage.settings.externalTooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_COMPETENCYTOOLTIP =
    'd.challengeReviewPage.settings.competencyTooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_LABEL =
    'd.challengeReviewPage.settings.programLanguages.label'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_TITLE =
    'd.challengeReviewPage.settings.programLanguages.title'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_DESCRIPTION =
    'd.challengeReviewPage.settings.programLanguages.description'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_MAINLANGUAGE =
    'd.challengeReviewPage.settings.programLanguages.mainLanguage'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_SETMAINLANGUAGE =
    'd.challengeReviewPage.settings.programLanguages.setMainLanguage'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_BUTTON =
    'd.challengeReviewPage.settings.programLanguages.button'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_MAINLANGUAGETOOLTIP =
    'd.challengeReviewPage.settings.programLanguages.mainLanguageTooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_SUCCESS =
    'd.challengeReviewPage.settings.programLanguages.success'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_ERROR =
    'd.challengeReviewPage.settings.programLanguages.error'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_ERRORS_NOTENOUGHLANGUAGES =
    'd.challengeReviewPage.settings.programLanguages.errors.notEnoughLanguages'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_PROGRAMLANGUAGES_ERRORS_MAINLANGUAGENOTINSELECTEDLANGUAGES =
    'd.challengeReviewPage.settings.programLanguages.errors.mainLanguageNotInSelectedLanguages'

  public static readonly D_CHALLENGEREVIEWPAGE_SETTINGS_SHARE_LABEL = 'd.challengeReviewPage.settings.share.label'
  public static readonly D_CHALLENGEREVIEWPAGE_META_TITLE = 'd.challengeReviewPage.meta.title'
  public static readonly D_CHALLENGEREVIEWPAGE_META_DESCRPTION = 'd.challengeReviewPage.meta.descrption'
  public static readonly D_CHALLENGEREVIEWPAGE_DISABLED = 'd.challengeReviewPage.disabled'
  public static readonly D_CHALLENGEREVIEWPAGE_COURSECOMMENT = 'd.challengeReviewPage.courseComment'
  public static readonly D_CHALLENGEREVIEWPAGE_COURSESTITLE = 'd.challengeReviewPage.coursesTitle'
  public static readonly D_CHALLENGEREVIEWPAGE_CREATECOURSEBUTTON = 'd.challengeReviewPage.createCourseButton'
  public static readonly D_CHALLENGEREVIEWPAGE_WITHCOMMENTS = 'd.challengeReviewPage.withComments'
  public static readonly D_CHALLENGEREVIEWPAGE_CREATIONONGOING = 'd.challengeReviewPage.creationOnGoing'
  public static readonly D_CHALLENGEREVIEWPAGE_NOCOMMENTSVALIDATED = 'd.challengeReviewPage.noCommentsValidated'
  public static readonly D_CHALLENGEREVIEWPAGE_NOCOMMENTSREVIEWING = 'd.challengeReviewPage.noCommentsReviewing'
  public static readonly D_CHALLENGEREVIEWPAGE_WITHCOMMENTSCHECK = 'd.challengeReviewPage.withCommentsCheck'
  public static readonly D_CHALLENGEREVIEWPAGE_TABTITLETAG_VALIDATED = 'd.challengeReviewPage.tabTitleTag.validated'
  public static readonly D_CHALLENGEREVIEWPAGE_TABTITLETAG_REVIEWING = 'd.challengeReviewPage.tabTitleTag.reviewing'
  public static readonly D_CHALLENGEREVIEWPAGE_COURSESTITLEADMIN = 'd.challengeReviewPage.coursesTitleAdmin'
  public static readonly D_CHALLENGEREVIEWPAGE_CHALLENGESCOUNTSUFFIX = 'd.challengeReviewPage.challengesCountSuffix'
  public static readonly D_CHALLENGEREVIEWPAGE_SEARCH = 'd.challengeReviewPage.search'
  public static readonly D_CHALLENGEREVIEWPAGE_TABS_GLOBAL = 'd.challengeReviewPage.tabs.global'
  public static readonly D_CHALLENGEREVIEWPAGE_TABS_CHALLENGES = 'd.challengeReviewPage.tabs.challenges'
  public static readonly D_CHALLENGEREVIEWPAGE_TABS_NOTIFICATIONS = 'd.challengeReviewPage.tabs.notifications'
  public static readonly D_CHALLENGEREVIEWPAGE_TABS_SETTINGS = 'd.challengeReviewPage.tabs.settings'
  public static readonly D_CHALLENGEREVIEWPAGE_TITLE = 'd.challengeReviewPage.title'
  public static readonly D_CHALLENGEREVIEWPAGE_TITLECOUNT = 'd.challengeReviewPage.titleCount'
  public static readonly D_CHALLENGEREVIEWPAGE_NOTIFICATIONSCOUNT = 'd.challengeReviewPage.notificationsCount'
  public static readonly D_CHALLENGEREVIEWPAGE_SUBTITLE = 'd.challengeReviewPage.subtitle'
  public static readonly D_CHALLENGEREVIEWPAGE_NOTIFICATIONSSUBTITLE = 'd.challengeReviewPage.notificationsSubtitle'
  public static readonly D_CHALLENGEREVIEWPAGE_NOTIFICATIONWAITINGFORYOU =
    'd.challengeReviewPage.notificationWaitingForYou'

  public static readonly D_CHALLENGEREVIEWPAGE_NOTIFICATIONWAITINGFIFTY =
    'd.challengeReviewPage.notificationWaitingFifty'

  public static readonly D_CHALLENGEREVIEWPAGE_NOTIFICATIONWAITINGCUSTOMER =
    'd.challengeReviewPage.notificationWaitingCustomer'

  public static readonly D_CHALLENGEREVIEWPAGE_CATEGORIES = 'd.challengeReviewPage.categories'
  public static readonly D_CHALLENGEREVIEWPAGE_CHALLENGES = 'd.challengeReviewPage.challenges'
  public static readonly D_CHALLENGEREVIEWPAGE_NONOTIFICATIONS_TITLE = 'd.challengeReviewPage.noNotifications.title'
  public static readonly D_CHALLENGEREVIEWPAGE_NONOTIFICATIONS_SUBTITLE =
    'd.challengeReviewPage.noNotifications.subtitle'

  public static readonly D_CHALLENGEREVIEWPAGE_STATUS_ISAWAITING = 'd.challengeReviewPage.status.isAwaiting'
  public static readonly D_CHALLENGEREVIEWPAGE_STATUS_ISUNREAD = 'd.challengeReviewPage.status.isUnread'
  public static readonly D_CHALLENGEREVIEWPAGE_STATUS_ISREVIEWED = 'd.challengeReviewPage.status.isReviewed'
  public static readonly D_CHALLENGEREVIEWPAGE_EMPTY_NOCHALLENGES = 'd.challengeReviewPage.empty.noChallenges'
  public static readonly D_CHALLENGEREVIEWPAGE_EMPTY_CHOSEMODULE = 'd.challengeReviewPage.empty.choseModule'
  public static readonly D_CHALLENGEREVIEWPAGE_EMPTY_EMPTYDETAIL = 'd.challengeReviewPage.empty.emptyDetail'
  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_STATUS = 'd.challengeReviewPage.filters.status'
  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_STATUSSELECTDEFAULT =
    'd.challengeReviewPage.filters.statusSelectDefault'

  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_COMPANIES = 'd.challengeReviewPage.filters.companies'
  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_COMPANYSELECTDEFAULT =
    'd.challengeReviewPage.filters.companySelectDefault'

  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_MODULE = 'd.challengeReviewPage.filters.module'
  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_MODULESELECTDEFAULT =
    'd.challengeReviewPage.filters.moduleSelectDefault'

  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_SORTBY = 'd.challengeReviewPage.filters.sortBy'
  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_SORTBYSELECTDEFAULT =
    'd.challengeReviewPage.filters.sortBySelectDefault'

  public static readonly D_CHALLENGEREVIEWPAGE_FILTERS_CLIENTSELECTDEFAULT =
    'd.challengeReviewPage.filters.clientSelectDefault'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_LINK =
    'd.challengeReviewPage.detailChallengeReview.link'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_COMPANYNAME =
    'd.challengeReviewPage.detailChallengeReview.companyName'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_TITLE =
    'd.challengeReviewPage.detailChallengeReview.title'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_STATUS =
    'd.challengeReviewPage.detailChallengeReview.status'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_CATEGORY =
    'd.challengeReviewPage.detailChallengeReview.category'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_MODULE =
    'd.challengeReviewPage.detailChallengeReview.module'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_CHARACTERISTIC =
    'd.challengeReviewPage.detailChallengeReview.characteristic'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_DEFINITION =
    'd.challengeReviewPage.detailChallengeReview.definition'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_CONTEXT =
    'd.challengeReviewPage.detailChallengeReview.context'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_ADVICE =
    'd.challengeReviewPage.detailChallengeReview.advice'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_PREVIOUSCHALLENGE =
    'd.challengeReviewPage.detailChallengeReview.previousChallenge'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_NEXTCHALLENGE =
    'd.challengeReviewPage.detailChallengeReview.nextChallenge'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EMPLOYEEROLE =
    'd.challengeReviewPage.detailChallengeReview.employeeRole'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_DURATION =
    'd.challengeReviewPage.detailChallengeReview.duration'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_MINUTE =
    'd.challengeReviewPage.detailChallengeReview.minute'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_MINUTES =
    'd.challengeReviewPage.detailChallengeReview.minutes'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_MANAGERPROFILE =
    'd.challengeReviewPage.detailChallengeReview.managerProfile'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_SKILLS =
    'd.challengeReviewPage.detailChallengeReview.skills'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_LINKEDACTION =
    'd.challengeReviewPage.detailChallengeReview.linkedAction'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_CLIENTNAME =
    'd.challengeReviewPage.detailChallengeReview.clientName'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_ACTIONNAME =
    'd.challengeReviewPage.detailChallengeReview.actionName'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_GLOBAL =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.global'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_UNKNOWN =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.Unknown'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_TOOLONGTITLE =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.TooLongTitle'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_NOTITLE =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.NoTitle'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_NOADVICE =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.NoAdvice'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_ERRORS_NOCONTEXT =
    'd.challengeReviewPage.detailChallengeReview.edit.errors.NoContext'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_EDIT_SUCCESS =
    'd.challengeReviewPage.detailChallengeReview.edit.success'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_PROFILESANDBEHAVIORS_TEXT_PART2 =
    'd.challengeReviewPage.detailChallengeReview.profilesAndBehaviors.text.part2'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_PROFILESANDBEHAVIORS_TEXT_PART1 =
    'd.challengeReviewPage.detailChallengeReview.profilesAndBehaviors.text.part1'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_PROFILESANDBEHAVIORS_TEXT_PART4 =
    'd.challengeReviewPage.detailChallengeReview.profilesAndBehaviors.text.part4'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_PROFILESANDBEHAVIORS_TEXT_PART3 =
    'd.challengeReviewPage.detailChallengeReview.profilesAndBehaviors.text.part3'

  public static readonly D_CHALLENGEREVIEWPAGE_DETAILCHALLENGEREVIEW_WHYTHISACTION =
    'd.challengeReviewPage.detailChallengeReview.whyThisAction'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_ASKFORREVIEWFORMBTN =
    'd.challengeReviewPage.comments.askForReviewFormBtn'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_ASKFORREVIEWFORMTITLE =
    'd.challengeReviewPage.comments.askForReviewFormTitle'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_ASKFORREVIEWFORMDESCRIPTION =
    'd.challengeReviewPage.comments.askForReviewFormDescription'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_REVIEWPLACEHOLDER =
    'd.challengeReviewPage.comments.reviewPlaceholder'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_REVIEWINPUTDESCRIPTION =
    'd.challengeReviewPage.comments.reviewInputDescription'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_FIFTYNAME = 'd.challengeReviewPage.comments.fiftyName'
  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_YOU = 'd.challengeReviewPage.comments.you'
  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_TODAY = 'd.challengeReviewPage.comments.today'
  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_YESTERDAY = 'd.challengeReviewPage.comments.yesterday'
  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_DAYBEFOREYESTERDAY =
    'd.challengeReviewPage.comments.dayBeforeYesterday'

  public static readonly D_CHALLENGEREVIEWPAGE_COMMENTS_ADD = 'd.challengeReviewPage.comments.add'
  public static readonly D_CHALLENGEREVIEWPAGE_CLOSECHALLENGEREVIEW = 'd.challengeReviewPage.closeChallengeReview'
  public static readonly D_CHALLENGEREVIEWPAGE_REOPENCHALLENGEREVIEW = 'd.challengeReviewPage.reOpenChallengeReview'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_RELATEDCONTENT =
    'd.challengeReviewPage.globalView.relatedContent'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_BANNER_TITLE = 'd.challengeReviewPage.globalView.banner.title'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_BANNER_DESCRIPTION =
    'd.challengeReviewPage.globalView.banner.description'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COMMENTS = 'd.challengeReviewPage.globalView.comments'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_TOPUSER = 'd.challengeReviewPage.globalView.topUser'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_LINKEDACTION = 'd.challengeReviewPage.globalView.linkedAction'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_ALLPROFILES = 'd.challengeReviewPage.globalView.allProfiles'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COLUMNNAME_CHALLENGE =
    'd.challengeReviewPage.globalView.columnName.challenge'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COLUMNNAME_ADVICE =
    'd.challengeReviewPage.globalView.columnName.advice'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COLUMNNAME_WHY =
    'd.challengeReviewPage.globalView.columnName.why'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COLUMNNAME_DEFINITION =
    'd.challengeReviewPage.globalView.columnName.definition'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_COLUMNNAME_PROFIL =
    'd.challengeReviewPage.globalView.columnName.profil'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_INSIGHT_CATEGORIES_TITLE =
    'd.challengeReviewPage.globalView.insight.categories.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_INSIGHT_CATEGORIES_TOOLTIP =
    'd.challengeReviewPage.globalView.insight.categories.tooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_INSIGHT_MANAGERPROFILE_TITLE =
    'd.challengeReviewPage.globalView.insight.managerProfile.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_INSIGHT_MANAGERPROFILE_TOOLTIP =
    'd.challengeReviewPage.globalView.insight.managerProfile.tooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_FINISHCUSTOMCHALLENGE =
    'd.challengeReviewPage.globalView.finishCustomChallenge'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_EDITCUSTOMCHALLENGE =
    'd.challengeReviewPage.globalView.editCustomChallenge'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_NONE =
    'd.challengeReviewPage.globalView.validation.none'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_TITLE =
    'd.challengeReviewPage.globalView.validation.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_CURRENTUSERHASVALIDATE =
    'd.challengeReviewPage.globalView.validation.currentUserHasValidate'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_HASVALIDATEACTION =
    'd.challengeReviewPage.globalView.validation.hasValidateAction'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_CLICKTOVALIDATEACTION =
    'd.challengeReviewPage.globalView.validation.clickToValidateAction'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_CLICKTOUNVALIDATEACTION =
    'd.challengeReviewPage.globalView.validation.clickToUnvalidateAction'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_VALIDATION_CURRENTUSERHASNOTVALIDATE =
    'd.challengeReviewPage.globalView.validation.currentUserHasNotValidate'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_INFORMATIONS = 'd.challengeReviewPage.globalView.informations'
  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_TITLE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_TITLE_FROM_TEMPLATE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.title-from-template'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_CONTENT =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.content'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_DESCRIPTION =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.description'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_DESCRIPTION_FROM_TEMPLATE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.description-from-template'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_CONCLUSION =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.conclusion'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_VALIDATE_TITLE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.validate.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_VALIDATE_TEXT =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.validate.text'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_EDIT_TITLE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.edit.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_EDIT_TEXT =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.edit.text'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_DECLINE_TITLE =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.decline.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_HOWTOTREATACTIONS_ACTIONS_DECLINE_TEXT =
    'd.challengeReviewPage.globalView.modal.how-to-treat-actions.actions.decline.text'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_RECOMMANDATIONS_TITLE =
    'd.challengeReviewPage.globalView.modal.recommandations.title'

  public static readonly D_CHALLENGEREVIEWPAGE_GLOBALVIEW_MODAL_RECOMMANDATIONS_CONTENT =
    'd.challengeReviewPage.globalView.modal.recommandations.content'

  public static readonly D_CHALLENGEREVIEWPAGE_ADDACTIONBTN = 'd.challengeReviewPage.addActionBtn'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_LINKEDACTION = 'd.challengeReviewPage.tags.linkedAction'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_OWNERNAME = 'd.challengeReviewPage.tags.ownerName'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DRAFT = 'd.challengeReviewPage.tags.draft'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_REVIEW = 'd.challengeReviewPage.tags.review'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_0 = 'd.challengeReviewPage.tags.difficulty.0'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_1 = 'd.challengeReviewPage.tags.difficulty.1'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_2 = 'd.challengeReviewPage.tags.difficulty.2'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_3 = 'd.challengeReviewPage.tags.difficulty.3'
  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_NOTENOUGHDATA_TOOLTIP =
    'd.challengeReviewPage.tags.difficulty.notEnoughData.tooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_DIFFICULTY_DIFFICULTYTOOLTIP =
    'd.challengeReviewPage.tags.difficulty.difficultyTooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_TAGS_VALIDATEDINOTHERPROGRAM_TOOLTIP =
    'd.challengeReviewPage.tags.validatedInOtherProgram.tooltip'

  public static readonly D_CHALLENGEREVIEWPAGE_DEFAULTPAGETITLE = 'd.challengeReviewPage.defaultPageTitle'
  public static readonly D_CHALLENGEREVIEWPAGE_SHARE_LINK = 'd.challengeReviewPage.share.link'
  public static readonly D_CHALLENGEREVIEWPAGE_APPROVEACTION = 'd.challengeReviewPage.approveAction'
  public static readonly D_CHALLENGEREVIEWPAGE_DESAPPROVEACTION = 'd.challengeReviewPage.desapproveAction'
  public static readonly D_CHALLENGEREVIEWPAGE_SEEACTIONCONTENT = 'd.challengeReviewPage.seeActionContent'
  public static readonly D_CHALLENGEREVIEWPAGE_APPROVEDACTION = 'd.challengeReviewPage.approvedAction'
  public static readonly D_CHALLENGEREVIEWPAGE_UNAPPROVEDACTION = 'd.challengeReviewPage.unapprovedAction'
  public static readonly D_CHALLENGEREVIEWPAGE_EDITDRAFT = 'd.challengeReviewPage.editDraft'
  public static readonly D_CHALLENGEREVIEWPAGE_REJECTREASON_PLACEHOLDER =
    'd.challengeReviewPage.rejectReason.placeholder'

  public static readonly D_CHALLENGEREVIEWPAGE_REJECTREASON_CANCEL = 'd.challengeReviewPage.rejectReason.cancel'
  public static readonly D_CHALLENGEREVIEWPAGE_REJECTREASON_SEND = 'd.challengeReviewPage.rejectReason.send'
  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_PLACEHOLDER =
    'd.challengeReviewPage.footerActions.placeholder'

  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_ACTION_REJECT =
    'd.challengeReviewPage.footerActions.action.reject'

  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_ACTION_VALIDATE =
    'd.challengeReviewPage.footerActions.action.validate'

  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_ACTION_EDIT_CONFIRM =
    'd.challengeReviewPage.footerActions.action.edit.confirm'

  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_ACTION_EDIT_CANCEL =
    'd.challengeReviewPage.footerActions.action.edit.cancel'

  public static readonly D_CHALLENGEREVIEWPAGE_FOOTERACTIONS_ACTION_EDIT_START =
    'd.challengeReviewPage.footerActions.action.edit.start'

  public static readonly D_CHALLENGEREVIEWPAGE_REJECTEDACTIONMESSAGE = 'd.challengeReviewPage.rejectedActionMessage'
  public static readonly D_CHALLENGEREVIEWPAGE_VALIDATION_STATUS0 = 'd.challengeReviewPage.validation.status-0'
  public static readonly D_CHALLENGEREVIEWPAGE_VALIDATION_STATUS1 = 'd.challengeReviewPage.validation.status-1'
  public static readonly D_CHALLENGEREVIEWPAGE_VALIDATION_STATUS2 = 'd.challengeReviewPage.validation.status-2'
  public static readonly D_CHALLENGEREVIEWPAGE_VALIDATION_CANCEL = 'd.challengeReviewPage.validation.cancel'
  public static readonly D_CHALLENGEREVIEWPAGE_WAITINGFORFIFTY = 'd.challengeReviewPage.waitingForFifty'
  public static readonly D_CHALLENGEREVIEWPAGE_WAITINGFORCLIENT = 'd.challengeReviewPage.waitingForClient'
  public static readonly D_CHALLENGEREVIEWPAGE_NEWACTION = 'd.challengeReviewPage.newAction'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONSCOUNT_HOWTOTREAT =
    'd.challengeReviewPage.actions-count.howToTreat'

  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONSCOUNT_RECOMMANDATIONS =
    'd.challengeReviewPage.actions-count.recommandations'

  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONMODAL_COMMENTS = 'd.challengeReviewPage.actionModal.comments'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONMODAL_NEWACTIONS = 'd.challengeReviewPage.actionModal.newActions'
  public static readonly D_CHALLENGEREVIEWPAGE_NEWACTIONS_REVIEWPLACEHOLDER =
    'd.challengeReviewPage.newActions.reviewPlaceholder'

  public static readonly D_CHALLENGEREVIEWPAGE_NEWACTIONS_ADD = 'd.challengeReviewPage.newActions.add'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_TITLE = 'd.challengeReviewPage.histories.title'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_CURRENTVERSION =
    'd.challengeReviewPage.histories.currentVersion'

  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_TODAYAT = 'd.challengeReviewPage.histories.todayAt'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_YESTERDAYAT = 'd.challengeReviewPage.histories.yesterdayAt'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_AT = 'd.challengeReviewPage.histories.at'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_ERRORS_GETALL = 'd.challengeReviewPage.histories.errors.getAll'
  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_ERRORS_RESTORE =
    'd.challengeReviewPage.histories.errors.restore'

  public static readonly D_CHALLENGEREVIEWPAGE_HISTORIES_ORIGINVERSION = 'd.challengeReviewPage.histories.originVersion'
  public static readonly D_CHALLENGEREVIEWPAGE_CHECKTRANSLATION = 'd.challengeReviewPage.checkTranslation'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_LABEL = 'd.challengeReviewPage.actionTranslate.label'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_TITLE = 'd.challengeReviewPage.actionTranslate.title'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_DESCRIPTION =
    'd.challengeReviewPage.actionTranslate.description'

  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_NOTICE = 'd.challengeReviewPage.actionTranslate.notice'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_BUTTON = 'd.challengeReviewPage.actionTranslate.button'
  public static readonly D_CHALLENGEREVIEWPAGE_ACTIONTRANSLATE_NOTICE2 = 'd.challengeReviewPage.actionTranslate.notice2'
  public static readonly D_CHALLENGEREVIEWPAGE_GOTONEXTSTEP = 'd.challengeReviewPage.goToNextStep'
  public static readonly D_CHALLENGEREVIEWPAGE_MUSTVALIDATEALLACTIONS = 'd.challengeReviewPage.mustValidateAllActions'
  public static readonly D_CHALLENGEREVIEWPAGE_TREATALLACTIONS = 'd.challengeReviewPage.treatAllActions'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_STEPS_1 = 'd.dashboardNewCoursePage.common.steps.1'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_STEPS_2 = 'd.dashboardNewCoursePage.common.steps.2'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_STEPS_3 = 'd.dashboardNewCoursePage.common.steps.3'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_STEPS_4 = 'd.dashboardNewCoursePage.common.steps.4'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_COURSENAMEALREADYEXISTS =
    'd.dashboardNewCoursePage.common.errors.CourseNameAlreadyExists'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_COURSENAMEEMPTY =
    'd.dashboardNewCoursePage.common.errors.CourseNameEmpty'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_UNEXPECTEDERROR =
    'd.dashboardNewCoursePage.common.errors.UnexpectedError'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_SAVINGERROR =
    'd.dashboardNewCoursePage.common.errors.SavingError'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_LOADINGERROR =
    'd.dashboardNewCoursePage.common.errors.LoadingError'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_ERRORS_NOTALLOWED =
    'd.dashboardNewCoursePage.common.errors.NotAllowed'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COMMON_TITLE = 'd.dashboardNewCoursePage.common.title'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_META_TITLE = 'd.dashboardNewCoursePage.meta.title'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_META_DESCRIPTION = 'd.dashboardNewCoursePage.meta.description'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_TITLE =
    'd.dashboardNewCoursePage.skillSelectionStep.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_SELECTPROFILESLABEL =
    'd.dashboardNewCoursePage.skillSelectionStep.selectProfilesLabel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_SELECTSKILLSLABEL =
    'd.dashboardNewCoursePage.skillSelectionStep.selectSkillsLabel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_ERRORS_NOPROFILESELECTED =
    'd.dashboardNewCoursePage.skillSelectionStep.errors.noProfileSelected'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_ERRORS_NOTENOUGHSKILLSSELECTED =
    'd.dashboardNewCoursePage.skillSelectionStep.errors.notEnoughSkillsSelected'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_ACTIONS_NEXT =
    'd.dashboardNewCoursePage.skillSelectionStep.actions.next'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_SKILLSMINIMUM =
    'd.dashboardNewCoursePage.skillSelectionStep.skillsMinimum'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_INTRO_TITLE =
    'd.dashboardNewCoursePage.skillSelectionStep.intro.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_SKILLSELECTIONSTEP_INTRO_SUBTITLE =
    'd.dashboardNewCoursePage.skillSelectionStep.intro.subtitle'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_TITLE =
    'd.dashboardNewCoursePage.challengeSelectionStep.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_TITLE =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_TOOLTIP =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.tooltip'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_ACTIONBYSKILLS =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.actionBySkills'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_ACTIONBYPROFILES =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.actionByProfiles'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_ACTIONS =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.actions'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_TITLEERROR =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.titleError'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_VALIDATIONS_ALL =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.validations.all'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_VALIDATIONS_SKILLS =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.validations.skills'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_HEALTH_VALIDATIONS_PROFILES =
    'd.dashboardNewCoursePage.challengeSelectionStep.health.validations.profiles'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_TITLE =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_EMPTY =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.empty'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_CONFIRMALL =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.confirmAll'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_STATES_CONFIRMED =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.states.confirmed'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_STATES_REJECTED =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.states.rejected'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_REJECTIONREASONS_NOTADAPTEDTOSELECTEDPROFILES =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.rejectionReasons.NotAdaptedToSelectedProfiles'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_REJECTIONREASONS_NOTADAPTEDTOSELECTEDSKILLS =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.rejectionReasons.NotAdaptedToSelectedSkills'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_REJECTIONREASONS_NOTADAPTEDTOCONTEXT =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.rejectionReasons.NotAdaptedToContext'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_REJECTIONREASONS_NOTADAPTED =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.rejectionReasons.NotAdapted'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_REJECTIONREASONS_NOTADAPTEDTOOBJECTIVE =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.rejectionReasons.NotAdaptedToObjective'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_DESCRIPTION =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.description'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_COLUMNS_SKILLS =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.columns.skills'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_COLUMNS_ACTIONS =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.columns.actions'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_COLUMNS_PREDICTIONS =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.columns.predictions'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_MORE =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.more'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_NEW =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.new'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_CANDIDATES_ERRORS_NOTENOUGH =
    'd.dashboardNewCoursePage.challengeSelectionStep.candidates.errors.notEnough'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_BACKBUTTONPOPUP_EXPLANATION =
    'd.dashboardNewCoursePage.challengeSelectionStep.backButtonPopup.explanation'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ERRORS_NOTENOUGHTOTALCHALLENGES =
    'd.dashboardNewCoursePage.challengeSelectionStep.errors.notEnoughTotalChallenges'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ERRORS_NOTENOUGHCHALLENGESINCATEGORIES =
    'd.dashboardNewCoursePage.challengeSelectionStep.errors.notEnoughChallengesInCategories'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_DECLINE =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.decline'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_CANCEL =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.cancel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_CONFIRM =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.confirm'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_BACK =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.back'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_NEXT =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.next'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_MOREACTIONS =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.moreActions'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_DISABLED =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.disabled'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_ACTIONS_LOADING =
    'd.dashboardNewCoursePage.challengeSelectionStep.actions.loading'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_INTRO_DESCRIPTION =
    'd.dashboardNewCoursePage.challengeSelectionStep.intro.description'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_TITLE =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_DESCRIPTION =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.description'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_TOTALACTIONS =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.totalActions'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_BYCATEGORIES =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.byCategories'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_TITLE =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_TEXT =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.text'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_QUESTION1 =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.question1'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_ANSWER1 =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.answer1'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_QUESTION2 =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.question2'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_MODAL_ANSWER2 =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.modal.answer2'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_VALIDATIONS_ALL_LABEL =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.validations.all.label'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_VALIDATIONS_ALL_COUNT =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.validations.all.count'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_VALIDATIONS_PILLARS_LABEL =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.validations.pillars.label'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_CHALLENGESELECTIONSTEP_STATUS_VALIDATIONS_PILLARS_COUNT =
    'd.dashboardNewCoursePage.challengeSelectionStep.status.validations.pillars.count'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_INTRODUCTION =
    'd.dashboardNewCoursePage.courseReviewStep.introduction'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSENAME_EXPLANATION =
    'd.dashboardNewCoursePage.courseReviewStep.courseName.explanation'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSENAME_COURSENAMELABEL =
    'd.dashboardNewCoursePage.courseReviewStep.courseName.courseNameLabel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSENAME_ERROR =
    'd.dashboardNewCoursePage.courseReviewStep.courseName.error'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_CANDIDATESTITLE =
    'd.dashboardNewCoursePage.courseReviewStep.candidatesTitle'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_EDITCATEGORYMODAL_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.editCategoryModal.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_EDITCATEGORYMODAL_EXPLANATION =
    'd.dashboardNewCoursePage.courseReviewStep.editCategoryModal.explanation'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_EDITCATEGORYMODAL_LABEL =
    'd.dashboardNewCoursePage.courseReviewStep.editCategoryModal.label'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_EDITCATEGORYMODAL_CANCELBUTTON =
    'd.dashboardNewCoursePage.courseReviewStep.editCategoryModal.cancelButton'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_EDITCATEGORYMODAL_SAVEBUTTON =
    'd.dashboardNewCoursePage.courseReviewStep.editCategoryModal.saveButton'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_BACKBUTTONPOPUP_EXPLANATION =
    'd.dashboardNewCoursePage.courseReviewStep.backButtonPopup.explanation'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_BACKBUTTONPOPUP_CANCEL =
    'd.dashboardNewCoursePage.courseReviewStep.backButtonPopup.cancel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_BACKBUTTONPOPUP_CONFIRM =
    'd.dashboardNewCoursePage.courseReviewStep.backButtonPopup.confirm'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_BACKBUTTON =
    'd.dashboardNewCoursePage.courseReviewStep.backButton'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_NEXTBUTTON =
    'd.dashboardNewCoursePage.courseReviewStep.nextButton'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSECREATEDMODAL_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseCreatedModal.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSECREATEDMODAL_BODY_SUCCESS =
    'd.dashboardNewCoursePage.courseReviewStep.courseCreatedModal.body.success'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSECREATEDMODAL_BODY_INREVIEW =
    'd.dashboardNewCoursePage.courseReviewStep.courseCreatedModal.body.inReview'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSECREATEDMODAL_BODY_ACTIONCHANGEEXPLANATION =
    'd.dashboardNewCoursePage.courseReviewStep.courseCreatedModal.body.actionChangeExplanation'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSECREATEDMODAL_BUTTONLABEL =
    'd.dashboardNewCoursePage.courseReviewStep.courseCreatedModal.buttonLabel'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_INTRO_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.intro.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_INTRO_DESCRIPTION =
    'd.dashboardNewCoursePage.courseReviewStep.intro.description'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEINTERNALNAME_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseInternalName.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEINTERNALNAME_LABEL =
    'd.dashboardNewCoursePage.courseReviewStep.courseInternalName.label'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEINTERNALNAME_TOOLTIP =
    'd.dashboardNewCoursePage.courseReviewStep.courseInternalName.tooltip'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEREVIEW_TITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseReview.title'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEREVIEW_SUBTITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseReview.subtitle'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEREVIEW_CATEGORYTITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseReview.categoryTitle'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_COURSEREVIEWSTEP_COURSEREVIEW_ACTIONSTITLE =
    'd.dashboardNewCoursePage.courseReviewStep.courseReview.actionsTitle'

  public static readonly D_DASHBOARDNEWCOURSEPAGE_LOADING_TITLE = 'd.dashboardNewCoursePage.loading.title'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_LOADING_STEPS_1 = 'd.dashboardNewCoursePage.loading.steps.1'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_LOADING_STEPS_2 = 'd.dashboardNewCoursePage.loading.steps.2'
  public static readonly D_DASHBOARDNEWCOURSEPAGE_LOADING_STEPS_3 = 'd.dashboardNewCoursePage.loading.steps.3'
  public static readonly D_ADMINNEWCOURSE_FOOTER_NEXT = 'd.adminNewCourse.footer.next'
  public static readonly D_ADMINNEWCOURSE_FOOTER_PREVIOUS = 'd.adminNewCourse.footer.previous'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_BANNER_TITLE = 'd.adminNewCourse.firstStep.banner.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_BANNER_DESCRIPTION = 'd.adminNewCourse.firstStep.banner.description'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_POPULATIONCARD_TITLE =
    'd.adminNewCourse.firstStep.populationCard.title'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_POPULATIONCARD_ERRORS_NOTENOUGH =
    'd.adminNewCourse.firstStep.populationCard.errors.notEnough'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_TITLE = 'd.adminNewCourse.firstStep.pillarsCard.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_DESCRIPTION =
    'd.adminNewCourse.firstStep.pillarsCard.description'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_LABEL =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.label'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_PLACEHOLDER =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.placeholder'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_ERRORS_SAMENAME =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.errors.sameName'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_ERRORS_EMPTYNAME =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.errors.emptyName'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_ERRORS_MINIMUMNUMBER =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.errors.minimumNumber'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_PILLARSCARD_PILLARINPUT_ERRORS_MAXIMUMNUMBER =
    'd.adminNewCourse.firstStep.pillarsCard.pillarInput.errors.maximumNumber'

  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_TITLE = 'd.adminNewCourse.firstStep.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_ERRORS_UNKNOWN = 'd.adminNewCourse.firstStep.errors.unknown'
  public static readonly D_ADMINNEWCOURSE_FIRSTSTEP_ERRORS_INPUTREQUIRED =
    'd.adminNewCourse.firstStep.errors.inputRequired'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_TITLE = 'd.adminNewCourse.skillsStep.title'
  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_BANNER_TITLE = 'd.adminNewCourse.skillsStep.banner.title'
  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_BANNER_DESCRIPTION =
    'd.adminNewCourse.skillsStep.banner.description'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_TITLE = 'd.adminNewCourse.skillsStep.skillsCard.title'
  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_DESCRIPTION =
    'd.adminNewCourse.skillsStep.skillsCard.description'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_FOOTER_BACKCONFIRM =
    'd.adminNewCourse.skillsStep.skillsCard.footer.backConfirm'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_BADCRITERIAMODAL_TITLE =
    'd.adminNewCourse.skillsStep.skillsCard.badCriteriaModal.title'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_BADCRITERIAMODAL_TEXT =
    'd.adminNewCourse.skillsStep.skillsCard.badCriteriaModal.text'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_BADCRITERIAMODAL_OK =
    'd.adminNewCourse.skillsStep.skillsCard.badCriteriaModal.ok'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_ERRORS_FETCH =
    'd.adminNewCourse.skillsStep.skillsCard.errors.fetch'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_ERRORS_INVALIDSKILLCOUNT =
    'd.adminNewCourse.skillsStep.skillsCard.errors.invalidSkillCount'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_ERRORS_NOTENOUGHCATEGORIES =
    'd.adminNewCourse.skillsStep.skillsCard.errors.notEnoughCategories'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_ERRORS_SUBMIT =
    'd.adminNewCourse.skillsStep.skillsCard.errors.submit'

  public static readonly D_ADMINNEWCOURSE_SKILLSSTEP_SKILLSCARD_ERRORS_BACK =
    'd.adminNewCourse.skillsStep.skillsCard.errors.back'

  public static readonly D_ADMINNEWCOURSE_FIRSTLOADINGSTEP_TITLE = 'd.adminNewCourse.firstLoadingStep.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTLOADINGSTEP_STEPS_1 = 'd.adminNewCourse.firstLoadingStep.steps.1'
  public static readonly D_ADMINNEWCOURSE_FIRSTLOADINGSTEP_STEPS_2 = 'd.adminNewCourse.firstLoadingStep.steps.2'
  public static readonly D_ADMINNEWCOURSE_FIRSTLOADINGSTEP_STEPS_3 = 'd.adminNewCourse.firstLoadingStep.steps.3'
  public static readonly D_ADMINNEWCOURSE_SECONDLOADINGSTEP_TITLE = 'd.adminNewCourse.secondLoadingStep.title'
  public static readonly D_ADMINNEWCOURSE_SECONDLOADINGSTEP_STEPS_1 = 'd.adminNewCourse.secondLoadingStep.steps.1'
  public static readonly D_ADMINNEWCOURSE_SECONDLOADINGSTEP_STEPS_2 = 'd.adminNewCourse.secondLoadingStep.steps.2'
  public static readonly D_ADMINNEWCOURSE_SECONDLOADINGSTEP_STEPS_3 = 'd.adminNewCourse.secondLoadingStep.steps.3'
  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_TITLE = 'd.adminNewCourse.firstBatchStep.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_BANNER_TITLE = 'd.adminNewCourse.firstBatchStep.banner.title'
  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_BANNER_DESCRIPTION =
    'd.adminNewCourse.firstBatchStep.banner.description'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGWRONGACTIONS_TITLE =
    'd.adminNewCourse.firstBatchStep.warningWrongActions.title'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGWRONGACTIONS_DESCRIPTION =
    'd.adminNewCourse.firstBatchStep.warningWrongActions.description'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGWRONGACTIONS_BUTTONLABEL =
    'd.adminNewCourse.firstBatchStep.warningWrongActions.buttonLabel'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGNOACTIONS_TITLE =
    'd.adminNewCourse.firstBatchStep.warningNoActions.title'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGNOACTIONS_DESCRIPTION =
    'd.adminNewCourse.firstBatchStep.warningNoActions.description'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_WARNINGNOACTIONS_BUTTONLABEL =
    'd.adminNewCourse.firstBatchStep.warningNoActions.buttonLabel'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_FOOTER_BACKCONFIRM =
    'd.adminNewCourse.firstBatchStep.footer.backConfirm'

  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_ERRORS_SUBMIT = 'd.adminNewCourse.firstBatchStep.errors.submit'
  public static readonly D_ADMINNEWCOURSE_FIRSTBATCHSTEP_ERRORS_BACK = 'd.adminNewCourse.firstBatchStep.errors.back'
  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_TITLE = 'd.adminNewCourse.allCandidatesStep.title'
  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_BANNER_TITLE =
    'd.adminNewCourse.allCandidatesStep.banner.title'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_BANNER_DESCRIPTION =
    'd.adminNewCourse.allCandidatesStep.banner.description'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_WARNINGNOTENOUGH_TITLE =
    'd.adminNewCourse.allCandidatesStep.warningNotEnough.title'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_WARNINGNOTENOUGH_DESCRIPTION =
    'd.adminNewCourse.allCandidatesStep.warningNotEnough.description'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_WARNINGNOTENOUGH_CHIP =
    'd.adminNewCourse.allCandidatesStep.warningNotEnough.chip'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_FOOTER_BACKCONFIRM =
    'd.adminNewCourse.allCandidatesStep.footer.backConfirm'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_ERRORS_SUBMIT =
    'd.adminNewCourse.allCandidatesStep.errors.submit'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_ERRORS_BACK =
    'd.adminNewCourse.allCandidatesStep.errors.back'

  public static readonly D_ADMINNEWCOURSE_ALLCANDIDATESSTEP_CANDIDATESLOADING =
    'd.adminNewCourse.allCandidatesStep.candidatesLoading'

  public static readonly D_ADMINNEWCOURSEFROMTEMPLATE_ANIMATION_TITLE = 'd.adminNewCourseFromTemplate.animation.title'
  public static readonly D_ADMINNEWCOURSEFROMTEMPLATE_ANIMATION_STEPS_1 =
    'd.adminNewCourseFromTemplate.animation.steps.1'

  public static readonly D_ADMINNEWCOURSEFROMTEMPLATE_ANIMATION_STEPS_2 =
    'd.adminNewCourseFromTemplate.animation.steps.2'

  public static readonly D_ADMINNEWCOURSEFROMTEMPLATE_ANIMATION_STEPS_3 =
    'd.adminNewCourseFromTemplate.animation.steps.3'

  public static readonly D_ADMINNEWCOURSE_REVIEWSTEP_TITLE = 'd.adminNewCourse.reviewStep.title'
  public static readonly D_ADMINEXPIRECCONTRACTPAGE_TITLE = 'd.adminExpirecContractPage.title'
  public static readonly D_ADMINEXPIRECCONTRACTPAGE_SUBTITLE1 = 'd.adminExpirecContractPage.subtitle1'
  public static readonly D_ADMINEXPIRECCONTRACTPAGE_SUBTITLE2 = 'd.adminExpirecContractPage.subtitle2'
  public static readonly D_ADMINEXPIRECCONTRACTPAGE_CONTACTBTN = 'd.adminExpirecContractPage.contactBtn'
  public static readonly D_ACTIONREPOSITORYPAGE_TITLE = 'd.actionRepositoryPage.title'
  public static readonly D_ACTIONREPOSITORYPAGE_DESCRIPTION = 'd.actionRepositoryPage.description'
  public static readonly D_ACTIONREPOSITORYPAGE_META_TITLE = 'd.actionRepositoryPage.meta.title'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_SEARCH = 'd.actionRepositoryPage.filters.search'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_PROGRAMS = 'd.actionRepositoryPage.filters.programs'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_SKILLS = 'd.actionRepositoryPage.filters.skills'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_PROFILES = 'd.actionRepositoryPage.filters.profiles'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_RESETFILTERS = 'd.actionRepositoryPage.filters.resetFilters'
  public static readonly D_ACTIONREPOSITORYPAGE_FILTERS_PILLARS = 'd.actionRepositoryPage.filters.pillars'
  public static readonly D_ACTIONREPOSITORYPAGE_NORESULT_TITLE = 'd.actionRepositoryPage.noResult.title'
  public static readonly D_ACTIONREPOSITORYPAGE_NORESULT_DESCRIPTION = 'd.actionRepositoryPage.noResult.description'
  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_HEADER = 'd.actionRepositoryPage.actions.header'
  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_FOOTER_DESCRIPTION =
    'd.actionRepositoryPage.actions.footer.description'

  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_FOOTER_ACTIONBTN =
    'd.actionRepositoryPage.actions.footer.actionBtn'

  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_CREATEDBY = 'd.actionRepositoryPage.actions.createdBy'
  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_PERSONALIZED = 'd.actionRepositoryPage.actions.personalized'
  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_PROGRAMS = 'd.actionRepositoryPage.actions.programs'
  public static readonly D_ACTIONREPOSITORYPAGE_ACTIONS_USEDIN = 'd.actionRepositoryPage.actions.usedIn'
  public static readonly D_ACTIONREPOSITORYPAGE_SUCCESFULLYADDEDTOCOURSE =
    'd.actionRepositoryPage.succesfullyAddedToCourse'

  public static readonly D_ACTIONREPOSITORYPAGE_ERRORS_LOADINGERROR = 'd.actionRepositoryPage.errors.LoadingError'
  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_TITLE = 'd.actionRepositoryPage.modal.firstStep.title'
  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_DESCRIPTION =
    'd.actionRepositoryPage.modal.firstStep.description'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_SELECT_LABEL =
    'd.actionRepositoryPage.modal.firstStep.select.label'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_SELECT_PLACEHOLDER =
    'd.actionRepositoryPage.modal.firstStep.select.placeholder'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_SELECT_CREATENEWPROGRAM =
    'd.actionRepositoryPage.modal.firstStep.select.createNewProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_ERRORS_ONLYONEPROGRAM =
    'd.actionRepositoryPage.modal.firstStep.errors.onlyOneProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_ERRORS_ACTIONSALREADYINTHEPROGRAM =
    'd.actionRepositoryPage.modal.firstStep.errors.actionsAlreadyInTheProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_ERRORS_CREATENEWPROGRAM =
    'd.actionRepositoryPage.modal.firstStep.errors.createNewProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_ACTIONS_NEXT =
    'd.actionRepositoryPage.modal.firstStep.actions.next'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_NAME =
    'd.actionRepositoryPage.modal.firstStep.createProgram.name'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_DESCRIPTION =
    'd.actionRepositoryPage.modal.firstStep.createProgram.description'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_NOTABENE =
    'd.actionRepositoryPage.modal.firstStep.createProgram.notabene'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_SUBMIT =
    'd.actionRepositoryPage.modal.firstStep.createProgram.submit'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_ERROR =
    'd.actionRepositoryPage.modal.firstStep.createProgram.error'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_SUCCESS =
    'd.actionRepositoryPage.modal.firstStep.createProgram.success'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_ONLYONEPROGRAM =
    'd.actionRepositoryPage.modal.firstStep.createProgram.onlyOneProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_FIRSTSTEP_CREATEPROGRAM_SUCCESSHERE =
    'd.actionRepositoryPage.modal.firstStep.createProgram.successHere'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_TITLE = 'd.actionRepositoryPage.modal.secondStep.title'
  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ERRORS_NOACTIONSELECTED =
    'd.actionRepositoryPage.modal.secondStep.errors.noActionSelected'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ERRORS_ACTIONSALREADYINTHEPROGRAM =
    'd.actionRepositoryPage.modal.secondStep.errors.actionsAlreadyInTheProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ERRORS_HASNOCATEGORY =
    'd.actionRepositoryPage.modal.secondStep.errors.hasNoCategory'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ERRORS_ACTIONWITHOUTCATEGORY =
    'd.actionRepositoryPage.modal.secondStep.errors.actionWithoutCategory'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_SELECTEDPROGRAM =
    'd.actionRepositoryPage.modal.secondStep.selectedProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_DESCRIPTION =
    'd.actionRepositoryPage.modal.secondStep.description'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_TABLE_ACTION =
    'd.actionRepositoryPage.modal.secondStep.table.action'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_TABLE_CATEGORY =
    'd.actionRepositoryPage.modal.secondStep.table.category'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_TABLE_SELECT_PLACEHOLDER =
    'd.actionRepositoryPage.modal.secondStep.table.select.placeholder'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ACTIONS_BACK =
    'd.actionRepositoryPage.modal.secondStep.actions.back'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ACTIONS_NEXT =
    'd.actionRepositoryPage.modal.secondStep.actions.next'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_SECONDSTEP_ACTIONS_CLOSE =
    'd.actionRepositoryPage.modal.secondStep.actions.close'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_PROGRAMSELECT_OPTIONS_NEWPROGRAM =
    'd.actionRepositoryPage.modal.programSelect.options.newProgram'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_PROGRAMSELECT_LABEL =
    'd.actionRepositoryPage.modal.programSelect.label'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_PILLARSELECT_OPTIONS_NEWPILLAR =
    'd.actionRepositoryPage.modal.pillarSelect.options.newPillar'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_PILLARSELECT_LABEL =
    'd.actionRepositoryPage.modal.pillarSelect.label'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_PILLARSELECT_PLACEHOLDER =
    'd.actionRepositoryPage.modal.pillarSelect.placeholder'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_ERRORS_TOOMANYPILLARS =
    'd.actionRepositoryPage.modal.errors.tooManyPillars'

  public static readonly D_ACTIONREPOSITORYPAGE_MODAL_ACTIONS_CONTINUE = 'd.actionRepositoryPage.modal.actions.continue'
  public static readonly D_ACTIONREPOSITORYPAGE_NOPROGRAM = 'd.actionRepositoryPage.no-program'
  public static readonly D_ACTIONREPOSITORYPAGE_STATS_TOTALACTIONS = 'd.actionRepositoryPage.stats.totalActions'
  public static readonly D_ACTIONREPOSITORYPAGE_STATS_PERSONNALIZEDACTIONS =
    'd.actionRepositoryPage.stats.personnalizedActions'

  public static readonly D_ACTIONREPOSITORYPAGE_STATS_CREATEDBYCLIENTS = 'd.actionRepositoryPage.stats.createdByClients'
  public static readonly D_ACTIONREPOSITORYPAGE_STATS_MULTIPLEPROGRAMS = 'd.actionRepositoryPage.stats.multiplePrograms'
  public static readonly D_ACTIONREPOSITORYPAGE_PILLARS_NOPROGRAMNAME = 'd.actionRepositoryPage.pillars.noProgramName'
  public static readonly D_ACTIONREPOSITORYPAGE_TITLE2 = 'd.actionRepositoryPage.title2'
  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_TITLE =
    'd.programActionsPage.createdWithChallenges.title'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_SUBTITLE =
    'd.programActionsPage.createdWithChallenges.subtitle'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_DESCRIPTION =
    'd.programActionsPage.createdWithChallenges.description'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_GENERATORDISABLED =
    'd.programActionsPage.createdWithChallenges.generatorDisabled'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_ACTIONS_TITLE =
    'd.programActionsPage.createdWithChallenges.actions.title'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_ACTIONS_SEECONTENT =
    'd.programActionsPage.createdWithChallenges.actions.seeContent'

  public static readonly D_PROGRAMACTIONSPAGE_CREATEDWITHCHALLENGES_GENERATE =
    'd.programActionsPage.createdWithChallenges.generate'

  public static readonly D_CAMPAIGN_ACTIONOVERVIEW_NONVALIDATED = 'd.campaign.actionOverview.nonvalidated'
  public static readonly D_MANAGERPROFILES_7D59C4642A7F4443BEA6A022E6740A8E =
    'd.manager-profiles.7d59c464-2a7f-4443-bea6-a022e6740a8e'

  public static readonly D_MANAGERPROFILES_F4B04CD07FFF4CFC9A90F090485C0432 =
    'd.manager-profiles.f4b04cd0-7fff-4cfc-9a90-f090485c0432'

  public static readonly D_MANAGERPROFILES_7793AAFD4D7347AC99A868EB82CA846E =
    'd.manager-profiles.7793aafd-4d73-47ac-99a8-68eb82ca846e'

  public static readonly D_MANAGERPROFILES_OPTIONS_7D59C4642A7F4443BEA6A022E6740A8E =
    'd.manager-profiles.options.7d59c464-2a7f-4443-bea6-a022e6740a8e'

  public static readonly D_MANAGERPROFILES_OPTIONS_F4B04CD07FFF4CFC9A90F090485C0432 =
    'd.manager-profiles.options.f4b04cd0-7fff-4cfc-9a90-f090485c0432'

  public static readonly D_MANAGERPROFILES_OPTIONS_7793AAFD4D7347AC99A868EB82CA846E =
    'd.manager-profiles.options.7793aafd-4d73-47ac-99a8-68eb82ca846e'

  public static readonly D_MANAGERPROFILES_OPTIONS_00000000000000000000000000000000 =
    'd.manager-profiles.options.00000000-0000-0000-0000-000000000000'

  public static readonly D_MANAGERPROFILES_NOMANAGER_FULL = 'd.manager-profiles.no-manager.full'
  public static readonly D_MANAGERPROFILES_NOMANAGER_SHORT = 'd.manager-profiles.no-manager.short'
  public static readonly D_MANAGERPROFILES_HIERARCHICAL_FULL = 'd.manager-profiles.hierarchical.full'
  public static readonly D_MANAGERPROFILES_HIERARCHICAL_SHORT = 'd.manager-profiles.hierarchical.short'
  public static readonly D_MANAGERPROFILES_FUNCTIONAL_FULL = 'd.manager-profiles.functional.full'
  public static readonly D_MANAGERPROFILES_FUNCTIONAL_SHORT = 'd.manager-profiles.functional.short'
  public static readonly D_MANAGERPROFILES_NOTSPECIFIED = 'd.manager-profiles.not-specified'
  public static readonly D_EDITCOMMKIT_CANCEL = 'd.editCommKit.cancel'
  public static readonly D_EDITCOMMKIT_TITLE_WELCOMEEMAILTITLE = 'd.editCommKit.title.WelcomeEmailTitle'
  public static readonly D_EDITCOMMKIT_TITLE_WELCOMEEMAILBODY = 'd.editCommKit.title.WelcomeEmailBody'
  public static readonly D_EDITCOMMKIT_TITLE_ONBOARDINGSENTENCE = 'd.editCommKit.title.OnboardingSentence'
  public static readonly D_EDITCOMMKIT_TITLE_PROGRAMNAMEFOREMAILS = 'd.editCommKit.title.ProgramNameForEmails'
  public static readonly D_EDITCOMMKIT_TITLE_PROGRAMNAMEFOREMPLOYEES = 'd.editCommKit.title.ProgramNameForEmployees'
  public static readonly D_EDITCOMMKIT_CONFIRM = 'd.editCommKit.confirm'
  public static readonly PHONEAPPMINORVERSION_P = 'phoneAppMinorVersion.p'
  public static readonly CHALLENGECARD_HABIT = 'challengeCard.habit'
  public static readonly CHALLENGECARD_DONEFORGOOD = 'challengeCard.doneForGood'
  public static readonly HABITREPLAY_MODALREINFORCE = 'habit-replay.modalReinforce'
  public static readonly HABITREPLAY_MODALRESUME = 'habit-replay.modalResume'
  public static readonly HABITREPLAY_MODALCANCEL = 'habit-replay.modalCancel'
  public static readonly HABITREPLAY_MODALCONFIRM = 'habit-replay.modalConfirm'
  public static readonly DAYOFWEEK_0 = 'dayOfWeek.0'
  public static readonly DAYOFWEEK_1 = 'dayOfWeek.1'
  public static readonly DAYOFWEEK_2 = 'dayOfWeek.2'
  public static readonly DAYOFWEEK_3 = 'dayOfWeek.3'
  public static readonly DAYOFWEEK_4 = 'dayOfWeek.4'
  public static readonly DAYOFWEEK_5 = 'dayOfWeek.5'
  public static readonly DAYOFWEEK_6 = 'dayOfWeek.6'
  public static readonly HABITSTOP_MODAL = 'habitStop.modal'
  public static readonly HABITSTOP_MODALCANCEL = 'habitStop.modalCancel'
  public static readonly HABITSTOP_MODALCONFIRM = 'habitStop.modalConfirm'
  public static readonly HABITCONGRATULATION_MODAL = 'habitCongratulation.modal'
  public static readonly HABITCONGRATULATION_MODALCANCEL = 'habitCongratulation.modalCancel'
  public static readonly HABITCONGRATULATION_MODALCONFIRM = 'habitCongratulation.modalConfirm'
  public static readonly HABITCONGRATULATION_TITLE = 'habitCongratulation.title'
  public static readonly HABITCONGRATULATION_TEXT = 'habitCongratulation.text'
  public static readonly HABITCONGRATULATION_NEXT = 'habitCongratulation.next'
  public static readonly DASHBOARD_HOME_HELLO = 'dashboard.home.hello'
  public static readonly DASHBOARD_HOME_DESCRIPTION = 'dashboard.home.description'
  public static readonly DASHBOARD_HOME_QUICKACCESS = 'dashboard.home.quickAccess'
  public static readonly DASHBOARD_HOME_GENERALINFORMATIONS = 'dashboard.home.generalInformations'
  public static readonly DASHBOARD_HOME_CONTRACTSTART = 'dashboard.home.contractStart'
  public static readonly DASHBOARD_HOME_CONTRACTEND = 'dashboard.home.contractEnd'
  public static readonly DASHBOARD_HOME_LICENSESNUMBER = 'dashboard.home.licensesNumber'
  public static readonly DASHBOARD_HOME_ACCOUNTMANAGER = 'dashboard.home.accountManager'
  public static readonly DASHBOARD_HOME_CSMMANAGER = 'dashboard.home.csmManager'
  public static readonly DASHBOARD_HOME_RESULTSCARD_TITLE = 'dashboard.home.resultsCard.title'
  public static readonly DASHBOARD_HOME_RESULTSCARD_CONTENT = 'dashboard.home.resultsCard.content'
  public static readonly DASHBOARD_HOME_COURSECARD_TITLE = 'dashboard.home.courseCard.title'
  public static readonly DASHBOARD_HOME_COURSECARD_CONTENT = 'dashboard.home.courseCard.content'
  public static readonly DASHBOARD_HOME_USERSCARD_TITLE = 'dashboard.home.usersCard.title'
  public static readonly DASHBOARD_HOME_USERSCARD_CONTENT = 'dashboard.home.usersCard.content'
  public static readonly DASHBOARD_HOME_CAMPAIGNSCARD_TITLE = 'dashboard.home.campaignsCard.title'
  public static readonly DASHBOARD_HOME_CAMPAIGNSCARD_CONTENT = 'dashboard.home.campaignsCard.content'
  public static readonly DASHBOARD_HOME_FILE_DOWNLOAD = 'dashboard.home.file.download'
  public static readonly DASHBOARD_HOME_FILE_DELETE = 'dashboard.home.file.delete'
  public static readonly DASHBOARD_HOME_FILE_SCANNING = 'dashboard.home.file.scanning'
  public static readonly DASHBOARD_HOME_FILE_INFECTED = 'dashboard.home.file.infected'
  public static readonly DASHBOARD_HOME_FILE_TITLE = 'dashboard.home.file.title'
  public static readonly DASHBOARD_HOME_FILE_DESCRIPTION = 'dashboard.home.file.description'
  public static readonly DASHBOARD_HOME_FILE_UPLOADING = 'dashboard.home.file.uploading'
  public static readonly DASHBOARD_HOME_FILE_COMPANYVALUES = 'dashboard.home.file.companyValues'
  public static readonly DASHBOARD_HOME_FILE_LEADERSHIPMODEL = 'dashboard.home.file.leadershipModel'
  public static readonly DASHBOARD_HOME_FILE_STRATEGICPLAN = 'dashboard.home.file.strategicPlan'
  public static readonly DASHBOARD_ADMINCREATEPROGRAMGENERATION_SUCCESS_ADDFILE =
    'dashboard.adminCreateProgramGeneration.success.addFile'

  public static readonly DASHBOARD_ADMINCREATEPROGRAMGENERATION_ERRORS_ADDFILE =
    'dashboard.adminCreateProgramGeneration.errors.addFile'

  public static readonly DASHBOARD_ADMINCREATEPROGRAMGENERATION_ERRORS_DELETEFILE =
    'dashboard.adminCreateProgramGeneration.errors.deleteFile'

  public static readonly DASHBOARD_MENU_HOME = 'dashboard.menu.home'
  public static readonly DASHBOARD_MENU_RESULTS = 'dashboard.menu.results'
  public static readonly DASHBOARD_MENU_COURSES = 'dashboard.menu.courses'
  public static readonly DASHBOARD_MENU_CAMPAIGNS = 'dashboard.menu.campaigns'
  public static readonly DASHBOARD_MENU_USERS = 'dashboard.menu.users'
  public static readonly DASHBOARD_MENU_LOGOUT = 'dashboard.menu.logout'
  public static readonly DASHBOARD_MENU_BACKFIFTY = 'dashboard.menu.backFifty'
  public static readonly DASHBOARD_MENU_HELP = 'dashboard.menu.help'
  public static readonly ENDOFCOURSECARD_TITLE = 'endOfCourseCard.title'
  public static readonly ENDOFCOURSECARD_TITLEWITHOUTLASTCOURSE = 'endOfCourseCard.titleWithoutLastCourse'
  public static readonly ENDOFCOURSECARD_SUBTITLE = 'endOfCourseCard.subtitle'
  public static readonly ENDOFCOURSECARD_CARDS_VALIDATECHALLENGE_TITLE = 'endOfCourseCard.cards.validateChallenge.title'
  public static readonly ENDOFCOURSECARD_CARDS_VALIDATECHALLENGE_SUBTITLE =
    'endOfCourseCard.cards.validateChallenge.subtitle'

  public static readonly ENDOFCOURSECARD_CARDS_CREATEHABIT_TITLE = 'endOfCourseCard.cards.createHabit.title'
  public static readonly ENDOFCOURSECARD_CARDS_CREATEHABIT_SUBTITLE = 'endOfCourseCard.cards.createHabit.subtitle'
  public static readonly ENDOFCOURSECARD_CARDS_CREATECHALLENGE_TITLE = 'endOfCourseCard.cards.createChallenge.title'
  public static readonly ENDOFCOURSECARD_CARDS_CREATECHALLENGE_SUBTITLE =
    'endOfCourseCard.cards.createChallenge.subtitle'

  public static readonly ENDOFCOURSECARD_CARDS_NEWCOURSE_TITLE = 'endOfCourseCard.cards.newCourse.title'
  public static readonly ENDOFCOURSECARD_CARDS_NEWCOURSE_SUBTITLE = 'endOfCourseCard.cards.newCourse.subtitle'
  public static readonly CUSTOMCHALLENGEMODAL_TITLE = 'customChallengeModal.title'
  public static readonly CUSTOMCHALLENGEMODAL_REQUIREDFIELDS = 'customChallengeModal.requiredFields'
  public static readonly CUSTOMCHALLENGEMODAL_BANNER_DRAFT = 'customChallengeModal.banner.draft'
  public static readonly CUSTOMCHALLENGEMODAL_BANNER_REVIEW = 'customChallengeModal.banner.review'
  public static readonly CUSTOMCHALLENGEMODAL_HELP_TITLE = 'customChallengeModal.help.title'
  public static readonly CUSTOMCHALLENGEMODAL_HELP_ADVICE1 = 'customChallengeModal.help.advice1'
  public static readonly CUSTOMCHALLENGEMODAL_HELP_ADVICE2 = 'customChallengeModal.help.advice2'
  public static readonly CUSTOMCHALLENGEMODAL_HELP_ADVICE3 = 'customChallengeModal.help.advice3'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_PILAR = 'customChallengeModal.action.pilar'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_DURATION = 'customChallengeModal.action.duration'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_TITLE_PLACEHOLDER = 'customChallengeModal.action.title.placeholder'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_TITLE_LABEL = 'customChallengeModal.action.title.label'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_CONTEXT_PLACEHOLDER =
    'customChallengeModal.action.context.placeholder'

  public static readonly CUSTOMCHALLENGEMODAL_ACTION_CONTEXT_LABEL = 'customChallengeModal.action.context.label'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_ADVICE_PLACEHOLDER =
    'customChallengeModal.action.advice.placeholder'

  public static readonly CUSTOMCHALLENGEMODAL_ACTION_ADVICE_LABEL = 'customChallengeModal.action.advice.label'
  public static readonly CUSTOMCHALLENGEMODAL_ACTION_PROFILES = 'customChallengeModal.action.profiles'
  public static readonly CUSTOMCHALLENGEMODAL_DELETEPOPUP_TITLE = 'customChallengeModal.deletePopup.title'
  public static readonly CUSTOMCHALLENGEMODAL_DELETEPOPUP_CANCEL = 'customChallengeModal.deletePopup.cancel'
  public static readonly CUSTOMCHALLENGEMODAL_DELETEPOPUP_CONFIRM = 'customChallengeModal.deletePopup.confirm'
  public static readonly CUSTOMCHALLENGEMODAL_SAVE_TOOLTIP = 'customChallengeModal.save.tooltip'
  public static readonly CUSTOMCHALLENGEMODAL_SAVE_SHORTLABEL = 'customChallengeModal.save.shortLabel'
  public static readonly CUSTOMCHALLENGEMODAL_SAVE_SAVEASDRAFT = 'customChallengeModal.save.saveAsDraft'
  public static readonly CUSTOMCHALLENGEMODAL_SAVE_TOASTSAVED = 'customChallengeModal.save.toastSaved'
  public static readonly CUSTOMCHALLENGEMODAL_SAVE_TOASTDRAFTSAVED = 'customChallengeModal.save.toastDraftSaved'
  public static readonly CUSTOMCHALLENGEMODAL_CREATE_TOOLTIP = 'customChallengeModal.create.tooltip'
  public static readonly CUSTOMCHALLENGEMODAL_CREATE_BTNLABEL = 'customChallengeModal.create.btnLabel'
  public static readonly CUSTOMCHALLENGEMODAL_CREATE_TOASTSUCCESS = 'customChallengeModal.create.toastSuccess'
  public static readonly CUSTOMCHALLENGEMODAL_INANOTHERPROGRAM = 'customChallengeModal.inAnotherProgram'
  public static readonly GLOBAL_FIFTYCOMMENTSINDICATOR_UNREAD = 'global.fiftyCommentsIndicator.unread'
  public static readonly GLOBAL_MAXCHARS = 'global.maxChars'
  public static readonly GLOBAL_CANCEL = 'global.cancel'
  public static readonly GLOBAL_CONFIRM = 'global.confirm'
  public static readonly GLOBAL_RETURN = 'global.return'
  public static readonly GLOBAL_DAYS = 'global.days'
  public static readonly GLOBAL_WEEKS = 'global.weeks'
  public static readonly GLOBAL_MINCHARS = 'global.minChars'
  public static readonly GLOBAL_CONTACTUS_LABEL = 'global.contactUs.label'
  public static readonly ADMIN_HOME_QUICKACCESS = 'admin.home.quickAccess'
  public static readonly ADMIN_HOME_RESULTSCARD_TITLE = 'admin.home.resultsCard.title'
  public static readonly ADMIN_HOME_RESULTSCARD_CONTENT = 'admin.home.resultsCard.content'
  public static readonly ADMIN_HOME_ADDUSER_TITLE = 'admin.home.addUser.title'
  public static readonly ADMIN_HOME_ADDUSER_CONTENT = 'admin.home.addUser.content'
  public static readonly ADMIN_HOME_PROGRAMS_TITLE = 'admin.home.programs.title'
  public static readonly ADMIN_HOME_PROGRAMS_CREATEPOPUP_TITLE = 'admin.home.programs.createPopup.title'
  public static readonly ADMIN_HOME_PROGRAMS_CREATEPOPUP_INPUTPLACEHOLDER =
    'admin.home.programs.createPopup.inputPlaceholder'

  public static readonly ADMIN_HOME_PROGRAMS_CREATEPOPUP_CANCELBTN = 'admin.home.programs.createPopup.cancelBtn'
  public static readonly ADMIN_HOME_PROGRAMS_CREATEPOPUP_CREATEBTN = 'admin.home.programs.createPopup.createBtn'
  public static readonly ADMIN_HOME_PROGRAMS_CREATEPROGRAMBTN = 'admin.home.programs.createProgramBtn'
  public static readonly ADMIN_HOME_PROGRAMS_EMPTYBTN = 'admin.home.programs.emptyBtn'
  public static readonly ADMIN_HOME_PROGRAMS_TOASTER_ALREADYEXISTS = 'admin.home.programs.toaster.alreadyExists'
  public static readonly ADMIN_HOME_PROGRAMS_TOASTER_SERVERERROR = 'admin.home.programs.toaster.serverError'
  public static readonly ADMIN_HOME_PROGRAMS_SUBTITLE = 'admin.home.programs.subtitle'
  public static readonly ADMIN_HOME_PROGRAMS_NOPROGRAM = 'admin.home.programs.noProgram'
  public static readonly ADMIN_HOME_PROGRAMS_NOCONNECTED = 'admin.home.programs.no-connected'
  public static readonly ADMIN_HOME_PROGRAMS_DEFAULTNAME = 'admin.home.programs.defaultName'
  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_ACTIONS_READY = 'admin.home.programs.statuses.actions.ready'
  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_ACTIONS_NOTREADY = 'admin.home.programs.statuses.actions.notReady'
  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_CAMPAIGN_READY = 'admin.home.programs.statuses.campaign.ready'
  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_CAMPAIGN_NOTREADY =
    'admin.home.programs.statuses.campaign.notReady'

  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_ANALYZE_READY = 'admin.home.programs.statuses.analyze.ready'
  public static readonly ADMIN_HOME_PROGRAMS_STATUSES_ANALYZE_NOTREADY = 'admin.home.programs.statuses.analyze.notReady'
  public static readonly ADMIN_HOME_ACTIONREPOSITORY_TITLE = 'admin.home.actionRepository.title'
  public static readonly ADMIN_HOME_ACTIONREPOSITORY_CONTENT = 'admin.home.actionRepository.content'
  public static readonly ADMIN_HOME_HELLO = 'admin.home.hello'
  public static readonly ADMIN_HOME_ARCHIVEDPROGRAMS_LINK = 'admin.home.archivedPrograms.link'
  public static readonly ADMIN_MENU_HOME = 'admin.menu.home'
  public static readonly ADMIN_MENU_PROGRAMS = 'admin.menu.programs'
  public static readonly ADMIN_MENU_RESULTS = 'admin.menu.results'
  public static readonly ADMIN_MENU_ACCOUNT = 'admin.menu.account'
  public static readonly ADMIN_MENU_USERS = 'admin.menu.users'
  public static readonly ADMIN_MENU_CROSSPROGRAMS = 'admin.menu.crossPrograms'
  public static readonly ADMIN_MENU_ADMINISTRATION = 'admin.menu.administration'
  public static readonly ADMIN_MENU_PROGRAMSSUBMENU = 'admin.menu.programsSubmenu'
  public static readonly ADMIN_MENU_PROFILE = 'admin.menu.profile'
  public static readonly ADMIN_MENU_COMPARESEGMENTS = 'admin.menu.compareSegments'
  public static readonly ADMIN_MENU_CONNECTNEWPROGRAM = 'admin.menu.connect-new-program'
  public static readonly ADMIN_PROGRAMS_SETTINGSBTN = 'admin.programs.settingsBtn'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_TITLE = 'admin.programs.settingsModal.title'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_PROGRAMNAME = 'admin.programs.settingsModal.programName'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_PROGRAMDURATION = 'admin.programs.settingsModal.programDuration'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_SEGMENT = 'admin.programs.settingsModal.segment'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_TOASTER_SAVED = 'admin.programs.settingsModal.toaster.saved'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_TOASTER_ERROR = 'admin.programs.settingsModal.toaster.error'
  public static readonly ADMIN_PROGRAMS_SETTINGSMODAL_SAVE = 'admin.programs.settingsModal.save'
  public static readonly ADMIN_PROGRAMS_MENU_ACTIONS = 'admin.programs.menu.actions'
  public static readonly ADMIN_PROGRAMS_MENU_COMMUNICATION = 'admin.programs.menu.communication'
  public static readonly ADMIN_PROGRAMS_MENU_CAMPAIGNS = 'admin.programs.menu.campaigns'
  public static readonly ADMIN_PROGRAMS_MENU_KPI = 'admin.programs.menu.kpi'
  public static readonly ADMIN_PROGRAMS_ACTIONS_TITLE = 'admin.programs.actions.title'
  public static readonly ADMIN_PROGRAMS_ACTIONS_WAITINGSECTION_TITLE = 'admin.programs.actions.waitingSection.title'
  public static readonly ADMIN_PROGRAMS_ACTIONS_WAITINGSECTION_SUBTITLE =
    'admin.programs.actions.waitingSection.subtitle'

  public static readonly ADMIN_PROGRAMS_ACTIONS_GENERATION_FAILURE = 'admin.programs.actions.generation.failure'
  public static readonly ADMIN_PROGRAMS_ACTIONS_NOACTIONS_TITLE = 'admin.programs.actions.noActions.title'
  public static readonly ADMIN_PROGRAMS_ACTIONS_NOACTIONS_GENERATE = 'admin.programs.actions.noActions.generate'
  public static readonly ADMIN_PROGRAMS_ACTIONS_NOACTIONS_INFORMATION = 'admin.programs.actions.noActions.information'
  public static readonly ADMIN_PROGRAMS_ACTIONS_TOTREAT_CHIP = 'admin.programs.actions.toTreat.chip'
  public static readonly ADMIN_PROGRAMS_ACTIONS_VALIDATED_CHIP = 'admin.programs.actions.validated.chip'
  public static readonly ADMIN_PROGRAMS_ACTIONS_MOVED_SUCCESS = 'admin.programs.actions.moved.success'
  public static readonly ADMIN_PROGRAMS_ACTIONS_MOVED_ERROR = 'admin.programs.actions.moved.error'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_TITLE = 'admin.programs.communication.title'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_SUBTITLE = 'admin.programs.communication.subtitle'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_PROGRAMNAMEINAPP = 'admin.programs.communication.programNameInApp'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_PROGRAMNAMEINEMAILS =
    'admin.programs.communication.programNameInEmails'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGTITLE = 'admin.programs.communication.onboardingTitle'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGPREVIZBTN =
    'admin.programs.communication.onboardingPrevizBtn'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGINTRO = 'admin.programs.communication.onboardingIntro'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_EMAILTITLE = 'admin.programs.communication.emailTitle'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_SENDTESTEMAILBTN = 'admin.programs.communication.sendTestEmailBtn'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_EMAILCONTENT = 'admin.programs.communication.emailContent'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_PREVIZMODAL_TITLE =
    'admin.programs.communication.previzModal.title'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_PREVIZMODAL_SUBTITLE =
    'admin.programs.communication.previzModal.subtitle'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_TOASTER_EMAILSENT =
    'admin.programs.communication.toaster.emailSent'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_TOASTER_SERVERERROR =
    'admin.programs.communication.toaster.serverError'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_EMAILSUBJECT = 'admin.programs.communication.emailSubject'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_COMPANYDISPLAYNAME =
    'admin.programs.communication.companyDisplayName'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_OBPREVIEW_DONE_TITLE =
    'admin.programs.communication.obPreview.done.title'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_OBPREVIEW_DONE_SUBTITLE =
    'admin.programs.communication.obPreview.done.subtitle'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_OBPREVIEW_DONE_RETRYBTN =
    'admin.programs.communication.obPreview.done.retryBtn'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_OBPREVIEW_DONE_DONEBTN =
    'admin.programs.communication.obPreview.done.doneBtn'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_OBPREVIEW_TESTBTN =
    'admin.programs.communication.obPreview.testBtn'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGDESCRIPTION =
    'admin.programs.communication.onboardingDescription'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGINTRODUCTION =
    'admin.programs.communication.onboardingIntroduction'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_ONBOARDINGLOGO = 'admin.programs.communication.onboardingLogo'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_CLIENTDISPLAYNAME =
    'admin.programs.communication.clientDisplayName'

  public static readonly ADMIN_PROGRAMS_COMMUNICATION_TITLEV2 = 'admin.programs.communication.titlev2'
  public static readonly ADMIN_PROGRAMS_COMMUNICATION_CONTEXTS_TITLE = 'admin.programs.communication.contexts.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TITLE = 'admin.programs.campaigns.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATECAMPAIGNBTN = 'admin.programs.campaigns.createCampaignBtn'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_WAITING_TITLE = 'admin.programs.campaigns.waiting.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_EMPTY_TITLE = 'admin.programs.campaigns.empty.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_EMPTY_SUBTITLE = 'admin.programs.campaigns.empty.subtitle'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_EMPTY_FUTURE = 'admin.programs.campaigns.empty.future'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_EMPTY_CURRENT = 'admin.programs.campaigns.empty.current'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_EMPTY_PAST = 'admin.programs.campaigns.empty.past'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_TITLE = 'admin.programs.campaigns.createModal.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_CREATEBTN =
    'admin.programs.campaigns.createModal.createBtn'

  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_NAME = 'admin.programs.campaigns.createModal.name'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_STARTAT = 'admin.programs.campaigns.createModal.startAt'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_TIMEZONES =
    'admin.programs.campaigns.createModal.timezones'

  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_CREATEMODAL_STARTTIMEAT =
    'admin.programs.campaigns.createModal.startTimeAt'

  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABLE_NAME = 'admin.programs.campaigns.table.name'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABLE_STARTANDEND = 'admin.programs.campaigns.table.startAndEnd'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABLE_PARTICIPANTS = 'admin.programs.campaigns.table.participants'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABLE_ACTIONS = 'admin.programs.campaigns.table.actions'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABLE_DETAILS = 'admin.programs.campaigns.table.details'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TOASTER_SERVERERROR = 'admin.programs.campaigns.toaster.serverError'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TOASTER_DUPLICATES = 'admin.programs.campaigns.toaster.duplicates'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_UPDATEMODAL_TITLE = 'admin.programs.campaigns.updateModal.title'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABS_CURRENT_LABEL = 'admin.programs.campaigns.tabs.current.label'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABS_FUTURE_LABEL = 'admin.programs.campaigns.tabs.future.label'
  public static readonly ADMIN_PROGRAMS_CAMPAIGNS_TABS_PAST_LABEL = 'admin.programs.campaigns.tabs.past.label'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_ISTRAININGOREVENT_TITLE =
    'admin.programs.contexts.isTrainingOrEvent.title'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_ISTRAININGOREVENT_DETAILS_1 =
    'admin.programs.contexts.isTrainingOrEvent.details.1'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_ISTRAININGOREVENT_DETAILS_2 =
    'admin.programs.contexts.isTrainingOrEvent.details.2'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DIRECTLYINTOACTION_DETAILS_1 =
    'admin.programs.contexts.directlyIntoAction.details.1'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DIRECTLYINTOACTION_DETAILS_2 =
    'admin.programs.contexts.directlyIntoAction.details.2'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DIRECTLYINTOACTION_TITLE =
    'admin.programs.contexts.directlyIntoAction.title'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DEPLOYBTN = 'admin.programs.contexts.deployBtn'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_GOTOPAGE = 'admin.programs.contexts.goToPage'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_BESTPRACTICES_TITLE = 'admin.programs.contexts.bestPractices.title'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_RECOMMENDATIONS_TITLE = 'admin.programs.contexts.recommendations.title'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_RECOMMENDATIONS_ERROR = 'admin.programs.contexts.recommendations.error'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_FILES_TITLE = 'admin.programs.contexts.files.title'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_FILES_NOFILE = 'admin.programs.contexts.files.nofile'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETE = 'admin.programs.contexts.delete'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETEMODAL_TITLE = 'admin.programs.contexts.deleteModal.title'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETEMODAL_DESCRIPTION =
    'admin.programs.contexts.deleteModal.description'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETEMODAL_CANCEL = 'admin.programs.contexts.deleteModal.cancel'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETEMODAL_CONFIRM = 'admin.programs.contexts.deleteModal.confirm'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_FILE_DOWNLOAD_ERROR = 'admin.programs.contexts.file.download.error'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DEPLOY_ERROR = 'admin.programs.contexts.deploy.error'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_TABS_BEFORE_LAUNCH = 'admin.programs.contexts.tabs.before_launch'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_TABS_DURING_LAUNCH = 'admin.programs.contexts.tabs.during_launch'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_TABS_AFTER_LAUNCH = 'admin.programs.contexts.tabs.after_launch'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DOWNLOAD_RELATED_FILES =
    'admin.programs.contexts.download_related_files'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DOWNLOAD_ALL_FILES = 'admin.programs.contexts.download_all_files'
  public static readonly ADMIN_PROGRAMS_CONTEXTS_DOWNLOAD_BESTPRACTICES_FILES =
    'admin.programs.contexts.download_bestPractices_files'

  public static readonly ADMIN_PROGRAMS_CONTEXTS_DELETEERROR = 'admin.programs.contexts.deleteError'
  public static readonly ADMIN_PROGRAMS_EXPERIENCE_TITLE = 'admin.programs.experience.title'
  public static readonly ADMIN_PROGRAMS_EXPERIENCE_SUBTITLE = 'admin.programs.experience.subtitle'
  public static readonly ADMIN_PROGRAMS_EXPERIENCE_CUSTOMIZE = 'admin.programs.experience.customize'
  public static readonly ADMIN_PROGRAMS_COMMUNICATIONEXPERIENCE_EMAILTITLE =
    'admin.programs.communicationExperience.emailTitle'

  public static readonly ADMIN_PROGRAMS_COMMUNICATIONEXPERIENCE_ONBOARDINGTITLE =
    'admin.programs.communicationExperience.onboardingTitle'

  public static readonly ADMIN_PROGRAMS_COMMUNICATIONEXPERIENCE_COLLABORATORSTITLE =
    'admin.programs.communicationExperience.collaboratorsTitle'

  public static readonly ADMIN_PROGRAMS_TITLE = 'admin.programs.title'
  public static readonly ADMIN_PROGRAMS_PILLARS_EMPTY = 'admin.programs.pillars.empty'
  public static readonly ADMIN_PROGRAMS_PILLARS_CREATED_SUCCESS = 'admin.programs.pillars.created.success'
  public static readonly ADMIN_PROGRAMS_PILLARS_CREATED_ERROR = 'admin.programs.pillars.created.error'
  public static readonly ADMIN_PROGRAMS_PILLARS_UPDATED_SUCCESS = 'admin.programs.pillars.updated.success'
  public static readonly ADMIN_PROGRAMS_PILLARS_UPDATED_ERROR = 'admin.programs.pillars.updated.error'
  public static readonly ADMIN_PROGRAMS_PILLARS_DELETED_SUCCESS = 'admin.programs.pillars.deleted.success'
  public static readonly ADMIN_PROGRAMS_PILLARS_DELETED_ERROR = 'admin.programs.pillars.deleted.error'
  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_TITLE_NEW = 'admin.programs.pillars.pillarModal.title.new'
  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_TITLE_UPDATE =
    'admin.programs.pillars.pillarModal.title.update'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_NAME_LABEL = 'admin.programs.pillars.pillarModal.name.label'
  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_NAME_PLACEHOLDER =
    'admin.programs.pillars.pillarModal.name.placeholder'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_DESCRIPTION_LABEL =
    'admin.programs.pillars.pillarModal.description.label'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_DESCRIPTION_PLACEHOLDER =
    'admin.programs.pillars.pillarModal.description.placeholder'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_DELETE_LABEL =
    'admin.programs.pillars.pillarModal.actions.delete.label'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_DELETE_TOOLTIP =
    'admin.programs.pillars.pillarModal.actions.delete.tooltip'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_DELETE_CONFIRM =
    'admin.programs.pillars.pillarModal.actions.delete.confirm'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_EDIT_LABEL =
    'admin.programs.pillars.pillarModal.actions.edit.label'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_EDIT_TOOLTIP =
    'admin.programs.pillars.pillarModal.actions.edit.tooltip'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_CREATE_LABEL =
    'admin.programs.pillars.pillarModal.actions.create.label'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_CREATE_TOOLTIP =
    'admin.programs.pillars.pillarModal.actions.create.tooltip'

  public static readonly ADMIN_PROGRAMS_PILLARS_PILLARMODAL_ACTIONS_CREATEANDGENERATE_LABEL =
    'admin.programs.pillars.pillarModal.actions.createAndGenerate.label'

  public static readonly ADMIN_PROGRAMS_PILLARS_EMPTYACTIONS_FIRSTLINE = 'admin.programs.pillars.emptyActions.firstLine'
  public static readonly ADMIN_PROGRAMS_PILLARS_EMPTYACTIONS_SECONDLINE =
    'admin.programs.pillars.emptyActions.secondLine'

  public static readonly ADMIN_PROGRAMS_PILLARS_CREATINGACTIONS_INFO = 'admin.programs.pillars.creatingActions.info'
  public static readonly ADMIN_PROGRAMS_PILLARS_CREATINGACTIONS_ERROR = 'admin.programs.pillars.creatingActions.error'
  public static readonly ADMIN_PROGRAMS_PILLARS_CREATINGACTIONS_SUCCESS =
    'admin.programs.pillars.creatingActions.success'

  public static readonly ADMIN_PROGRAMS_PILLARS_CREATINGACTIONS_NONEADDED =
    'admin.programs.pillars.creatingActions.noneAdded'

  public static readonly ADMIN_PROGRAMS_STATUS_ALL = 'admin.programs.status.all'
  public static readonly ADMIN_PROGRAMS_STATUS_ACTIVE = 'admin.programs.status.active'
  public static readonly ADMIN_PROGRAMS_STATUS_ARCHIVED = 'admin.programs.status.archived'
  public static readonly ADMIN_PROGRAMS_SEEALL = 'admin.programs.seeAll'
  public static readonly ADMIN_PROGRAMS_SEARCH_PLACEHOLDER = 'admin.programs.search.placeholder'
  public static readonly ADMIN_ACCOUNT_TITLE = 'admin.account.title'
  public static readonly ADMIN_ACCOUNT_MAIN_TITLE = 'admin.account.main.title'
  public static readonly ADMIN_ACCOUNT_MAIN_FIRSTNAME = 'admin.account.main.firstName'
  public static readonly ADMIN_ACCOUNT_MAIN_EMAIL = 'admin.account.main.email'
  public static readonly ADMIN_ACCOUNT_MAIN_LASTNAME = 'admin.account.main.lastName'
  public static readonly ADMIN_ACCOUNT_MAIN_ROLE = 'admin.account.main.role'
  public static readonly ADMIN_ACCOUNT_OTHERS_TITLE = 'admin.account.others.title'
  public static readonly ADMIN_ACCOUNT_OTHERS_ROLE = 'admin.account.others.role'
  public static readonly ADMIN_ACCOUNT_OTHERS_TIMEZONE = 'admin.account.others.Timezone'
  public static readonly ADMIN_ACCOUNT_LANGUAGE_TITLE = 'admin.account.language.title'
  public static readonly ADMIN_ACCOUNT_LANGUAGE_LANGUAGE = 'admin.account.language.language'
  public static readonly ADMIN_ACCOUNT_LOGOUT_TITLE = 'admin.account.logout.title'
  public static readonly ADMIN_ACCOUNT_LOGOUT_BUTTON = 'admin.account.logout.button'
  public static readonly ADMIN_ACCOUNT_SAVE_BUTTON = 'admin.account.save.button'
  public static readonly ADMIN_ACCOUNT_SAVE_SUCCESS = 'admin.account.save.success'
  public static readonly ADMIN_ACCOUNT_SAVE_ERROR = 'admin.account.save.error'
  public static readonly ADMIN_ACCOUNT_ERRORS_INPUTREQUIRED = 'admin.account.errors.inputRequired'
  public static readonly ADMIN_ACCOUNT_ERRORS_FETCHUSERDATA = 'admin.account.errors.fetchUserData'
  public static readonly ADMIN_ACCOUNT_ERRORS_FETCHTIMEZONES = 'admin.account.errors.fetchTimezones'
  public static readonly ADMIN_ACCOUNT_ERRORS_FETCHCAMPAIGNS = 'admin.account.errors.fetchCampaigns'
  public static readonly ADMIN_ACCOUNT_RESET_TITLE = 'admin.account.reset.title'
  public static readonly ADMIN_ACCOUNT_RESET_BUTTON = 'admin.account.reset.button'
  public static readonly ADMIN_ACCOUNT_RESET_SELECT_LABEL = 'admin.account.reset.select_label'
  public static readonly ADMIN_ACCOUNT_RESET_CURRENT_CAMPAIGN = 'admin.account.reset.current_campaign'
  public static readonly ADMIN_ACCOUNT_RESET_NOTENOUGHCAMPAIGNS = 'admin.account.reset.notEnoughCampaigns'
  public static readonly ADMIN_ACCOUNT_RESET_DESCRIPTION = 'admin.account.reset.description'
  public static readonly ADMIN_ACCOUNT_RESET_CONFIRMTEXT = 'admin.account.reset.confirmText'
  public static readonly ADMIN_USERPAGE_SEARCH_NORESULT_TITLE = 'admin.userPage.search.noResult.title'
  public static readonly ADMIN_USERPAGE_SEARCH_NORESULT_SUBTITLE = 'admin.userPage.search.noResult.subtitle'
  public static readonly ADMIN_USERPAGE_ADDADMINBTN_LABEL = 'admin.userPage.addAdminBtn.label'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_FIRSTNAME = 'admin.userPage.datagridColumn.firstName'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_LASTNAME = 'admin.userPage.datagridColumn.lastName'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_ROLE = 'admin.userPage.datagridColumn.role'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_EMAIL = 'admin.userPage.datagridColumn.email'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_ACTIONS = 'admin.userPage.datagridColumn.actions'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_PROGRAMS = 'admin.userPage.datagridColumn.programs'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_ALLPROGRAMS = 'admin.userPage.datagridColumn.allPrograms'
  public static readonly ADMIN_USERPAGE_DATAGRIDCOLUMN_PROGRAMSCOUNT = 'admin.userPage.datagridColumn.programsCount'
  public static readonly ADMIN_USERPAGE_DOWNLOADPARTICIPANTS = 'admin.userPage.downloadParticipants'
  public static readonly ADMIN_USERPAGE_ACTIONS_USERDETAILLINK = 'admin.userPage.actions.userDetailLink'
  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONPROGRAMPREPARATION =
    'admin.program.overview.section-program-preparation'

  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONPROGRAMMONITORING =
    'admin.program.overview.section-program-monitoring'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDMICROACTIONS_TITLE =
    'admin.program.overview.card-micro-actions.title'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDMICROACTIONS_DESCRIPTION =
    'admin.program.overview.card-micro-actions.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDMETRICS_TITLE = 'admin.program.overview.card-metrics.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDMETRICS_DESCRIPTION =
    'admin.program.overview.card-metrics.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDCAMPAIGNS_TITLE = 'admin.program.overview.card-campaigns.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDCAMPAIGNS_DESCRIPTION =
    'admin.program.overview.card-campaigns.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDCOMMUNICATION_TITLE =
    'admin.program.overview.card-communication.title'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDCOMMUNICATION_DESCRIPTION =
    'admin.program.overview.card-communication.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_BANNER_TITLE = 'admin.program.overview.banner.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_BANNER_SUBTITLE = 'admin.program.overview.banner.subtitle'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDPOPULATION_TITLE = 'admin.program.overview.card-population.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDPOPULATION_DESCRIPTION =
    'admin.program.overview.card-population.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_HEADER_TITLE = 'admin.program.overview.header.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_HEADER_SUBTITLE = 'admin.program.overview.header.subtitle'
  public static readonly ADMIN_PROGRAM_OVERVIEW_HEADER_ARCHIVED = 'admin.program.overview.header.archived'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDOVERVIEW_TITLE = 'admin.program.overview.card-overview.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_FIRSTBRICK_TITLE = 'admin.program.overview.firstBrick.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_SECONDBRICK_TITLE = 'admin.program.overview.secondBrick.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_THIRDBRICK_TITLE = 'admin.program.overview.thirdBrick.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_GOTOBUTTON = 'admin.program.overview.goToButton'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDINFORMATION_DESCRIPTION =
    'admin.program.overview.card-information.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDINFORMATION_TITLE = 'admin.program.overview.card-information.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDINFORMATIONS_TITLE =
    'admin.program.overview.card-informations.title'

  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDSEGMENTS_TITLE = 'admin.program.overview.card-segments.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_CARDSEGMENTS_DESCRIPTION =
    'admin.program.overview.card-segments.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_FIRST_TITLE = 'admin.program.overview.sections.first.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_FIRST_DESCRIPTION =
    'admin.program.overview.sections.first.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_SECOND_TITLE = 'admin.program.overview.sections.second.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_SECOND_DESCRIPTION =
    'admin.program.overview.sections.second.description'

  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_THIRD_TITLE = 'admin.program.overview.sections.third.title'
  public static readonly ADMIN_PROGRAM_OVERVIEW_SECTIONS_THIRD_DESCRIPTION =
    'admin.program.overview.sections.third.description'

  public static readonly ADMIN_PROGRAM_POPULATION_NOTES_TEXTAREA_PLACEHOLDER =
    'admin.program.population.notes.textarea.placeholder'

  public static readonly ADMIN_PROGRAM_POPULATION_NOTES_TITLE = 'admin.program.population.notes.title'
  public static readonly ADMIN_PROGRAM_POPULATION_MANAGERPROFILES_TITLE =
    'admin.program.population.manager-profiles.title'

  public static readonly ADMIN_PROGRAM_POPULATION_MANAGERPROFILES_SUBTITLE =
    'admin.program.population.manager-profiles.subtitle'

  public static readonly ADMIN_PROGRAM_POPULATION_MANAGERPROFILES_TITLEV2 =
    'admin.program.population.manager-profiles.titlev2'

  public static readonly ADMIN_PROGRAM_POPULATION_FETCHPOPULATIONDETAILSERROR =
    'admin.program.population.fetch-population-details-error'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_TITLE = 'admin.program.population.segments.title'
  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_SUBTITLE = 'admin.program.population.segments.subtitle'
  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_YOURSEGMENTS =
    'admin.program.population.segments.your-segments'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_DEFAULTSEGMENTS_YEAR =
    'admin.program.population.segments.default-segments.year'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_DEFAULTSEGMENTS_CAMPAIGN =
    'admin.program.population.segments.default-segments.campaign'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_DEFAULTSEGMENTS_MANAGERPROFILES =
    'admin.program.population.segments.default-segments.manager-profiles'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_DEFAULTSEGMENTS_TITLE =
    'admin.program.population.segments.default-segments.title'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_DEFAULTSEGMENTS_MANAGERPROFILESV2 =
    'admin.program.population.segments.default-segments.manager-profilesv2'

  public static readonly ADMIN_PROGRAM_POPULATION_SEGMENTS_EMPTYCUSTOM = 'admin.program.population.segments.emptyCustom'
  public static readonly ADMIN_PROGRAM_POPULATION_PROFILES_TITLE = 'admin.program.population.profiles.title'
  public static readonly ADMIN_PROGRAM_POPULATION_PROFILES_SUBTITLE = 'admin.program.population.profiles.subtitle'
  public static readonly ADMIN_PROGRAM_POPULATION_HEADER_TITLE = 'admin.program.population.header.title'
  public static readonly ADMIN_PROGRAM_POPULATION_HEADER_CREATEDAT = 'admin.program.population.header.createdAt'
  public static readonly ADMIN_PROGRAM_POPULATION_TITLE = 'admin.program.population.title'
  public static readonly ADMIN_PROGRAM_MICROACTIONS_NOPROFILEBANNER_TITLE =
    'admin.program.micro-actions.no-profile-banner.title'

  public static readonly ADMIN_PROGRAM_MICROACTIONS_NOPROFILEBANNER_CONTENT =
    'admin.program.micro-actions.no-profile-banner.content'

  public static readonly ADMIN_PROGRAM_MICROACTIONS_NOPROFILEBANNER_CTA =
    'admin.program.micro-actions.no-profile-banner.cta'

  public static readonly ADMIN_PROGRAM_COMMUNICATION_HEADER_TITLE = 'admin.program.communication.header.title'
  public static readonly ADMIN_PROGRAM_COMMUNICATION_HEADER_TITLEV2 = 'admin.program.communication.header.titlev2'
  public static readonly ADMIN_PROGRAM_CAMPAIGNS_HEADER_TITLE = 'admin.program.campaigns.header.title'
  public static readonly ADMIN_PROGRAM_KPI_HEADER_TITLE = 'admin.program.kpi.header.title'
  public static readonly ADMIN_PROGRAM_ACTIONSREVIEW_HEADER_TITLE = 'admin.program.actionsReview.header.title'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_ABOUTPROGRAM_TITLE = 'admin.program.informations.aboutProgram.title'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_ABOUTPROGRAM_NAMEYOURPROGRAM =
    'admin.program.informations.aboutProgram.nameYourProgram'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_ABOUTPROGRAM_DESCRIBEYOURPROGRAM =
    'admin.program.informations.aboutProgram.describeYourProgram'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_ABOUTPROGRAM_DESCRIBEYOURPROGRAMPLACEHOLDER =
    'admin.program.informations.aboutProgram.describeYourProgramPlaceholder'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILLINGHELP = 'admin.program.informations.fillingHelp'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_TITLE =
    'admin.program.informations.structureProgram.title'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_DESCRIPTION =
    'admin.program.informations.structureProgram.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_PROGRAMNAME =
    'admin.program.informations.structureProgram.programName'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_PILAR_TITLE =
    'admin.program.informations.structureProgram.pilar.title'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_PILAR_NAME =
    'admin.program.informations.structureProgram.pilar.name'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_STRUCTUREPROGRAM_PILAR_DESCRIBEIT =
    'admin.program.informations.structureProgram.pilar.describeIt'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_SAVEANDQUIT = 'admin.program.informations.saveAndQuit'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_GOTOACTIONS = 'admin.program.informations.goToActions'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILLINGHELPMODAL_ABOUTPROGRAM_CONTENT =
    'admin.program.informations.fillingHelpModal.aboutProgram.content'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILLINGHELPMODAL_STRUCTUREPROGRAM_CONTENT =
    'admin.program.informations.fillingHelpModal.structureProgram.content'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILLINGHELPMODAL_DESCRIPTION =
    'admin.program.informations.fillingHelpModal.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_SAVEMODAL_NOSAVE = 'admin.program.informations.saveModal.noSave'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_SAVEMODAL_SAVE = 'admin.program.informations.saveModal.save'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_SAVEMODAL_BODY = 'admin.program.informations.saveModal.body'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_SAVEMODAL_TITLE = 'admin.program.informations.saveModal.title'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR1_NAME =
    'admin.program.informations.autoFillPillars.pillar1.name'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR1_DESCRIPTION =
    'admin.program.informations.autoFillPillars.pillar1.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR2_NAME =
    'admin.program.informations.autoFillPillars.pillar2.name'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR2_DESCRIPTION =
    'admin.program.informations.autoFillPillars.pillar2.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR3_NAME =
    'admin.program.informations.autoFillPillars.pillar3.name'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR3_DESCRIPTION =
    'admin.program.informations.autoFillPillars.pillar3.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR4_NAME =
    'admin.program.informations.autoFillPillars.pillar4.name'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_AUTOFILLPILLARS_PILLAR4_DESCRIPTION =
    'admin.program.informations.autoFillPillars.pillar4.description'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILES_TITLE = 'admin.program.informations.files.title'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILE_DRAG_DROP = 'admin.program.informations.file.drag_drop'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILE_ACCEPTED_EXTENSIONS =
    'admin.program.informations.file.accepted_extensions'

  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILE_SCANNING = 'admin.program.informations.file.scanning'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILE_INFECTED = 'admin.program.informations.file.infected'
  public static readonly ADMIN_PROGRAM_INFORMATIONS_FILE_DOWNLOAD = 'admin.program.informations.file.download'
  public static readonly ADMIN_PROGRAM_PILARS_ADDPILARCTA = 'admin.program.pilars.addPilarCTA'
  public static readonly ADMIN_PROGRAM_PILARS_AUTOFILLPILLARCTA = 'admin.program.pilars.autoFillPillarCTA'
  public static readonly ADMIN_PROGRAM_CTA_SAVE = 'admin.program.cta.save'
  public static readonly ADMIN_PROGRAM_CTA_GOTOACTIONS = 'admin.program.cta.goToActions'
  public static readonly ADMIN_PROGRAM_CTA_GENERATEACTIONS = 'admin.program.cta.generateActions'
  public static readonly ADMIN_PROGRAM_CTA_TOOLTIP_MISSINGMANDATORY = 'admin.program.cta.tooltip.missingMandatory'
  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_TITLE =
    'admin.program.pillars.modal.searchManually.title'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_NORESULTTEXT =
    'admin.program.pillars.modal.searchManually.noResultText'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_BEFORESEARCHTEXT =
    'admin.program.pillars.modal.searchManually.beforeSearchText'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_FIELDS_SEARCHKEYWORD =
    'admin.program.pillars.modal.searchManually.fields.searchKeyword'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_FIELDS_PROFILES =
    'admin.program.pillars.modal.searchManually.fields.profiles'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_FIELDS_MACROSKILLS =
    'admin.program.pillars.modal.searchManually.fields.macroskills'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_FIELDS_SKILLS =
    'admin.program.pillars.modal.searchManually.fields.skills'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_FIELDS_BEHAVIORS =
    'admin.program.pillars.modal.searchManually.fields.behaviors'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_ADD =
    'admin.program.pillars.modal.searchManually.add'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_ADDED =
    'admin.program.pillars.modal.searchManually.added'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_BEHAVIORS =
    'admin.program.pillars.modal.searchManually.behaviors'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_REFINE =
    'admin.program.pillars.modal.searchManually.refine'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHMANUALLY_RESULTS =
    'admin.program.pillars.modal.searchManually.results'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHACTIONAUTOLOADING_TITLE =
    'admin.program.pillars.modal.searchActionAutoLoading.title'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHACTIONAUTOLOADING_CONTENT =
    'admin.program.pillars.modal.searchActionAutoLoading.content'

  public static readonly ADMIN_PROGRAM_PILLARS_MODAL_SEARCHACTIONAUTOLOADING_SUBTEXT =
    'admin.program.pillars.modal.searchActionAutoLoading.subtext'

  public static readonly ADMIN_PROGRAM_ACTIONS_STATES_DUPLICATE = 'admin.program.actions.states.duplicate'
  public static readonly ADMIN_PROGRAM_ACTIONS_STATES_ARCHIVE = 'admin.program.actions.states.archive'
  public static readonly ADMIN_PROGRAM_ACTIONS_STATES_UNARCHIVE = 'admin.program.actions.states.unarchive'
  public static readonly ADMIN_PROGRAM_ACTIONS_STATES_DELETE = 'admin.program.actions.states.delete'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETEMODAL_TITLE = 'admin.program.actions.deleteModal.title'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETEMODAL_DESCRIPTION = 'admin.program.actions.deleteModal.description'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETEMODAL_CANCEL = 'admin.program.actions.deleteModal.cancel'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETEMODAL_CONFIRM = 'admin.program.actions.deleteModal.confirm'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETEMODAL_CONFIRMDELETE =
    'admin.program.actions.deleteModal.confirmDelete'

  public static readonly ADMIN_PROGRAM_ACTIONS_DUPLICATE_ERROR = 'admin.program.actions.duplicate.error'
  public static readonly ADMIN_PROGRAM_ACTIONS_DUPLICATE_SUCCESSLINK = 'admin.program.actions.duplicate.successLink'
  public static readonly ADMIN_PROGRAM_ACTIONS_DUPLICATE_SUCCESSLABEL = 'admin.program.actions.duplicate.successLabel'
  public static readonly ADMIN_PROGRAM_ACTIONS_ARCHIVE_SUCCESS = 'admin.program.actions.archive.success'
  public static readonly ADMIN_PROGRAM_ACTIONS_ARCHIVE_ERROR = 'admin.program.actions.archive.error'
  public static readonly ADMIN_PROGRAM_ACTIONS_UNARCHIVE_SUCCESS = 'admin.program.actions.unarchive.success'
  public static readonly ADMIN_PROGRAM_ACTIONS_UNARCHIVE_ERROR = 'admin.program.actions.unarchive.error'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETE_SUCCESS = 'admin.program.actions.delete.success'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETE_ERROR = 'admin.program.actions.delete.error'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETE_DELETETOOLTIP = 'admin.program.actions.delete.deleteTooltip'
  public static readonly ADMIN_PROGRAM_ACTIONS_DELETE_ERRORCONFIRM = 'admin.program.actions.delete.errorConfirm'
  public static readonly ADMIN_PROGRAM_EXPORT_MODAL_TITLE = 'admin.program.export.modal.title'
  public static readonly ADMIN_PROGRAM_EXPORT_MODAL_DESCRIPTION = 'admin.program.export.modal.description'
  public static readonly ADMIN_PROGRAM_EXPORT_MODAL_ACTIONSSELECTED = 'admin.program.export.modal.actionsSelected'
  public static readonly ADMIN_PROGRAM_EXPORT_MODAL_EXPORTBTN = 'admin.program.export.modal.exportBtn'
  public static readonly ADMIN_PROGRAM_EXPORT_MODAL_EXPORTTOOLTIPBTN = 'admin.program.export.modal.exportTooltipBtn'
  public static readonly ADMIN_PROGRAM_SEGMENTS_EDIT = 'admin.program.segments.edit'
  public static readonly ADMIN_PROGRAM_SEGMENTS_TITLE = 'admin.program.segments.title'
  public static readonly ADMIN_COMPARESEGMENT_SUBTITLE = 'admin.compareSegment.subtitle'
  public static readonly ADMIN_COMPARESEGMENT_TOP_TITLE = 'admin.compareSegment.top.title'
  public static readonly ADMIN_COMPARESEGMENT_TOP_DESCRIPTION = 'admin.compareSegment.top.description'
  public static readonly ADMIN_COMPARESEGMENT_FLOP_TITLE = 'admin.compareSegment.flop.title'
  public static readonly ADMIN_COMPARESEGMENT_FLOP_DESCRIPTION = 'admin.compareSegment.flop.description'
  public static readonly ADMIN_COMPARESEGMENT_GROUP = 'admin.compareSegment.group'
  public static readonly ADMIN_COMPARESEGMENT_RANKING = 'admin.compareSegment.ranking'
  public static readonly ADMIN_COMPARESEGMENT_USERS = 'admin.compareSegment.users'
  public static readonly ADMIN_COMPARESEGMENT_ADOPTIONRATE = 'admin.compareSegment.adoptionRate'
  public static readonly ADMIN_COMPARESEGMENT_ONEACTIONPERCENT = 'admin.compareSegment.oneActionPercent'
  public static readonly ADMIN_COMPARESEGMENT_MOREACTIONPERCENT = 'admin.compareSegment.moreActionPercent'
  public static readonly ADMIN_COMPARESEGMENT_IMPLEMENTATIONPERCENTAGE = 'admin.compareSegment.implementationPercentage'
  public static readonly ADMIN_COMPARESEGMENT_SUBSCRIBESUSERDOERS = 'admin.compareSegment.subscribesUserDoers'
  public static readonly ADMIN_COMPARESEGMENT_SUSTAINEDPRACTICE = 'admin.compareSegment.sustainedPractice'
  public static readonly ADMIN_ARCHIVEDPROGRAMS_TITLE = 'admin.archivedPrograms.title'
  public static readonly ADMIN_ARCHIVEDPROGRAMS_EMPTY = 'admin.archivedPrograms.empty'
  public static readonly ADMIN_USER_PROGRESS_ACTIONS_1 = 'admin.user.progress.actions.1'
  public static readonly ADMIN_USER_PROGRESS_ACTIONS_2 = 'admin.user.progress.actions.2'
  public static readonly ADMIN_USER_PROGRESS_ACTIONS_3 = 'admin.user.progress.actions.3'
  public static readonly ADMIN_USER_PROGRESS_ACTIONS_4 = 'admin.user.progress.actions.4'
  public static readonly ADMIN_USER_ACTIVITY_PROGRAMSTITLE = 'admin.user.activity.programsTitle'
  public static readonly ADMIN_USER_ACTIVITY_KPIS_PROGRAMS = 'admin.user.activity.kpis.programs'
  public static readonly ADMIN_USER_ACTIVITY_KPIS_ACCEPTEDACTIONS = 'admin.user.activity.kpis.acceptedActions'
  public static readonly ADMIN_USER_ACTIVITY_KPIS_DONEACTIONS = 'admin.user.activity.kpis.doneActions'
  public static readonly ADMIN_USER_ACTIVITY_KPIS_DONEHABITS = 'admin.user.activity.kpis.doneHabits'
  public static readonly ADMIN_USER_ACTIVITY_NOPROGRAMS = 'admin.user.activity.noPrograms'
  public static readonly ADMIN_USER_ACTIVITY_TABLABEL = 'admin.user.activity.tabLabel'
  public static readonly ADMIN_USER_ACTIVITY_STARTEDAT = 'admin.user.activity.startedAt'
  public static readonly ADMIN_USER_PARAMETERS_NOSEGMENTS = 'admin.user.parameters.noSegments'
  public static readonly ADMIN_USER_PARAMETERS_INFO_TITLE = 'admin.user.parameters.info.title'
  public static readonly ADMIN_USER_PARAMETERS_ACCESS_TITLE = 'admin.user.parameters.access.title'
  public static readonly ADMIN_USER_PARAMETERS_SEGMENTS_TITLE = 'admin.user.parameters.segments.title'
  public static readonly ADMIN_USER_PARAMETERS_TABLABEL = 'admin.user.parameters.tabLabel'
  public static readonly ADMIN_SEGMENTSPAGE_DATAGRIDCOLUMN_NAME = 'admin.segmentsPage.datagridColumn.name'
  public static readonly ADMIN_SEGMENTSPAGE_DATAGRIDCOLUMN_USEDINPROGRAMS =
    'admin.segmentsPage.datagridColumn.usedInPrograms'

  public static readonly ADMIN_SEGMENTSPAGE_DATAGRIDCOLUMN_ACTIONS = 'admin.segmentsPage.datagridColumn.actions'
  public static readonly ADMIN_SEGMENTSPAGE_DATAGRIDCOLUMN_INFO = 'admin.segmentsPage.datagridColumn.info'
  public static readonly ADMIN_SEGMENTSPAGE_ADDSEGMENTBTN_LABEL = 'admin.segmentsPage.addSegmentBtn.label'
  public static readonly ADMIN_SEGMENTSPAGE_TITLE = 'admin.segmentsPage.title'
  public static readonly ADMIN_SEGMENTSPAGE_DESCRIPTION = 'admin.segmentsPage.description'
  public static readonly ADMIN_SEGMENTSPAGE_DATAGRID_USAGES_LABEL = 'admin.segmentsPage.datagrid.usages.label'
  public static readonly ADMIN_SEGMENTSPAGE_DELETEMODAL_TITLE = 'admin.segmentsPage.deleteModal.title'
  public static readonly ADMIN_SEGMENTSPAGE_DELETEMODAL_TEXT = 'admin.segmentsPage.deleteModal.text'
  public static readonly ADMIN_SEGMENTSPAGE_DELETEMODAL_SUCCESS = 'admin.segmentsPage.deleteModal.success'
  public static readonly ADMIN_SEGMENTSPAGE_DELETEMODAL_ERROR = 'admin.segmentsPage.deleteModal.error'
  public static readonly ADMIN_SEGMENTSPAGE_NEWSEGMENT_ERROR = 'admin.segmentsPage.newSegment.error'
  public static readonly ADMIN_SEGMENTSPAGE_DELETESEGMENTBTN_TOOLTIP = 'admin.segmentsPage.deleteSegmentBtn.tooltip'
  public static readonly ADMIN_SEGMENTSPAGE_CHECKTRANSLATION = 'admin.segmentsPage.checkTranslation'
  public static readonly ADMIN_SEGMENTSPAGE_ADDFIRSTSEGMENTBTN_LABEL = 'admin.segmentsPage.addFirstSegmentBtn.label'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_TITLE = 'admin.segmentDetailsPage.title'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_NAME = 'admin.segmentDetailsPage.form.name'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_QUESTION = 'admin.segmentDetailsPage.form.question'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_PREVIZ = 'admin.segmentDetailsPage.form.previz'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_CHOICES_TITLE = 'admin.segmentDetailsPage.form.choices.title'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_CHOICES_DELETECONFIRM =
    'admin.segmentDetailsPage.form.choices.deleteConfirm'

  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_CHOICES_ADDBTN = 'admin.segmentDetailsPage.form.choices.addBtn'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_FORM_BULKBTN = 'admin.segmentDetailsPage.form.bulkBtn'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_TITLE = 'admin.segmentDetailsPage.batchModal.title'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_TEXT = 'admin.segmentDetailsPage.batchModal.text'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_PREVIZBTN = 'admin.segmentDetailsPage.batchModal.previzBtn'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_PREVISUALISATION_TEXT =
    'admin.segmentDetailsPage.batchModal.previsualisation.text'

  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_SAVE_ERROR =
    'admin.segmentDetailsPage.batchModal.save.error'

  public static readonly ADMIN_SEGMENTDETAILSPAGE_BATCHMODAL_SAVE_SUCCESS =
    'admin.segmentDetailsPage.batchModal.save.success'

  public static readonly ADMIN_SEGMENTDETAILSPAGE_ADDITEM_ERROR = 'admin.segmentDetailsPage.addItem.error'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_UPDATEITEM_ERROR = 'admin.segmentDetailsPage.updateItem.error'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_UPDATEITEM_SUCCESS = 'admin.segmentDetailsPage.updateItem.success'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_UPDATEITEM_ERRORDUPLICATE =
    'admin.segmentDetailsPage.updateItem.errorDuplicate'

  public static readonly ADMIN_SEGMENTDETAILSPAGE_UPDATESEGMENT_ERROR = 'admin.segmentDetailsPage.updateSegment.error'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_DELETEITEM_ERROR = 'admin.segmentDetailsPage.deleteItem.error'
  public static readonly ADMIN_SEGMENTDETAILSPAGE_DELETEITEM_SUCCESS = 'admin.segmentDetailsPage.deleteItem.success'
  public static readonly COMPONENTS_FIFTYDATAGRID_PAGINATION_ON = 'components.fiftyDatagrid.pagination.on'
  public static readonly PROGRAMFILEFORM_DESCRIPTION = 'ProgramFileForm.description'
  public static readonly PROGRAMFILEFORM_FILENAME = 'ProgramFileForm.filename'
  public static readonly PROGRAMFILEFORM_TITLE = 'ProgramFileForm.title'
  public static readonly PROGRAMFILEFORM_DATE = 'ProgramFileForm.date'
  public static readonly PROGRAMFILEFORM_NOFILES = 'ProgramFileForm.noFiles'
  public static readonly PROGRAMFILEFORM_DOWNLOAD = 'ProgramFileForm.download'
  public static readonly CHALLENGEREVIEWCOMMENTREQUESTTRAITED = 'challengeReviewComment-request-traited'
  public static readonly PROGRAMACTIONACTIONLIST_HEADER_TAB_TOTREAT = 'programActionActionList.header.tab.to-treat'
  public static readonly PROGRAMACTIONACTIONLIST_HEADER_TAB_VALIDATED = 'programActionActionList.header.tab.validated'
  public static readonly PROGRAMACTIONACTIONLIST_HEADER_TAB_ALL = 'programActionActionList.header.tab.all'
  public static readonly PROGRAMACTIONACTIONLIST_HEADER_DESCRIPTION = 'programActionActionList.header.description'
  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NOACTION_TOTREAT =
    'programActionActionList.category.no-action.toTreat'

  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NOACTION_VALIDATED =
    'programActionActionList.category.no-action.validated'

  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NOACTION_FILTERED =
    'programActionActionList.category.no-action.filtered'

  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NOACTION_REJECTED =
    'programActionActionList.category.no-action.rejected'

  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NAME_TOTREAT = 'programActionActionList.category.name.toTreat'
  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NAME_VALIDATED =
    'programActionActionList.category.name.validated'

  public static readonly PROGRAMACTIONACTIONLIST_CATEGORY_NAME_REJECTED =
    'programActionActionList.category.name.rejected'

  public static readonly PROGRAMACTIONACTIONLIST_FILTERS_SKILLS = 'programActionActionList.filters.skills'
  public static readonly PROGRAMACTIONACTIONLIST_DROPDOWN_LABEL = 'programActionActionList.dropdown.label'
  public static readonly PROGRAMACTIONACTIONLIST_DROPDOWN_ADDACTION = 'programActionActionList.dropdown.addAction'
  public static readonly PROGRAMACTIONACTIONLIST_DROPDOWN_ASKFORACTION = 'programActionActionList.dropdown.askForAction'
  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_BUTTON =
    'programActionActionList.modalAddActions.button'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_FOOTER_LINK =
    'programActionActionList.modalAddActions.footer.link'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_FOOTER_TEXT =
    'programActionActionList.modalAddActions.footer.text'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_OWNACTIONS_BUTTON =
    'programActionActionList.modalAddActions.ownActions.button'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_OWNACTIONS_TEXT =
    'programActionActionList.modalAddActions.ownActions.text'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_OWNACTIONS_TITLE =
    'programActionActionList.modalAddActions.ownActions.title'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_AUTOMATICALLY_BUTTON =
    'programActionActionList.modalAddActions.automatically.button'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_AUTOMATICALLY_TEXT =
    'programActionActionList.modalAddActions.automatically.text'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_AUTOMATICALLY_TITLE =
    'programActionActionList.modalAddActions.automatically.title'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_AUTOMATICALLY_NODESCRIPTIONTOOLTIP =
    'programActionActionList.modalAddActions.automatically.noDescriptionTooltip'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_MANUALLY_BUTTON =
    'programActionActionList.modalAddActions.manually.button'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_MANUALLY_TEXT =
    'programActionActionList.modalAddActions.manually.text'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_MANUALLY_TITLE =
    'programActionActionList.modalAddActions.manually.title'

  public static readonly PROGRAMACTIONACTIONLIST_MODALADDACTIONS_TITLE = 'programActionActionList.modalAddActions.title'
  public static readonly PROGRAMACTIONACTIONLIST_EMPTYPILLAR_TEXT = 'programActionActionList.emptyPillar.text'
  public static readonly PROGRAMACTIONACTIONLIST_NOPILLAR_TEXT = 'programActionActionList.noPillar.text'
  public static readonly PROGRAMACTIONACTIONLIST_NOPILLAR_ADDBTN = 'programActionActionList.noPillar.addBtn'

  public static readonly PROGRAMACTION_VALIDATIONSTATUS_VALIDATED = 'programAction.validationStatus.validated'
  public static readonly PROGRAMACTION_VALIDATIONSTATUS_REJECTED = 'programAction.validationStatus.rejected'
  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_HEADER_TITLE =
    'adminProgramCampaignDetailAddUsers.header.title'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FOOTER_ADDEDPARTICIPANTS =
    'adminProgramCampaignDetailAddUsers.footer.addedParticipants'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FOOTER_BTN_ADD =
    'adminProgramCampaignDetailAddUsers.footer.btn.add'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FOOTER_WARNING_TOOLTIP =
    'adminProgramCampaignDetailAddUsers.footer.warning.tooltip'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_COLUMN_NAME =
    'adminProgramCampaignDetailAddUsers.content.datagrid.column.name'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_COLUMN_FIRSTNAME =
    'adminProgramCampaignDetailAddUsers.content.datagrid.column.firstname'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_COLUMN_EMAIL =
    'adminProgramCampaignDetailAddUsers.content.datagrid.column.email'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_COLUMN_CAMPAIGN =
    'adminProgramCampaignDetailAddUsers.content.datagrid.column.campaign'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_TITLE =
    'adminProgramCampaignDetailAddUsers.content.datagrid.title'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_OVERLAPUSERS_PHRASELEFT =
    'adminProgramCampaignDetailAddUsers.content.datagrid.overlapUsers.phraseLeft'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_OVERLAPUSERS_PHRASERIGHT =
    'adminProgramCampaignDetailAddUsers.content.datagrid.overlapUsers.phraseRight'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_OVERLAPUSERS_INFO_TOOLTIP =
    'adminProgramCampaignDetailAddUsers.content.datagrid.overlapUsers.info.tooltip'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_NOTINCLUDEDUSER_WARNING_TOOLTIP =
    'adminProgramCampaignDetailAddUsers.content.datagrid.notIncludedUser.warning.tooltip'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_DATAGRID_NOTINCLUDEDUSER_WARNING_TOOLTIPWITHOUOVERLAP =
    'adminProgramCampaignDetailAddUsers.content.datagrid.notIncludedUser.warning.tooltip-withouOverlap'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_CONTENT_FILTERS_RESET =
    'adminProgramCampaignDetailAddUsers.content.filters.reset'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FILTER_PROGRAMS =
    'adminProgramCampaignDetailAddUsers.filter.programs'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FILTER_CAMPAIGNS =
    'adminProgramCampaignDetailAddUsers.filter.campaigns'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FILTER_PROFILES =
    'adminProgramCampaignDetailAddUsers.filter.profiles'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FILTER_SEGMENTS =
    'adminProgramCampaignDetailAddUsers.filter.segments'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_FILTER_PROFILESV2 =
    'adminProgramCampaignDetailAddUsers.filter.profilesv2'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_INFOFILTER_MESSAGE =
    'adminProgramCampaignDetailAddUsers.infoFilter.message'

  public static readonly ADMINPROGRAMCAMPAIGNDETAILADDUSERS_DATAGRID_NODATA =
    'adminProgramCampaignDetailAddUsers.datagrid.noData'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_TITLE = 'multipleChallengesValidationModal.title'
  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_DESCRIPTION = 'multipleChallengesValidationModal.description'
  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_CLOSEBUTTONTOOLTIP =
    'multipleChallengesValidationModal.closeButtonTooltip'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_CLOSEBUTTONLABEL =
    'multipleChallengesValidationModal.closeButtonLabel'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_STATUSES_VALIDATED =
    'multipleChallengesValidationModal.statuses.validated'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_STATUSES_RESCHEDULED =
    'multipleChallengesValidationModal.statuses.rescheduled'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_STATUSES_SKIPPED =
    'multipleChallengesValidationModal.statuses.skipped'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_SKIPBUTTON =
    'multipleChallengesValidationModal.actions.skipButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_VALIDATEBUTTON =
    'multipleChallengesValidationModal.actions.validateButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_SKIPFEEDBACKBUTTON =
    'multipleChallengesValidationModal.actions.skipFeedbackButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_VALIDATEFEEDBACKBUTTON =
    'multipleChallengesValidationModal.actions.validateFeedbackButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_SKIPRESCHEDULEBUTTON =
    'multipleChallengesValidationModal.actions.skipRescheduleButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_VALIDATERESCHEDULEBUTTON =
    'multipleChallengesValidationModal.actions.validateRescheduleButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_SCHEDULEBUTTON =
    'multipleChallengesValidationModal.actions.scheduleButton'

  public static readonly MULTIPLECHALLENGESVALIDATIONMODAL_ACTIONS_SKIPFEEDBACKBUTTONNEWWEEK =
    'multipleChallengesValidationModal.actions.skipFeedbackButton--newWeek'

  public static readonly ADMINLAUNCHUSERLIST_PARTICIPANT_REMOVED = 'adminLaunchUserList.participant.removed'
  public static readonly ADMINKPI_COLLAPSEBLOCK_WHATDOESITMEAN = 'adminKpi.collapseBlock.whatDoesItMean'
  public static readonly ADMINKPI_COLLAPSEBLOCK_HOWTOINTERPRETIT = 'adminKpi.collapseBlock.howToInterpretIt'
  public static readonly ADMINKPI_COLLAPSEBLOCK_TITLE = 'adminKpi.collapseBlock.title'
  public static readonly ADMINKPI_COLLAPSEBLOCK_ADOPTIONRATE_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.adoptionRate.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_ADOPTIONRATE_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.adoptionRate.howToInterpretIt.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_COURSEANALYSIS_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.courseAnalysis.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_COURSEANALYSIS_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.courseAnalysis.howToInterpretIt.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_RATECARD_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.rateCard.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_RATECARD_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.rateCard.howToInterpretIt.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_RATEOFACTION_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.rateOfAction.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_RATEOFACTION_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.rateOfAction.howToInterpretIt.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_MOSTVALIDATEDACTIONS_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.mostValidatedActions.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_MOSTVALIDATEDACTIONS_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.mostValidatedActions.howToInterpretIt.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_MOSTDIFFICULTACTIONS_WHATDOESITMEAN_CONTENT =
    'adminKpi.collapseBlock.mostDifficultActions.whatDoesItMean.content'

  public static readonly ADMINKPI_COLLAPSEBLOCK_MOSTDIFFICULTACTIONS_HOWTOINTERPRETIT_CONTENT =
    'adminKpi.collapseBlock.mostDifficultActions.howToInterpretIt.content'

  public static readonly ADMINKPI_PILLARSPAGE_BACK = 'adminKpi.pillarsPage.back'
  public static readonly ADMINKPI_PILLARSPAGE_TITLE = 'adminKpi.pillarsPage.title'
  public static readonly ADMINKPI_PILLARSPAGE_ERRORS_GETDETAILED = 'adminKpi.pillarsPage.errors.getDetailed'
  public static readonly ADMINKPI_PILLARSPAGE_ERRORS_GETSINGLEPROGRAMKPISUMMARY =
    'adminKpi.pillarsPage.errors.getSingleProgramKpiSummary'

  public static readonly ADMINKPI_PILLARSPAGE_ERRORS_GETCROSSPROGRAMKPISUMMARY =
    'adminKpi.pillarsPage.errors.getCrossProgramKpiSummary'

  public static readonly ADMINKPI_PILLARSPAGE_IMPLEMENTATIONRATEPERPILLAR =
    'adminKpi.pillarsPage.implementationRatePerPillar'

  public static readonly ADMINKPI_PILLARSPAGE_NODATA_PILLAR1 = 'adminKpi.pillarsPage.noData.pillar1'
  public static readonly ADMINKPI_PILLARSPAGE_NODATA_PILLAR2 = 'adminKpi.pillarsPage.noData.pillar2'
  public static readonly ADMINKPI_PILLARSPAGE_NODATA_PILLAR3 = 'adminKpi.pillarsPage.noData.pillar3'
  public static readonly ADMINKPI_PILLARSPAGE_MOSTPOPULARPILLAR = 'adminKpi.pillarsPage.mostPopularPillar'
  public static readonly ADMINKPI_PILLARSPAGE_PAGELINK = 'adminKpi.pillarsPage.pageLink'
  public static readonly ADMINKPI_PILLARSPAGE_COMPARISONTITLE = 'adminKpi.pillarsPage.comparisonTitle'
  public static readonly ADMINKPI_PILLARSPAGE_SELECTPLACEHOLDER = 'adminKpi.pillarsPage.selectPlaceholder'
  public static readonly ADMINKPI_PILLARSPAGE_DESCRIPTION = 'adminKpi.pillarsPage.description'
  public static readonly ADMINKPI_PILLARSPAGE_RATE_DESCRIPTION = 'adminKpi.pillarsPage.rate.description'
  public static readonly ADMINKPI_PILLARSPAGE_RATE_NORATE = 'adminKpi.pillarsPage.rate.noRate'
  public static readonly ADMINKPI_PILLARSPAGE_PRIORITIZEDBYUSERS = 'adminKpi.pillarsPage.prioritizedByUsers'
  public static readonly ADMINKPI_PILLARSPAGE_ACCEPTEDBYUSERS = 'adminKpi.pillarsPage.acceptedByUsers'
  public static readonly ADMINKPI_PILLARSPAGE_MOSTSUCCESSFULACTION = 'adminKpi.pillarsPage.mostSuccessfulAction'
  public static readonly ADMINKPI_PILLARSPAGE_LEASTSUCCESSFULACTION = 'adminKpi.pillarsPage.leastSuccessfulAction'
  public static readonly ADMINKPI_PILLARSPAGE_LEASTCHOSENACTION = 'adminKpi.pillarsPage.leastChosenAction'
  public static readonly ADMINKPI_PILLARSPAGE_ALLPILLARAREEQUALS = 'adminKpi.pillarsPage.allPillarAreEquals'
  public static readonly ADMINKPI_PILLARSPAGE_RATES_TITLE = 'adminKpi.pillarsPage.rates.title'
  public static readonly ADMINKPI_PILLARSPAGE_RATES_GLOBALRATE = 'adminKpi.pillarsPage.rates.globalRate'
  public static readonly ADMINKPI_PILLARSPAGE_RATES_NORATE = 'adminKpi.pillarsPage.rates.noRate'
  public static readonly ADMINKPI_PILLARSPAGE_MOSTCHOSEN_TITLE = 'adminKpi.pillarsPage.mostChosen.title'
  public static readonly ADMINKPI_PILLARSPAGE_PRIORIZATION_TITLE_HYBRID =
    'adminKpi.pillarsPage.priorization.title.hybrid'

  public static readonly ADMINKPI_PILLARSPAGE_PRIORIZATION_TITLE_OLDFASHIONED =
    'adminKpi.pillarsPage.priorization.title.oldFashioned'

  public static readonly ADMINKPI_PILLARSPAGE_PRIORIZATION_TITLE_HYBRIDTOOLTIP =
    'adminKpi.pillarsPage.priorization.title.hybridtooltip'

  public static readonly ADMINKPI_PILLARSPAGE_PRIORIZATION_SUBTITLE1 = 'adminKpi.pillarsPage.priorization.subtitle1'
  public static readonly ADMINKPI_PILLARSPAGE_PRIORIZATION_SUBTITLE2 = 'adminKpi.pillarsPage.priorization.subtitle2'
  public static readonly ADMINKPI_PILLARSPAGE_SUCCESSRATES_TITLE = 'adminKpi.pillarsPage.successRates.title'
  public static readonly ADMINKPI_PILLARSPAGE_SUCCESSRATES_CHARTS_FAILED =
    'adminKpi.pillarsPage.successRates.charts.failed'

  public static readonly ADMINKPI_PILLARSPAGE_SUCCESSRATES_CHARTS_WAITING =
    'adminKpi.pillarsPage.successRates.charts.waiting'

  public static readonly ADMINKPI_PILLARSPAGE_SUCCESSRATES_CHARTS_SUCCEEDED =
    'adminKpi.pillarsPage.successRates.charts.succeeded'

  public static readonly ADMINKPI_PILLARSPAGE_SUCCESSRATES_NOACCEPTEDACTION =
    'adminKpi.pillarsPage.successRates.noAcceptedAction'

  public static readonly ADMINKPI_PILLARSPAGE_TOPACTIONS_MOSTVALIDATED = 'adminKpi.pillarsPage.topActions.mostValidated'
  public static readonly ADMINKPI_PILLARSPAGE_TOPACTIONS_MOSTFAILED = 'adminKpi.pillarsPage.topActions.mostFailed'
  public static readonly ADMINKPI_PILLARSPAGE_TOPACTIONS_LESSCHOSEN = 'adminKpi.pillarsPage.topActions.lessChosen'
  public static readonly ADMINKPI_FILTERS_PROGRAMLABEL = 'adminKpi.filters.programLabel'
  public static readonly ADMINKPI_FILTERS_EMPTY = 'adminKpi.filters.empty'
  public static readonly ADMINKPI_FILTERS_ACTIVEFILTERS = 'adminKpi.filters.activeFilters'
  public static readonly ADMINKPI_HOMEPAGE_TITLE = 'adminKpi.homePage.title'
  public static readonly ADMINKPI_HOMEPAGE_TABS_PROGRAM = 'adminKpi.homePage.tabs.program'
  public static readonly ADMINKPI_HOMEPAGE_TABS_CROSSPROGRAM = 'adminKpi.homePage.tabs.crossProgram'
  public static readonly ADMINKPI_HOMEPAGE_TABS_IMPACTS = 'adminKpi.homePage.tabs.impacts'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_SELECT_TITLE = 'adminKpi.homePage.program.select.title'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_SELECT_PLACEHOLDER = 'adminKpi.homePage.program.select.placeholder'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_ERRORS_GETPROGRAMNAMES =
    'adminKpi.homePage.program.errors.getProgramNames'

  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_PRACTICE_BENCHMARK = 'adminKpi.homePage.program.practice.benchmark'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_PRACTICE_DOERSTEXT = 'adminKpi.homePage.program.practice.doersText'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_PRACTICE_FOOTERLINK = 'adminKpi.homePage.program.practice.footerLink'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_PRACTICE_ACTIVEUSERS =
    'adminKpi.homePage.program.practice.activeUsers'

  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_PRACTICE_INACTIVEUSERS =
    'adminKpi.homePage.program.practice.inactiveUsers'

  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_IMPACT_TITLE = 'adminKpi.homePage.program.impact.title'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_IMPACT_TOOLTIP = 'adminKpi.homePage.program.impact.tooltip'
  public static readonly ADMINKPI_HOMEPAGE_PROGRAM_IMPACT_ALLPROGRAMSLINK =
    'adminKpi.homePage.program.impact.allProgramsLink'

  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_SELECT_TITLE = 'adminKpi.homePage.crossProgram.select.title'
  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_PRACTICE_TITLE = 'adminKpi.homePage.crossProgram.practice.title'
  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_PRACTICE_LEGENDS_REGULAR =
    'adminKpi.homePage.crossProgram.practice.legends.regular'

  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_PRACTICE_LEGENDS_EXPLORER =
    'adminKpi.homePage.crossProgram.practice.legends.explorer'

  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_PRACTICE_BENCHMARK =
    'adminKpi.homePage.crossProgram.practice.benchmark'

  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_PRACTICE_TEXT = 'adminKpi.homePage.crossProgram.practice.text'
  public static readonly ADMINKPI_HOMEPAGE_CROSSPROGRAM_ADOPTION_TITLE = 'adminKpi.homePage.crossProgram.adoption.title'
  public static readonly ADMINKPI_HOMEPAGE_INFO_SUBTITLE = 'adminKpi.homePage.info.subtitle'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_TITLE = 'adminKpi.homePage.impacts.title'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_TOOLTIP = 'adminKpi.homePage.impacts.tooltip'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_CAMPAIGNS = 'adminKpi.homePage.impacts.campaigns'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_USERS = 'adminKpi.homePage.impacts.users'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_DETAILSLINK = 'adminKpi.homePage.impacts.detailsLink'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_REGISTRATIONTOOLTIP_CHECKED =
    'adminKpi.homePage.impacts.registrationTooltip.checked'

  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_REGISTRATIONTOOLTIP_DISABLED =
    'adminKpi.homePage.impacts.registrationTooltip.disabled'

  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_LOW = 'adminKpi.homePage.impacts.legends.low'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_MEDIUM = 'adminKpi.homePage.impacts.legends.medium'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_GOOD = 'adminKpi.homePage.impacts.legends.good'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_HIGH = 'adminKpi.homePage.impacts.legends.high'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_TITLE = 'adminKpi.homePage.impacts.legends.title'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LEGENDS_IMPLEMENTATIONRATE =
    'adminKpi.homePage.impacts.legends.implementationRate'

  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LABELS_LOW = 'adminKpi.homePage.impacts.labels.low'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LABELS_MEDIUM = 'adminKpi.homePage.impacts.labels.medium'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LABELS_GOOD = 'adminKpi.homePage.impacts.labels.good'
  public static readonly ADMINKPI_HOMEPAGE_IMPACTS_LABELS_HIGH = 'adminKpi.homePage.impacts.labels.high'
  public static readonly ADMINKPI_PRACTICEPAGE_PLATFORMS_DESKTOP = 'adminKpi.practicePage.platforms.desktop'
  public static readonly ADMINKPI_PRACTICEPAGE_PLATFORMS_SMARTPHONE = 'adminKpi.practicePage.platforms.smartphone'
  public static readonly ADMINKPI_PRACTICEPAGE_CHANNELS_AGENDA = 'adminKpi.practicePage.channels.agenda'
  public static readonly ADMINKPI_PRACTICEPAGE_CHANNELS_SMS = 'adminKpi.practicePage.channels.sms'
  public static readonly ADMINKPI_PRACTICEPAGE_CHANNELS_TEAMS = 'adminKpi.practicePage.channels.teams'
  public static readonly ADMINKPI_PRACTICEPAGE_CHANNELS_SUBTITLE = 'adminKpi.practicePage.channels.subtitle'
  public static readonly ADMINKPI_PRACTICEPAGE_PLATORMS_CARDTITLE = 'adminKpi.practicePage.platorms.cardTitle'
  public static readonly ADMINKPI_PRACTICEPAGE_PLATORMS_SUBTITLE = 'adminKpi.practicePage.platorms.subtitle'
  public static readonly ADMINKPI_PRACTICEPAGE_ERRORS_GETDETAILED = 'adminKpi.practicePage.errors.getDetailed'
  public static readonly FIFTYBANNERALERT_UNREACHABLEAPI_MESSAGE = 'fiftyBannerAlert.unreachableApi.message'
  public static readonly COMPARESEGMENT_FORTHEPROGRAM = 'compareSegment.forTheProgram'
  public static readonly COMPARESEGMENTS_COMPARERESULTSBY = 'compareSegments.compareResultsBy'
  public static readonly COMPARESEGMENTS_MOSTENGAGEDGROUPS_TITLE = 'compareSegments.mostEngagedGroups.title'
  public static readonly COMPARESEGMENTS_LESSENGAGEDGROUPS_TITLE = 'compareSegments.lessEngagedGroups.title'
  public static readonly COMPARESEGMENTS_DETAILEDRESULTS = 'compareSegments.detailedResults'
  public static readonly COMPARESEGMENTS_PLACEHOLDER_SELECTAPROGRAM = 'compareSegments.placeholder.selectAProgram'
  public static readonly COMPARESEGMENTS_PLACEHOLDER_SELECTASEGMENT = 'compareSegments.placeholder.selectASegment'
  public static readonly COMPARESEGMENTS_FORTHEPROGRAM = 'compareSegments.forTheProgram'
  public static readonly COMPARESEGMENTS_DATAGRID_TOOLTIP_IMPLEMENTATIONPERCENTAGE =
    'compareSegments.datagrid.tooltip.implementationPercentage'

  public static readonly COMPARESEGMENTS_DATAGRID_TOOLTIP_RANKING = 'compareSegments.datagrid.tooltip.ranking'
  public static readonly COMPARESEGMENTS_DATAGRID_TOOLTIP_ADOPTION = 'compareSegments.datagrid.tooltip.adoption'
  public static readonly COMPARESEGMENTS_DATAGRID_TOOLTIP_PRACTICE = 'compareSegments.datagrid.tooltip.practice'
  public static readonly COMPARESEGMENTS_MESSAGE_TITLE = 'compareSegments.message.title'
  public static readonly COMPARESEGMENTS_MESSAGE_DESCRIPTION = 'compareSegments.message.description'
  public static readonly COMPARESEGMENTS_DOWNLOAD_TOOLTIP = 'compareSegments.download.tooltip'
  public static readonly COMPARESEGMENTS_BESTADOPTION_TITLE = 'compareSegments.bestAdoption.title'
  public static readonly COMPARESEGMENTS_WORSTADOPTION_TITLE = 'compareSegments.worstAdoption.title'
  public static readonly KPI_RANKING_UNASSIGNED = 'kpi.ranking.unassigned'
  public static readonly KPI_RANKING_MANAGERPROFILES = 'kpi.ranking.managerProfiles'
  public static readonly KPI_RANKING_LAUNCHES = 'kpi.ranking.launches'
  public static readonly KPI_RANKING_IGNOREDGROUPS_PREFACE = 'kpi.ranking.ignoredGroups.preface'
  public static readonly KPI_RANKING_IGNOREDGROUPS_UNSPECIFIEDGROUPEXPLANATION =
    'kpi.ranking.ignoredGroups.unspecifiedGroupExplanation'

  public static readonly KPI_RANKING_IGNOREDGROUPS_UNSPECIFIEDGROUP = 'kpi.ranking.ignoredGroups.unspecifiedGroup'
  public static readonly KPI_RANKING_IGNOREDGROUPS_TOOFEWUSERS = 'kpi.ranking.ignoredGroups.tooFewUsers'
  public static readonly FIFTYPILARGRAPH_PILAR = 'fiftyPilarGraph.pilar'
  public static readonly FIFTYPILARGRAPH_TEXTAREA_PLACEHOLDER = 'fiftyPilarGraph.textArea.placeholder'
  public static readonly ADMINKPIADOPTIONRATEPAGE_HEADER_TITLE = 'adminKpiAdoptionRatePage.header.title'
  public static readonly ADMINKPIADOPTIONRATECARD_PRESUBSCRIBES = 'adminKpiAdoptionRateCard.preSubscribes'
  public static readonly ADMINKPIADOPTIONRATECARD_SUBSCRIBES = 'adminKpiAdoptionRateCard.subscribes'
  public static readonly ADMINKPIADOPTIONRATECARD_FOOTER_INTERESTPHRASE =
    'adminKpiAdoptionRateCard.footer.interestPhrase'

  public static readonly ADMINKPIADOPTIONRATECARD_VOLUNTEERS = 'adminKpiAdoptionRateCard.volunteers'
  public static readonly ADMINKPIADOPTIONRATECARD_TARGETUSERS = 'adminKpiAdoptionRateCard.targetUsers'
  public static readonly ADMINKPIADOPTIONRATECARD_ADOPTIONRATE = 'adminKpiAdoptionRateCard.adoptionRate'
  public static readonly ADMINKPIADOPTIONRATECARD_DOWNLOAD_REGISTERED_USERS =
    'adminKpiAdoptionRateCard.download_registered_users'

  public static readonly ADMINKPIADOPTIONRATECARD_REMIND_UNREGISTERED_USERS =
    'adminKpiAdoptionRateCard.remind_unregistered_users'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_REGISTERED_USERS_TEXT =
    'adminKpiAdoptionRateCard.modal.registered_users.text'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_ACCESS_OUR_TEMPLATES =
    'adminKpiAdoptionRateCard.modal.access_our_templates'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_ACCESS_OUR_REMINDER_TEMPLATES =
    'adminKpiAdoptionRateCard.modal.access_our_reminder_templates'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_DOWNLOAD_EMAIL_TEMPLATE =
    'adminKpiAdoptionRateCard.modal.download_email_template'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_UNREGISTERED_USERS_LIST =
    'adminKpiAdoptionRateCard.modal.unregistered_users_list'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_DOWNLOAD_USERS_LIST =
    'adminKpiAdoptionRateCard.modal.download_users_list'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_REGISTERED_USERS_LIST =
    'adminKpiAdoptionRateCard.modal.registered_users_list'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_REMINDER_EMAIL_TEMPLATE =
    'adminKpiAdoptionRateCard.modal.reminder_email_template'

  public static readonly ADMINKPIADOPTIONRATECARD_MODAL_UNREGISTERED_USERS_TEXT =
    'adminKpiAdoptionRateCard.modal.unregistered_users.text'

  public static readonly ADMINKPIPRACTICE_HEADER_TITLE = 'adminKpiPractice.header.title'
  public static readonly ADMINKPIACTIONSCOMPLETIONCARD_HEADER_TITLE = 'adminKpiActionsCompletionCard.header.title'
  public static readonly ADMINKPIACTIONSCOMPLETIONCARD_DATA_SUCCESSFULACTIONS =
    'adminKpiActionsCompletionCard.data.successfulActions'

  public static readonly ADMINKPIACTIONSCOMPLETIONCARD_DATA_NOTSUCCESSFULACTIONS =
    'adminKpiActionsCompletionCard.data.notSuccessfulActions'

  public static readonly ADMINKPIACTIONSCOMPLETIONCARD_DATA_PENDINGACTIONS =
    'adminKpiActionsCompletionCard.data.pendingActions'

  public static readonly ACTIONSACCEPTANCEEVOLUTIONCARD_HEADER_TITLE = 'actionsAcceptanceEvolutionCard.header.title'
  public static readonly ACTIONSACCEPTANCEEVOLUTIONCARD_CHART_NUMBEROFSUCCESSFULACTIONS =
    'actionsAcceptanceEvolutionCard.chart.numberOfSuccessfulActions'

  public static readonly ACTIONSACCEPTANCEEVOLUTIONCARD_CHART_VALUE = 'actionsAcceptanceEvolutionCard.chart.value'
  public static readonly BATCHUSEFILEFORM_ALERTOVERLAP_SUMMARY_TITLE = 'batchUseFileForm.alertOverlap.summary.title'
  public static readonly ERRORSPAGE_NOTFOUND_TITLE = 'errorspage.notfound.title'
  public static readonly ERRORSPAGE_NOTFOUND_TEXT = 'errorspage.notfound.text'
  public static readonly ERRORSPAGE_FORBIDDEN_TITLE = 'errorspage.forbidden.title'
  public static readonly ERRORSPAGE_FORBIDDEN_TEXT = 'errorspage.forbidden.text'
  public static readonly ERRORSPAGE_INTERNAL_TITLE = 'errorspage.internal.title'
  public static readonly ERRORSPAGE_INTERNAL_TEXT = 'errorspage.internal.text'
  public static readonly ERRORSPAGE_NETWORK_TITLE = 'errorspage.network.title'
  public static readonly ERRORSPAGE_NETWORK_TEXT = 'errorspage.network.text'
  public static readonly ERRORSPAGE_UNKNOWN_TITLE = 'errorspage.unknown.title'
  public static readonly ERRORSPAGE_UNKNOWN_TEXT = 'errorspage.unknown.text'
  public static readonly ERRORSPAGE_ACTIONS_BACK = 'errorspage.actions.back'
  public static readonly ONBOARDING_HOWTO_TITLE = 'onboarding.howto.title'
  public static readonly ONBOARDING_HOWTO_TEXT1 = 'onboarding.howto.text1'
  public static readonly ONBOARDING_HOWTO_TEXT2 = 'onboarding.howto.text2'
  public static readonly ONBOARDING_HOWTO_ACTIONS_NEXT = 'onboarding.howto.actions.next'
  public static readonly ONBOARDING_HOWTO_ACTIONS_BACK = 'onboarding.howto.actions.back'
  public static readonly ONBOARDING_HOWTO_META_TITLE = 'onboarding.howto.meta.title'
  public static readonly ONBOARDING_HOWTO_TEXT3 = 'onboarding.howto.text3'
  public static readonly ONBOARDING_LEARNING_TITLE = 'onboarding.learning.title'
  public static readonly ONBOARDING_LEARNING_TEXT1 = 'onboarding.learning.text1'
  public static readonly ONBOARDING_LEARNING_TEXT2 = 'onboarding.learning.text2'
  public static readonly ONBOARDING_LEARNING_ACTIONS_BACK = 'onboarding.learning.actions.back'
  public static readonly ONBOARDING_LEARNING_ACTIONS_NEXT = 'onboarding.learning.actions.next'
  public static readonly ONBOARDING_LEARNING_META_TITLE = 'onboarding.learning.meta.title'
  public static readonly ONBOARDING_PILLARSELECT_TITLE = 'onboarding.pillarSelect.title'
  public static readonly ONBOARDING_PILLARSELECT_SUBTITLE = 'onboarding.pillarSelect.subtitle'
  public static readonly ONBOARDING_PILLARSELECT_THEME = 'onboarding.pillarSelect.theme'
  public static readonly ONBOARDING_PILLARSELECT_CHOOSE = 'onboarding.pillarSelect.choose'
  public static readonly ONBOARDING_PILLARSELECT_MOREINFO = 'onboarding.pillarSelect.moreInfo'
  public static readonly ONBOARDING_PILLARSELECT_TOOLTIP = 'onboarding.pillarSelect.tooltip'
  public static readonly ONBOARDING_PILLARSELECT_NEXT = 'onboarding.pillarSelect.next'
  public static readonly ONBOARDING_PILLARSELECT_BACK = 'onboarding.pillarSelect.back'
  public static readonly ONBOARDING_PILLARSELECT_META_TITLE = 'onboarding.pillarSelect.meta.title'
  public static readonly ONBOARDING_PILLARSELECT_CHOSEN = 'onboarding.pillarSelect.chosen'
  public static readonly ONBOARDING_STEPS_1 = 'onboarding.steps.1'
  public static readonly ONBOARDING_STEPS_2 = 'onboarding.steps.2'
  public static readonly ONBOARDING_STEPS_3 = 'onboarding.steps.3'
  public static readonly ONBOARDING_STEPS_4 = 'onboarding.steps.4'
  public static readonly ONBOARDING_SUBSEQUENT_TITLE = 'onboarding.subsequent.title'
  public static readonly ONBOARDING_SUBSEQUENT_SUBTITLE = 'onboarding.subsequent.subtitle'
  public static readonly ONBOARDING_ACTIONCHOICES_ERRORS_TITLE = 'onboarding.actionChoices.errors.title'
  public static readonly ONBOARDING_ACTIONCHOICES_ERRORS_DESCRIPTION = 'onboarding.actionChoices.errors.description'
  public static readonly ONGOINGCHALLENGESLIST_NOONGOING_TITLE = 'ongoingChallengesList.noOngoing.title'
  public static readonly ONGOINGCHALLENGESLIST_NOONGOING_DESCRIPTION = 'ongoingChallengesList.noOngoing.description'
  public static readonly ONGOINGCHALLENGESLIST_NOONGOING_CTA = 'ongoingChallengesList.noOngoing.cta'
  public static readonly ONGOINGCHALLENGESLIST_NOMORE_DESCRIPTION_NOMOREACTIONS =
    'ongoingChallengesList.noMore.description.noMoreActions'

  public static readonly ONGOINGCHALLENGESLIST_NOMORE_DESCRIPTION_COMEBACK =
    'ongoingChallengesList.noMore.description.comeback'

  public static readonly ONGOINGCHALLENGESLIST_NOMORE_TITLE = 'ongoingChallengesList.noMore.title'
  public static readonly ONGOINGCHALLENGESLIST_NOMORE_CTA = 'ongoingChallengesList.noMore.cta'
  public static readonly ONGOINGCHALLENGESLIST_STARTNEWWEEK_TITLE = 'ongoingChallengesList.startNewWeek.title'
  public static readonly ONGOINGCHALLENGESLIST_STARTNEWWEEK_DESCRIPTION =
    'ongoingChallengesList.startNewWeek.description'

  public static readonly ONGOINGCHALLENGESLIST_STARTNEWWEEK_CTA = 'ongoingChallengesList.startNewWeek.cta'
  public static readonly ONGOINGCHALLENGESLIST_TITLE = 'ongoingChallengesList.title'
  public static readonly ONGOINGCHALLENGESLIST_NOONGOINGPLANNED_TITLE = 'ongoingChallengesList.noOngoingPlanned.title'
  public static readonly ONGOINGCHALLENGESLIST_NOONGOINGPLANNED_DESCRIPTION =
    'ongoingChallengesList.noOngoingPlanned.description'

  public static readonly ONGOINGCHALLENGESLIST_NOONGOINGPLANNED_CTA = 'ongoingChallengesList.noOngoingPlanned.cta'
  public static readonly WEEKPROGRESSWIDGET_TITLE = 'weekProgressWidget.title'
  public static readonly WEEKPROGRESSWIDGET_DESCRIPTION = 'weekProgressWidget.description'
  public static readonly FIFTYACTIONPICKER_TITLE_PILAR = 'fiftyActionPicker.title.pilar'
  public static readonly FIFTYACTIONPICKER_TITLE_NOPILAR_FIRSTACTION = 'fiftyActionPicker.title.noPilar.firstAction'
  public static readonly FIFTYACTIONPICKER_DESCRIPTION_PILAR = 'fiftyActionPicker.description.pilar'
  public static readonly FIFTYACTIONPICKER_DESCRIPTION_NOPILAR = 'fiftyActionPicker.description.noPilar'
  public static readonly FIFTYACTIONPICKER_WEEKCOUNTER = 'fiftyActionPicker.weekCounter'
  public static readonly FIFTYACTIONPICKER_ACTION_ACCEPTED = 'fiftyActionPicker.action.accepted'
  public static readonly FIFTYACTIONPICKER_ACTION_SHOWMORE = 'fiftyActionPicker.action.showMore'
  public static readonly FIFTYACTIONPICKER_ACTION_CTA = 'fiftyActionPicker.action.cta'
  public static readonly FIFTYACTIONPICKER_FOOTER_CTA_STARTMYWEEK = 'fiftyActionPicker.footer.cta.startMyWeek'
  public static readonly FIFTYACTIONPICKER_FOOTER_CTA_DOACTIONS = 'fiftyActionPicker.footer.cta.doActions'
  public static readonly FIFTYACTIONPICKER_FOOTER_CTA_BACKTOPILLAR = 'fiftyActionPicker.footer.cta.backToPillar'
  public static readonly CHALLENGECHOICE_HEADING = 'challengeChoice.heading'
  public static readonly CHALLENGECHOICE_DESCRIPTION = 'challengeChoice.description'
  public static readonly NEWSESSIONACTIONSFEEDBACK_TITLE = 'newSessionActionsFeedback.title'
  public static readonly NEWSESSIONACTIONSFEEDBACK_DESCRIPTION = 'newSessionActionsFeedback.description'
  public static readonly NEWSESSIONACTIONSFEEDBACK_CTA = 'newSessionActionsFeedback.cta'
  public static readonly NEWSESSIONACTIONSFEEDBACK_CTAOLD = 'newSessionActionsFeedback.cta--old'
  public static readonly NEWSESSIONPAGE_STEPPER_STEPLASTWEEKFEEDBACK = 'newSessionPage.stepper.step--last-week-feedback'
  public static readonly NEWSESSIONPAGE_STEPPER_STEPNEWSESSIONPILARSELECTION =
    'newSessionPage.stepper.step--new-session-pilar-selection'

  public static readonly NEWSESSIONPAGE_STEPPER_STEPNEWSESSIONACTIONSSELECTION =
    'newSessionPage.stepper.step--new-session-actions-selection'

  public static readonly GENERIC_OR = 'generic.or'
  public static readonly NEWWEEKFEEDBACKTITLE = 'new-week-feedback-title'
  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_HEADING = 'new-session-skip-actions-feedback.heading'
  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_DESCRIPTION = 'new-session-skip-actions-feedback.description'
  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_NOALREADYDOING =
    'new-session-skip-actions-feedback.no--already-doing'

  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_NONOTAPPLICABLE =
    'new-session-skip-actions-feedback.no--not-applicable'

  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_YESACTIONCONFIRM =
    'new-session-skip-actions-feedback.yesActionConfirm'

  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_NOACTIONCONFIRM =
    'new-session-skip-actions-feedback.noActionConfirm'

  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_CTA_PASS = 'new-session-skip-actions-feedback.cta.pass'
  public static readonly NEWSESSIONSKIPACTIONSFEEDBACK_CTA_CONFIRM = 'new-session-skip-actions-feedback.cta.confirm'
  public static readonly COMMON_GPDR_FREETEXT = 'common.gpdr.freeText'
  public static readonly COMMON_AND = 'common.and'
  public static readonly COMMON_BETA = 'common.beta'
  public static readonly ADMINCREATEPROGRAM_HEADING = 'adminCreateProgram.heading'
  public static readonly ADMINCREATEPROGRAM_DESCRIPTION = 'adminCreateProgram.description'
  public static readonly ADMINCREATEPROGRAM_CTA_BACK = 'adminCreateProgram.cta.back'
  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_HEADING = 'adminCreateProgram.path.generate.heading'
  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_BANNER = 'adminCreateProgram.path.generate.banner'
  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_CARD_TITLE = 'adminCreateProgram.path.generate.card.title'
  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_CARD_DESCRIPTION =
    'adminCreateProgram.path.generate.card.description'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_CARD_CTA = 'adminCreateProgram.path.generate.card.cta'
  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_FILEUPLOAD_LABEL =
    'adminCreateProgram.path.generate.steps.fileUpload.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_FILEUPLOAD_CONFIRMCTA =
    'adminCreateProgram.path.generate.steps.fileUpload.confirmCTA'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_FILEANALYSIS_LABEL =
    'adminCreateProgram.path.generate.steps.fileAnalysis.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATEINFOS_LABEL =
    'adminCreateProgram.path.generate.steps.generateInfos.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATEINFOS_CTA =
    'adminCreateProgram.path.generate.steps.generateInfos.cta'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATEINFOS_BACKBTN =
    'adminCreateProgram.path.generate.steps.generateInfos.backBtn'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATESTRUCTURE_LABEL =
    'adminCreateProgram.path.generate.steps.generateStructure.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATESTRUCTURE_CTA =
    'adminCreateProgram.path.generate.steps.generateStructure.cta'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATESTRUCTURE_BACKBTN =
    'adminCreateProgram.path.generate.steps.generateStructure.backBtn'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_GENERATESTRUCTURE_SKIPCTA =
    'adminCreateProgram.path.generate.steps.generateStructure.skipCta'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_MANUALINFOS_LABEL =
    'adminCreateProgram.path.generate.steps.manualInfos.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_MANUALINFOS_CTA =
    'adminCreateProgram.path.generate.steps.manualInfos.cta'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_MANUALSTRUCTURE_LABEL =
    'adminCreateProgram.path.generate.steps.manualStructure.label'

  public static readonly ADMINCREATEPROGRAM_PATH_GENERATE_STEPS_MANUALSTRUCTURE_CTA =
    'adminCreateProgram.path.generate.steps.manualStructure.cta'

  public static readonly ADMINCREATEPROGRAM_PATH_MANUAL_HEADING = 'adminCreateProgram.path.manual.heading'
  public static readonly ADMINCREATEPROGRAM_PATH_MANUAL_BANNER = 'adminCreateProgram.path.manual.banner'
  public static readonly ADMINCREATEPROGRAM_PATH_MANUAL_CARD_TITLE = 'adminCreateProgram.path.manual.card.title'
  public static readonly ADMINCREATEPROGRAM_PATH_MANUAL_CARD_DESCRIPTION =
    'adminCreateProgram.path.manual.card.description'

  public static readonly ADMINCREATEPROGRAM_PATH_MANUAL_CARD_CTA = 'adminCreateProgram.path.manual.card.cta'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_HEADING = 'adminCreateProgram.path.template.heading'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_BANNER = 'adminCreateProgram.path.template.banner'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_CARD_TITLE = 'adminCreateProgram.path.template.card.title'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_CARD_DESCRIPTION =
    'adminCreateProgram.path.template.card.description'

  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_CARD_CTA = 'adminCreateProgram.path.template.card.cta'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_CHOOSETEMPLATE =
    'adminCreateProgram.path.template.chooseTemplate'

  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_TOTALACTIONS = 'adminCreateProgram.path.template.totalActions'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_MODALTITLE = 'adminCreateProgram.path.template.modalTitle'
  public static readonly ADMINCREATEPROGRAM_PATH_TEMPLATE_HEADER_TOTALACTIONS =
    'adminCreateProgram.path.template.header.totalActions'

  public static readonly ADMINCREATEPROGRAM_ERRORS_CHOOSETYPE = 'adminCreateProgram.errors.chooseType'
  public static readonly CREATEPROGRAMFILEUPLOAD_HEADING = 'createProgramFileUpload.heading'
  public static readonly CREATEPROGRAMFILEUPLOAD_UPLOAD_TITLE = 'createProgramFileUpload.upload.title'
  public static readonly CREATEPROGRAMFILEUPLOAD_UPLOAD_CTA = 'createProgramFileUpload.upload.cta'
  public static readonly CREATEPROGRAMFILEUPLOAD_UPLOAD_FORMATACCEPTED = 'createProgramFileUpload.upload.formatAccepted'
  public static readonly CREATEPROGRAMFILEUPLOAD_UPLOAD_COMPLETED = 'createProgramFileUpload.upload.completed'
  public static readonly CREATEPROGRAMFILEUPLOAD_UPLOAD_ONGOING = 'createProgramFileUpload.upload.ongoing'
  public static readonly CREATEPROGRAMFILEANALYSIS_HEADING = 'createProgramFileAnalysis.heading'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_0 = 'createProgramFileAnalysis.loading.0'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_1 = 'createProgramFileAnalysis.loading.1'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_2 = 'createProgramFileAnalysis.loading.2'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_3 = 'createProgramFileAnalysis.loading.3'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_4 = 'createProgramFileAnalysis.loading.4'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_5 = 'createProgramFileAnalysis.loading.5'
  public static readonly CREATEPROGRAMFILEANALYSIS_LOADING_6 = 'createProgramFileAnalysis.loading.6'
  public static readonly CREATEPROGRAMFILEANALYSIS_CTA_RETRYWITHANOTHER =
    'createProgramFileAnalysis.cta.retryWithAnother'

  public static readonly CREATEPROGRAMFILEANALYSIS_CTA_RETRYWITHSAME = 'createProgramFileAnalysis.cta.retryWithSame'
  public static readonly CREATEPROGRAMFILEANALYSIS_CTA_CONNECTMANUALLY = 'createProgramFileAnalysis.cta.connectManually'
  public static readonly CREATEPROGRAMFILEANALYSIS_CTA_RETRYWITHSAMEFILE =
    'createProgramFileAnalysis.cta.retryWithSameFile'

  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_3_TITLE = 'createProgramFileAnalysis.errors.3.title'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_3_CONTENT = 'createProgramFileAnalysis.errors.3.content'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_2_TITLE = 'createProgramFileAnalysis.errors.2.title'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_2_CONTENT = 'createProgramFileAnalysis.errors.2.content'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_4_TITLE = 'createProgramFileAnalysis.errors.4.title'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_4_CONTENT = 'createProgramFileAnalysis.errors.4.content'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_1_TITLE = 'createProgramFileAnalysis.errors.1.title'
  public static readonly CREATEPROGRAMFILEANALYSIS_ERRORS_1_CONTENT = 'createProgramFileAnalysis.errors.1.content'
  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_INFO_HEADING =
    'adminCreateProgramGeneration.banner.info.heading'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_INFO_DESCRIPTION =
    'adminCreateProgramGeneration.banner.info.description'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_STRUCTURE_HEADING =
    'adminCreateProgramGeneration.banner.structure.heading'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_STRUCTURE_DESCRIPTION =
    'adminCreateProgramGeneration.banner.structure.description'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_WARNING_SUBJECTISTOONARROW_TITLE =
    'adminCreateProgramGeneration.banner.warning.subjectIsTooNarrow.title'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_WARNING_SUBJECTISTOOWIDE_TITLE =
    'adminCreateProgramGeneration.banner.warning.subjectIsToWide.title'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_WARNING_SUBJECTISTOONARROW_DESCRIPTION =
    'adminCreateProgramGeneration.banner.warning.subjectIsTooNarrow.description'

  public static readonly ADMINCREATEPROGRAMGENERATION_BANNER_WARNING_SUBJECTISTOOWIDE_DESCRIPTION =
    'adminCreateProgramGeneration.banner.warning.subjectIsToWide.description'

  public static readonly ADMINCREATEPROGRAMGENERATION_ERRORS_REMOVEFILE =
    'adminCreateProgramGeneration.errors.removeFile'

  public static readonly ADMINCREATEPROGRAMGENERATION_ERRORS_ADDFILE = 'adminCreateProgramGeneration.errors.addFile'
  public static readonly ADMINCREATEPROGRAMGENERATION_ERRORS_WRONGEXTENSION =
    'adminCreateProgramGeneration.errors.wrongExtension'

  public static readonly ADMINCREATEPROGRAMGENERATION_FOOTER_TOOLTIP = 'adminCreateProgramGeneration.footer.tooltip'
  public static readonly ADMINCREATEPROGRAMMANUAL_FOOTER_TOOLTIP = 'adminCreateProgramManual.footer.tooltip'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_TITLE = 'createProgramFile.informations.title'
  public static readonly CREATEPROGRAMFILE_INFORMATIONS_DESCRIPTION = 'createProgramFile.informations.description'
  public static readonly CREATEPROGRAMFILE_INFORMATIONS_LABELS_NAME = 'createProgramFile.informations.labels.name'
  public static readonly CREATEPROGRAMFILE_INFORMATIONS_LABELS_MANAGERPROFILES =
    'createProgramFile.informations.labels.managerProfiles'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_LABELS_DESCRIPTION =
    'createProgramFile.informations.labels.description'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_ERRORS_OUTOFSCOPE =
    'createProgramFile.informations.errors.OutOfScope'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_ERRORS_SUBJECTISTOONARROW =
    'createProgramFile.informations.errors.SubjectIsTooNarrow'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_ERRORS_SUBJECTISTOWIDE =
    'createProgramFile.informations.errors.SubjectIsToWide'

  public static readonly CREATEPROGRAMFILE_INFORMATIONS_ERRORS_TECHNICALERROR =
    'createProgramFile.informations.errors.TechnicalError'

  public static readonly CREATEPROGRAMFILE_STRUCTURE_TITLE = 'createProgramFile.structure.title'
  public static readonly CREATEPROGRAMFILE_STRUCTURE_DESCRIPTION = 'createProgramFile.structure.description'
  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_USERNOTFOUND_TITLE = 'signupWithTeamsPage.errors.userNotFound.title'
  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_USERNOTFOUND_DESCRIPTION =
    'signupWithTeamsPage.errors.userNotFound.description'

  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_UNKNOWN_TITLE = 'signupWithTeamsPage.errors.unknown.title'
  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_UNKNOWN_DESCRIPTION =
    'signupWithTeamsPage.errors.unknown.description'

  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_TEAMSADMINNOTAVAILABLE_TITLE =
    'signupWithTeamsPage.errors.teamsAdminNotAvailable.title'

  public static readonly SIGNUPWITHTEAMSPAGE_ERRORS_TEAMSADMINNOTAVAILABLE_DESCRIPTION =
    'signupWithTeamsPage.errors.teamsAdminNotAvailable.description'

  public static readonly CREATEACTIONWITHAI_HEADER_TITLE = 'createActionWithAI.header.title'
  public static readonly CREATEACTIONWITHAI_HEADER_SUBTITLE = 'createActionWithAI.header.subtitle'
  public static readonly CREATEACTIONWITHAI_ACTIONINPUT_LABEL = 'createActionWithAI.actionInput.label'
  public static readonly CREATEACTIONWITHAI_ACTIONINPUT_PLACEHOLDER = 'createActionWithAI.actionInput.placeholder'
  public static readonly CREATEACTIONWITHAI_ACTIONINPUT_HELP = 'createActionWithAI.actionInput.help'
  public static readonly CREATEACTIONWITHAI_ACTIONINPUT_TOOLTIP = 'createActionWithAI.actionInput.tooltip'
  public static readonly CREATEACTIONWITHAI_SIMILARITY_ACTIONBTN = 'createActionWithAI.similarity.actionBtn'
  public static readonly CREATEACTIONWITHAI_SIMILARITY_ERRORS_ADDINGACTIONTOPROGRAM =
    'createActionWithAI.similarity.errors.addingActionToProgram'

  public static readonly CREATEACTIONWITHAI_ACTIONS_CONTINUEBTN = 'createActionWithAI.actions.continueBtn'
  public static readonly CREATEACTIONWITHAI_ACTIONS_CONFIRMINPUT_TOOLTIP =
    'createActionWithAI.actions.confirmInput.tooltip'

  public static readonly CREATEACTIONWITHAI_ACTIONS_RETRY = 'createActionWithAI.actions.retry'
  public static readonly CREATEACTIONWITHAI_ACTIONS_CONTINUEMANUALLY = 'createActionWithAI.actions.continueManually'
  public static readonly CREATEACTIONWITHAI_LOADER_SIMILARITY = 'createActionWithAI.loader.similarity'
  public static readonly CREATEACTIONWITHAI_LOADER_WORDING = 'createActionWithAI.loader.wording'
  public static readonly CREATEACTIONWITHAI_LOADER_CONTENT = 'createActionWithAI.loader.content'
  public static readonly CREATEACTIONWITHAI_STEPS_SIMILARITY_SUBTITLE = 'createActionWithAI.steps.similarity.subtitle'
  public static readonly CREATEACTIONWITHAI_STEPS_SIMILARITY_ADDACTIONTOPROGRAM =
    'createActionWithAI.steps.similarity.addActionToProgram'

  public static readonly CREATEACTIONWITHAI_STEPS_SIMILARITY_ACTIONADDED =
    'createActionWithAI.steps.similarity.actionAdded'

  public static readonly CREATEACTIONWITHAI_STEPS_SIMILARITY_ERROR = 'createActionWithAI.steps.similarity.error'
  public static readonly CREATEACTIONWITHAI_STEPS_SIMILARITY_EMPTY = 'createActionWithAI.steps.similarity.empty'
  public static readonly CREATEACTIONWITHAI_STEPS_WORDING_SUBTITLE = 'createActionWithAI.steps.wording.subtitle'
  public static readonly CREATEACTIONWITHAI_STEPS_WORDING_USETHISFORMULATION =
    'createActionWithAI.steps.wording.useThisFormulation'

  public static readonly CREATEACTIONWITHAI_STEPS_WORDING_FORMULATIONUSED =
    'createActionWithAI.steps.wording.formulationUsed'

  public static readonly CREATEACTIONWITHAI_STEPS_WORDING_ERROR = 'createActionWithAI.steps.wording.error'
  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_SUBTITLE = 'createActionWithAI.steps.content.subtitle'
  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ERROR = 'createActionWithAI.steps.content.error'
  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_DURATION_PLACEHOLDER =
    'createActionWithAI.steps.content.duration.placeholder'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_DURATION_LABEL =
    'createActionWithAI.steps.content.duration.label'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_PROFILES_PLACEHOLDER =
    'createActionWithAI.steps.content.profiles.placeholder'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_PROFILES_LABEL =
    'createActionWithAI.steps.content.profiles.label'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ADVICE_PLACEHOLDER =
    'createActionWithAI.steps.content.advice.placeholder'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ADVICE_LABEL = 'createActionWithAI.steps.content.advice.label'
  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_CONTEXT_PLACEHOLDER =
    'createActionWithAI.steps.content.context.placeholder'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_CONTEXT_LABEL =
    'createActionWithAI.steps.content.context.label'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ACTIONS_CREATE =
    'createActionWithAI.steps.content.actions.create '

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ACTIONS_GENERATEAGAIN =
    'createActionWithAI.steps.content.actions.generateAgain'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ACTIONS_GENERATECONTENT =
    'createActionWithAI.steps.content.actions.generateContent'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ACTIONS_CREATEACTIONTOOLTIP =
    'createActionWithAI.steps.content.actions.createActionTooltip'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_ACTIONS_CREATEV2 =
    'createActionWithAI.steps.content.actions.createv2'

  public static readonly CREATEACTIONWITHAI_STEPS_CONTENT_CREATEACTIONSUCCESS =
    'createActionWithAI.steps.content.createActionSuccess'

  public static readonly ADMINKPI_PILLARSPAGE_MOSTPRACTICEDPILLAR = 'adminkpi.pillarsPage.mostPracticedPillar'
  public static readonly ADMINKPI_PILLARSPAGE_LEASTPRACTICEDPILLAR = 'adminkpi.pillarsPage.leastPracticedPillar'
  public static readonly PROGRESSIONPAGE_BADGES_LOCKED_MAXACTIONS = 'progressionPage.badges.locked.maxActions'
}

import * as Sentry from '@sentry/vue'
import type { Vue } from '@sentry/vue/types/types'
import type { Router } from 'vue-router'

export default (app: Vue, router: Router) => {
  if (window.env.NODE_ENV === 'production') {
    Sentry.init({
      app,
      dsn: 'https://4ce3b263b23b47d2bf15a5c4f4667740@sentry.io/1388906',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      environment: window.env.NODE_ENV,
      release: (import.meta as any).env.VITE_APP_VERSION,
      tracesSampleRate: 0.2,
      ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: Cancelled',
        'TypeError: annulé',
        'promise-polyfill',
        'ResizeObserver loop limit exceeded', // Caused by LastPass extension
        'ResizeObserver loop completed with undelivered notifications.', // Caused by LastPass extension
      ], // as explain on stackoverflow, these are not disturbing errors, see questions/55738408
    })
  }
}
